import { shareConstants } from '../constants/shareConstants';

var initialState = {
  UserRegion: ''
};

export const shareReducer = (state = initialState, action) => {
  switch (action.type) {
    case shareConstants.GET_CLINICAL_VIDEOS_SHARE_SUCCESS:
      return {
        ...state,
        ClinicalVidShareData: action.payload.data.archived_video,
        RelatedClinicalVideosData: action.payload.data.related
      };

    case shareConstants.GET_CLINICAL_VIDEOS_SHARE_FALIURE:
    case shareConstants.GET_MEDWIKI_SHARE_FALIURE:
    case shareConstants.GET_SESSION_SHARE_FALIURE:
    case shareConstants.GET_GR_SHARE_FALIURE:
    case shareConstants.GET_SPQ_SHARE_FALIURE:
    case shareConstants.GET_EBOOK_SHARE_FALIURE:
    case shareConstants.GET_CHANNEL_SHARE_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };

    case shareConstants.GET_MEDWIKI_SHARE_SUCCESS:
      return {
        ...state,
        MedwikiShareData: action?.payload?.data?.comp,
        MedwikiRelatedData: action?.payload?.data?.related
      };

    // case shareConstants.GET_MEDWIKI_SHARE_FALIURE:
    // case shareConstants.GET_SESSION_SHARE_FALIURE:
    // case shareConstants.GET_GR_SHARE_FALIURE:
    // case shareConstants.GET_SPQ_SHARE_FALIURE:
    // case shareConstants.GET_EBOOK_SHARE_FALIURE:
    // case shareConstants.GET_CHANNEL_SHARE_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case shareConstants.GET_SESSION_SHARE_SUCCESS:
      return {
        ...state,
        SessionShareData:
          'session' in action.payload.data && action.payload.data?.session.length > 0
            ? action.payload.data?.session[0]
            : {},
        SessionRelatedData: action.payload.data.related
      };

    // case shareConstants.GET_SESSION_SHARE_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case shareConstants.GET_GR_SHARE_SUCCESS:
      return {
        ...state,
        GrShareData: action.payload.data.gr[0],
        RelatedGrData: action.payload.data.related
      };

    // case shareConstants.GET_GR_SHARE_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case shareConstants.GET_SPQ_SHARE_SUCCESS:
      return {
        ...state,
        SpqShareData: action.payload.data.survey,
        SpqRelatedData: action.payload.data.related
      };

    // case shareConstants.GET_SPQ_SHARE_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case shareConstants.GET_EBOOK_SHARE_SUCCESS:
      return {
        ...state,
        EbookShare: action.payload.data.epub[0],
        EbookRelatedData: action.payload.data.related
      };

    // case shareConstants.GET_EBOOK_SHARE_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case shareConstants.GET_CHANNEL_SHARE_SUCCESS:
      return {
        ...state,
        ChannelShareData: action.payload.data.channel[0],
        ChannelRelatedData: action.payload.data.related
      };
    case shareConstants.GET_OBSERVERSHIP_SHARE_SUCCESS:
      return {
        ...state,
        observershipShareData: action.payload.data.channel[0],
        observershipRelatedData: action.payload.data.related
      };
    // case shareConstants.GET_CHANNEL_SHARE_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };
    case 'SAVE_UTM':
      return {
        ...state,
        utmSource: action.payload
      };

    case 'GET_USER_REGION':
      return {
        ...state,
        UserRegion: action.payload.data.user_env
      };
    default:
      return state;
  }
};
