import React from 'react';
import { routeNames } from '../constants';
import Loadable from '../Loadable';

const ClinicalVideosShare = Loadable({
  loader: () => import('../../../src/pages/share/ClinicalVideosShare')
});

const MedwikiShare = Loadable({
  loader: () => import('../../../src/pages/share/MedwikiShare')
});

const TrainingShare = Loadable({
  loader: () => import('../../../src/pages/share/TrainingShare')
});

const PollShare = Loadable({
  loader: () => import('../../../src/pages/share/PollShare')
});

const QuizShare = Loadable({
  loader: () => import('../../../src/pages/share/QuizShare')
});

const SessionShare = Loadable({
  loader: () => import('../../../src/pages/share/SessionShare')
});

const EbookShare = Loadable({
  loader: () => import('../../../src/pages/share/ebookShare')
});

const ProfileShare = Loadable({
  loader: () => import('../../../src/pages/share/profileShare')
});

export const shareRoutes = [
  {
    path: '/share/training/:id',
    component: <TrainingShare />,
    subPath: [':utm', ''],
    ShareRoute: true,
    redirectTypeRoute: routeNames.course.cover
  },

  {
    path: '/share/content/:id',
    component: <MedwikiShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: routeNames.medwiki.detail,
    ShareRoute: true
  },
  {
    path: '/share/feed/:id',
    component: <MedwikiShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: 'MedwikiDetails',
    ShareRoute: true
  },
  {
    path: '/share/Poll/:id',
    redirectTypeRoute: routeNames.spq.poll,
    ShareRoute: true,
    component: <PollShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/survey/:id',
    redirectTypeRoute: routeNames.spq.detail,
    ShareRoute: true,
    component: <QuizShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/cme/:id',
    redirectTypeRoute: routeNames.sessions.detail,
    ShareRoute: true,
    component: <SessionShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/doctorVoice/:id',
    redirectTypeRoute: routeNames.dashboard.landing,
    ShareRoute: true,
    component: <PollShare />,
    subPath: ['', ':utm']
  },

  {
    path: '/share/documents/:id',
    redirectTypeRoute: routeNames.epaper.detail,
    ShareRoute: true,
    component: <EbookShare />,
    subPath: ['', ':utm']
  },

  {
    path: '/share/Profile',
    component: <ProfileShare />,
    ShareRoute: true,
    redirectTypeRoute: routeNames.userProfile.landing,
    subPath: ['', ':utm']
  },
  {
    path: '/share/videos/:id',
    component: <ClinicalVideosShare />,
    subPath: ['', ':utm'],
    ShareRoute: true,
    redirectTypeRoute: routeNames.clinicalVideo.detail
  },
  {
    path: '/share/video_archive/:id',
    component: <ClinicalVideosShare />,
    subPath: ['', ':utm'],
    ShareRoute: true,
    redirectTypeRoute: routeNames.clinicalVideo.detail
  }
];
