import { useDispatch, useSelector } from 'react-redux';
import {
  getLocalStorage,
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage
} from '../../common/common';
import { useOpenPixel } from './useOpenPixel';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { recordOpixEvent } from '../../Store/actions/tracking.action';
import { useParams } from 'react-router-dom';
import { routeNames } from '../../router/constants';
import { CARD_VIEW } from '../../components/tracking/constants';

export const useTrackUserActivity = () => {
  const dispatch = useDispatch();
  const openPixelInitialize = useOpenPixel();
  useEffect(() => {
    openPixelInitialize(
      window,
      document,
      window['opix'],
      'opix',
      `${process.env.REACT_APP_OPIX_REPORTING_ENDPOINT}`,
      1
    );
  }, []);

  const current_menu = useSelector((state) => state.common.current_menu);
  const { id } = useParams();

  function generateURL(currentLocation, type_id) {
    var url = 'https://' + window.location.hostname + '/#';

    if (currentLocation) {
      if (currentLocation.includes('medwiki')) {
        url = url + routeNames.medwiki.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('clinical')) {
        url = url + routeNames.clinicalVideo.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('surveys')) {
        url = url + routeNames.spq.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('sessions')) {
        url = url + routeNames.sessions.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('channel')) {
        url = url + routeNames.channel.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('grandround')) {
        url = url + routeNames.grandRound.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('course')) {
        url = url + routeNames.course.cover + '/' + type_id;
      } else if (
        currentLocation.toLowerCase().includes('epaper') ||
        currentLocation.toLowerCase().includes('epub')
      ) {
        url = url + routeNames.epaper.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('observership')) {
        url = url + routeNames.observership.observershipDetails + '/' + type_id;
      }
    }
    return url;
  }
  function userActivityTracker(inView, item, typeOfItem, pageId = 0) {
    var { type_id, type, survey_id, sponsor_id, session_id } = item || {};
    if (inView) {
      //SEND GA EVENT FOR CARD
      if (type === CARD_VIEW && !!sponsor_id) {
        let currentLocation = '';
        let pageTitle = '';
        pageTitle = document.title;
        currentLocation = window.location.href;
        console.log('Send view', {
          hitType: 'pageview',
          page: generateURL(currentLocation, type_id),
          title: pageTitle,
          location: generateURL(currentLocation, type_id)
        });
        ReactGA.send({
          hitType: 'pageview',
          page: generateURL(currentLocation, type_id),
          title: pageTitle,
          location: generateURL(currentLocation, type_id),
          dl: currentLocation
        });

        // ReactGA.event({
        //   category: 'CARD_VIEW',
        //   action: 'CARD VIEW',
        //   page_title: pageTitle,
        //   page_location: currentLocation,
        //   value: parseInt(type_id),
        //   label: getLocalStorage('user', false) ? getLocalStorage('user', '{}')?.user_master_id : 0, // optional
        //   nonInteraction: true, // optional, true/false
        //   transport: 'beacon' // optional, beacon/xhr/image
        // });
      }

      const payload = {
        page: current_menu,
        pageId: pageId ? pageId : id,
        content_type: type,
        content_id: type_id,
        sponsor_id: sponsor_id
      };
      // opix('event', `${type}_${typeOfItem}`, payload);

      var previousAnalytics = JSON.parse(getSessionStorage('analytics_sent', 'null'));
      if (previousAnalytics && previousAnalytics.length > 0) {
        var findIndex = previousAnalytics.indexOf(payload);
        if (findIndex === -1) {
          previousAnalytics.push(payload);
          setSessionStorage('analytics_sent', JSON.stringify(previousAnalytics));
          // dispatch(recordOpixEvent(type, payload));
        }
      } else {
        setSessionStorage('analytics_sent', JSON.stringify([payload]));
        // dispatch(recordOpixEvent(type, payload));

        // opix('event', `${type}_${typeOfItem}`, payload);
      }

      // if (JSON.parse(getSessionStorage('analytics_sent'))?.length > 4) {
      //   opix('event', ``, getSessionStorage('analytics_sent'));

      //   removeSessionStorage('analytics_sent');
      // }
    }
  }

  // function userClickActivityTracker(item){
  //     var { type_id, type, survey_id ,sponsor_id} = item || {};
  //     if (type === 'survey') type_id = survey_id;
  //     else if (type==='training') type_id = id;
  //     const payload = {
  //         page: current_menu,
  //         pageId: 0,
  //         content_type: type,
  //         content_id: type_id,
  //         sponsor_id: sponsor_id,
  //       }
  //     opix('event', `${type}_${typeOfItem}`, payload);

  // }

  return {
    userActivityTracker
  };
};
