import React, { memo, useEffect, useState } from 'react';

import './css/doctorCircle.scss';
import LazyImage from '../CustomLibraries/LazyImage';
import { DEMO_USER_PICTURE } from '../../common/common';
import { routeNames } from '../../router/constants';
import { CARD_CLICK } from '../tracking/constants';

import { useTracker } from '../../TrackerFolder/TrackerComponent';
import { onCardClick } from '../tracking/UserActions';
import useRedirect from '../../common/hooks/useRedirect';
const DoctorCircleCards = ({ data, id, type, gtmTagVariable = '' }) => {
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();
  const doctorData = data?.session_doctor_entities;
  const [last_circle, setLastCircle] = useState(-1);
  useEffect(() => {
    if (data) {
      const prnWidth = document.querySelector(`.common_${type}_area_${id}`)?.clientWidth;
      var boxWidth = document.querySelector('.cmnDoc')?.clientWidth;
      if (prnWidth > 0 && boxWidth > 0) {
        var rounValue = Math.floor(prnWidth / boxWidth);
        setLastCircle(rounValue);
        // console.log('CIRCLE_PARENT', prnWidth, boxWidth, rounValue);
      }
    }
  }, [data]);
  return (
    <div className={`w-100 cmnDocArea overflow-hidden common_${type}_area_${id}`}>
      {doctorData.length > 0 &&
        doctorData?.map((item, i) => {
          const isLastCircle =
            last_circle > 0 &&
            doctorData?.length > 2 &&
            last_circle < doctorData?.length &&
            i == last_circle &&
            doctorData.length - last_circle > 1;

          return (
            <div
              className={
                isLastCircle
                  ? 'rounded-circle cmnDoc lastCircle cursorPointer position-relative overflow-hidden border border-3 border-white bg-light z-1 ms-n3 float-start'
                  : 'rounded-circle cmnDoc cursorPointer position-relative overflow-hidden border border-3 border-white bg-light z-1 ms-n3 float-start'
              }
              key={i + 1}
              id={`cmnDoc_${i}`}
              onClick={() => {
                if (item?.session_doctor_id) {
                  trackActivityEvent(CARD_CLICK, onCardClick(item?.session_doctor_id, 'dashboard'));
                  redirectTo(
                    routeNames.sessions.speaker,
                    item?.session_doctor_id,
                    item?.session_doctor_name
                  );
                }
              }}
            >
              <LazyImage
                src={item?.session_doctor_image ?? DEMO_USER_PICTURE}
                fallback_image={DEMO_USER_PICTURE}
                className={`object-fit-cover w-100 h-100 float-start ${gtmTagVariable}_doctorCircleCard`}
                alt="doctor"
              />
              {doctorData?.length > 2 &&
                last_circle < doctorData?.length &&
                i == last_circle &&
                doctorData.length - last_circle > 1 && (
                  <div className="overlay text-white fs-1 bg-black bg-opacity-50 d-flex justify-content-center align-items-center">
                    <span>+{doctorData.length - last_circle}</span>
                  </div>
                )}
            </div>
          );
        })}
    </div>
  );
};

export default memo(DoctorCircleCards);
