import React, { memo } from 'react';
// import '../../components/cards/medwiki/medwikiCardV1/css/style.scss';
import '../../components/cards/ListingCard/css/style.scss';
import { CgArrowLongRight } from '../../components/icons';
import { Skeleton } from '../Skeleton';
const MedwikiLoader = () => {
  return (
    <>
      <div className="w-100 shadow rounded-3 overflow-hidden bg-white pt-3">
        <div className="p-2">
          <Skeleton variant="rectangular" className="rounded-3 w-100 " width="100%" height={150} />
        </div>
        <div className="w-100 float-start medWikiCrdV1Btm p-3 pb-4">
          <div className="w-100 float-start d-flex text-start cardV1Topicmb-2">
            <div className=" position-relative w-100">
              <div className="d-flex align-items-center justify-content-between ">
                <Skeleton variant="text" width="80%" height={20} />
                <Skeleton variant="circular" width={20} height={20} />
              </div>
            </div>
          </div>
          <div className="m-0">
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(MedwikiLoader);
