import React, { memo } from 'react';
import { Skeleton } from '../../Skeleton';

const MedwikiRelatedPostLoader = ({ title , countCard = 3  }) => {
  return (
    <>
      <div
        className="w-100 detailsTrnding d-flex flex-column shadow rounded-3"
        id="medwiki_related_post"
      >
        <div className="detailsTrndingTtl p-3">
          <h2 className="fs-3 text-black text-start">
            <Skeleton variant={'rectangular'} className="rounded-3" height={20} width="50%" />
          </h2>
        </div>

        <div className="w-100 float-start detailsTrndingCardArea p-3 ">
          {[...Array(countCard)].map((i ,index) => (
            <div className="Card positiw-100 float-start archVideoSmallon-relative mb-3" key={index}>
              <div className="w-100 float-start smallCardIn position-relative z-1 d-flex justify-content-between align-items-center">
                <div className="smallCardPic cursorPointer d-flex position-relative overflow-hidden">
                  <div className="w-100 h-100 position-relative">
                    <Skeleton variant="rectangular" width={70} height={70} />
                  </div>
                  <div className="smallCardRight text-start ps-3">
                    <Skeleton variant={'text'} height={10} width={50} />
                    <Skeleton variant={'text'} height={10} width={200} />
                    <Skeleton variant={'text'} height={10} width={200} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="w-100 p-3">
          <Skeleton variant="rounded" width="100%" height={40} className="rounded-3" />
        </div>
      </div>
    </>
  );
};

export default memo(MedwikiRelatedPostLoader);
