import axiosInstance from '../helper/axiosInstance.js';
import { appTourConstants } from '../constants';

export const getAppTourData = (pageName, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`onboardingtour/listing?page_name=${pageName}`)
      .then((response) => {
        callback(response.data);
        response?.data?.data?.data?.steps?.length > 0 &&
          dispatch({
            type: appTourConstants.GET_APP_TOUR_DATA_SUCCESS,
            payload: response.data
          });
      })
      .catch((error) => {
        callback('error');
        dispatch({
          type: appTourConstants.GET_APP_TOUR_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const openModalStatus = (isViewTourClicked = false) => {
  return {
    type: appTourConstants.TOOGLE_APP_TOUR_MODAL_VIEW,
    payload: isViewTourClicked
  };
};
export const closeModalStatus = () => {
  return {
    type: appTourConstants.CLOSE_APP_TOUR_MODAL
  };
};
export const resetAppTourReducer = () => {
  return {
    type: appTourConstants.RESET_APP_STORE
  };
};
export const submitAppTourAnalytics = (arr, callback) => {
  console.log('action arr', arr.user_view_step.length);
  if (arr.user_view_step?.length > 0) {
    return async (dispatch) => {
      axiosInstance
        .post('onboardingtour/tourviewstatus', arr)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          callback('error');
        });
    };
  }
};
