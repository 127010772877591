import React, { memo, useEffect, useState } from 'react';

import dayjs from 'dayjs';

function SessionCoutDown(props) {
  const {textClass} =props
  const calculateTimeLeft = () => {
    const datetime = dayjs(props?.date).diff(dayjs(new Date()));

    let timeLeft = {};

    if (datetime > 0) {
      timeLeft = {
        days: Math.floor(datetime / (1000 * 60 * 60 * 24)),
        hours: Math.floor((datetime / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((datetime / 1000 / 60) % 60),
        seconds: Math.floor((datetime / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
  });

  const getFormatedData = (val = 0) => {
    return val.toString().padStart(2, 0);
  };
  return (
    <>
      <div className="row m-0">
        {timeLeft?.days > 0 ? (
          <>
            <div className="col px-2">
              <p className={`fw-normal fs-6 ${textClass}`}>
                <span className="fs-3 fw-medium d-block position-relative lh-1 pb-1 mb-1 countdown">
                  {getFormatedData(timeLeft.days)}
                </span>
                Days
              </p>
            </div>
            <div className="col px-2">
              <p className={`fw-normal fs-6 ${textClass}`}>
                <span className="fs-3 fw-medium d-block position-relative lh-1 pb-1 mb-1 countdown">
                  {getFormatedData(timeLeft.hours)}
                </span>
                Hours
              </p>
            </div>
            <div className="col px-2">
              <p className={`fw-normal fs-6 ${textClass}`}>
                <span className="fs-3 fw-medium d-block position-relative lh-1 pb-1 mb-1 countdown">
                  {getFormatedData(timeLeft.minutes)}
                </span>
                Minutes
              </p>
            </div>
            {/* <div className="col px-2">
              <p className="fw-normal fs-6 ${textClass}">
                <span className="fs-3 fw-medium d-block position-relative lh-1">
                  {getFormatedData(timeLeft.seconds)}
                </span>
                Seconds
              </p>
            </div> */}
          </>
        ) : timeLeft?.hours > 0 ? (
          <>
            <div className="col px-2">
              <p className={`fw-normal fs-6 ${textClass}`}>
                <span className="fs-4 fw-medium d-block position-relative lh-1 pb-1 mb-1 countdown">
                  {getFormatedData(timeLeft.hours)}
                </span>
                Hours
              </p>
            </div>
            <div className="col px-2">
              <p className={`fw-normal fs-6 ${textClass}`}>
                <span className="fs-4 fw-medium d-block position-relative lh-1 pb-1 mb-1 countdown">
                  {getFormatedData(timeLeft.minutes)}
                </span>
                Minutes
              </p>
            </div>
            <div className="col px-2">
              <p className={`fw-normal fs-6 ${textClass}`}>
                <span className="fs-4 fw-medium d-block position-relative lh-1 pb-1 mb-1 countdown">
                  {getFormatedData(timeLeft.seconds)}
                </span>
                Seconds
              </p>
            </div>
          </>
        ) : (
          <></>
          // timeLeft?.seconds > 0 && (
          //   <>
          //     <div className="col px-2">
          //       <p className="fw-normal fs-6 ${textClass}">
          //         <span className="fs-3 fw-medium d-block position-relative lh-1">
          //           {getFormatedData(timeLeft.minutes)}
          //         </span>
          //         Minutes
          //       </p>
          //     </div>
          //     <div className="col px-2">
          //       <p className="fw-normal fs-6 ${textClass}">
          //         <span className="fs-3 fw-medium d-block position-relative lh-1">
          //           {getFormatedData(timeLeft.seconds)}
          //         </span>
          //         Seconds
          //       </p>
          //     </div>
          //   </>
          // )
        )}
      </div>
    </>
  );
}

export default memo(SessionCoutDown);

// 2021-12-22 13:42:30
