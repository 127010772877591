// STATIC IMAGES
export const clinical_Video_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Clinical_Video_Static_Image.jpg';
export const medwiki_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Medwiki_Static_Image.jpg';
export const ebook_static_image = 'https://assets.clirnet.com/common/portrait.png';
export const quizzes_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Quizzes_Static_Image.jpg';
export const certified_courses_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/certified_courses_static_image.jpg';
export const grand_rounds_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Grand_Round_Static_Image.jpg';
export const surveys_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Surveys_Static_Image.jpg';

export const content_cta_suscribe_service_v1_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Cta_Suscribe_Service_V1_Static_Image.png';
export const content_cta_suscribe_speciality_v1_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Cta_Suscribe_Speciality_V1_Static_Image.png';
export const content_cta_suscribe_speciality_v2_static_image =
  'https://assets.clirnet.com/defaultContentImage/images/Cta_Suscribe_Speciality_V2_Static_Image.png';

export const certificate_static_image = 'https://assets.clirnet.com/common/certificate.png';
