import React, { memo } from 'react';
import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';
import LoaderLine from '../components/LoaderLine';
import OpixWrapper from '../router/OpixWrapper';
import { ErrorFallback } from '../pages/error';
import * as Sentry from '@sentry/react';
// ReactGA.initialize('G-MMRQERMH4E');
ReactGA.initialize(process.env.REACT_APP_GA_ID);

function RootLayout() {
  return (
    <>
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <OpixWrapper>
          <LoaderLine />
          <Outlet />
        </OpixWrapper>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default memo(RootLayout);
