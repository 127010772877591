export const spqConstants = {
  GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_FAILURE',

  GET_POLL_SUCCESS: 'GET_POLL_SUCCESS',
  GET_POLL_FAILURE: 'GET_POLL_FAILURE',

  GET_SURVEY_SUCCESS: 'GET_SURVEY_SUCCESS',
  GET_SURVEY_FAILURE: 'GET_SURVEY_FAILURE',

  GET_QUIZ_SUCCESS: 'GET_QUIZ_SUCCESS',
  GET_QUIZ_FAILURE: 'GET_QUIZ_FAILURE',

  GET_COMPLETED_ALL_SUCCESS: 'GET_COMPLETED_ALL_SUCCESS',
  GET_COMPLETED_ALL_FAILURE: 'GET_COMPLETED_ALL_FAILURE',

  GET_COMPLETED_POLL_SUCCESS: 'GET_COMPLETED_POLL_SUCCESS',
  GET_COMPLETED_POLL_FAILURE: 'GET_COMPLETED_POLL_FAILURE',

  GET_COMPLETED_SURVEY_SUCCESS: 'GET_COMPLETED_SURVEY_SUCCESS',
  GET_COMPLETED_SURVEY_FAILURE: 'GET_COMPLETED_SURVEY_FAILURE',

  GET_COMPLETED_QUIZ_SUCCESS: 'GET_COMPLETED_QUIZ_SUCCESS',
  GET_COMPLETED_QUIZ_FAILURE: 'GET_COMPLETED_QUIZ_FAILURE',

  GET_SPQ_DETAILS_SUCCESS: 'GET_SPQ_DETAILS_SUCCESS',
  GET_SPQ_DETAILS_FAILURE: 'GET_SPQ_DETAILS_FAILURE',

  GET_SPQ_RELATED_SUCCESS: 'GET_SPQ_RELATED_SUCCESS',
  GET_SPQ_RELATED_FAILURE: 'GET_SPQ_RELATED_FAILURE',

  GET_PENDING_SUCCESS: 'GET_PENDING_SUCCESS',
  GET_PENDING_FAILURE: 'GET_PENDING_FAILURE'
};
