export const loginConstants = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
  USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',

  LOG_OUT: 'LOG_OUT',
  LOG_OUT_FROM: 'LOG_OUT_FROM'
};
