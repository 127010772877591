import React from 'react';
import useRedirect from '../common/hooks/useRedirect';
import { routeNames } from '../router/constants';
import { useTracker } from '../TrackerFolder/TrackerComponent';
import { CARD_CLICK } from '../components/tracking/constants';
import { onCardClick } from '../components/tracking/UserActions';
import { removeHtmlTags } from './common';

function useContentAccessRedirect() {
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();

  const contentAccessRedirect = (routeName, id, title, extra) => {
    const routePath = routeName.split('/');
    let serviceName = '';

    if (routePath.length > 0) {
      serviceName = routePath[1]?.replace('/', '');
    }
    trackActivityEvent(CARD_CLICK, onCardClick(id, serviceName));
    redirectTo(routeName, id, removeHtmlTags(title), typeof extra == 'string' ? extra : '');
  };

  return { contentAccessRedirect };
}

export default useContentAccessRedirect;
