export const DASHBOARD_DOCTOR_VOICE = 'DASHBOARD_DOCTOR_VOICE';
export const DASHBOARD_ALL_DATA = 'DASHBOARD_ALL_DATA';
export const DASHBOARD_INFINITE_DATA = 'DASHBOARD_INFINITE_DATA';

export const DASHBOARD_USER_INFO = 'DASHBOARD_USER_INFO';
export const DASHBOARD_CERT_COUNT = 'DASHBOARD_CERT_COUNT';
export const DASHBOARD_RESERVED_CME = 'DASHBOARD_RESERVED_CME';
export const DASHBOARD_RECENT = 'DASHBOARD_RECENT';
export const STREAM_INFI = 'STREAM_INFI';
export const TRENDING_TOPIC = 'TRENDING_TOPIC';
export const SPECIALITY_FEED_DATA = 'SPECIALITY_FEED_DATA';
export const MOST_READ_FEED_DATA = 'MOST_READ_FEED_DATA';
export const DASHBOARD_TRENDING_CONTENT = 'DASHBOARD_TRENDING_CONTENT';
export const DASHBOARD_MOSTREAD_CONTENT = 'DASHBOARD_MOSTREAD_CONTENT';
