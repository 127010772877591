import React from 'react';
import Loadable from '../Loadable';

const Registration = Loadable({
  loader: () => import('../../pages/registration/Registration')
});

const NotFound = Loadable({
  loader: () => import('../../../src/pages/notfound/NotFound')
});

const AutoLogin = Loadable({
  loader: () => import('../../../src/pages/login/autoLogin')
});

const AutoAuth = Loadable({
  loader: () => import('../../../src/pages/Autoauth/autoauth')
});
const SessionInvitiation = Loadable({
  loader: () => import('../../../src/pages/session/SessionInvitation')
});

export const neutralRoutes = [
  {
    path: '/NotFound',
    component: <NotFound />,
    module: 'generic'
  },
  {
    path: '*',
    component: <NotFound />,
    module: 'generic'
  },
  {
    path: '/Autologin/:token/:redirect_name',
    subPath: [':redirect_page_id/:auto_subscription', ':redirect_page_id', ''],
    component: <AutoLogin />,
    module: 'generic'
  },
  {
    path: '/SessionInvitation/:content_type/:content_id/:utm_source/:user_type/:user_id',
    component: <SessionInvitiation />,
    subPath: ['', ':token'],
    module: 'session'
  },
  {
    path: '/autoauth/:content_type/:content_id/:utm_source',
    component: <AutoAuth />,
    module: 'generic'
  },
  {
    path: '/autoauth/:content_type/:content_id/:utm_source/:user_type/:user_id',
    component: <AutoAuth />,
    subPath: ['', ':token'],
    module: 'generic'
  },
  {
    path: '/autoauthDirect/:content_type/:content_id/:utm_source/:user_type/:user_id',
    component: <AutoAuth />,
    subPath: ['', ':token'],
    module: 'generic'
  },
  {
    path: '/registration',
    component: <Registration />,
    subPath: ['', ':email', ':email/:referal_code'],
    module: 'generic'
  }
];
