import React, { lazy } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import DetailsPageLayout from '../../layout/DetailsPageLayout';

import { routeNames } from '../constants';
import Loadable from '../Loadable';
import { Row } from 'react-bootstrap';
import CalendarPage from '../../pages/resources/CalendarPage';
import ResourcesPage from '../../pages/resources/ResourcesPage';
import ConferenceDetailsPage from '../../pages/resources/ConferenceDetailsPage';
import LeaderboardPage from '../../pages/session/LeaderboardPage';
// import ApplicationDetails from '../../pages/uiv3/observership/ApplicationDetails';

const Dashboard = Loadable({
  loader: () => import('../../../src/pages/dashboard/Dashboard')
});
const EbookListing = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookListing')
});
const EbookDetails = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookDetails')
});
const Vault = Loadable({
  loader: () => import('../../../src/pages/vault/Vault')
});

const HelpAndSupport = Loadable({
  loader: () => import('../../../src/pages/helpAndSupport/HelpAndSupport')
});

const CpdReview = Loadable({
  loader: () => import('../../../src/pages/userProfile/CpdReview')
});

const ClinicalVideoDetails = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalDetails')
});

const SPQ = Loadable({
  loader: () => import('../../../src/pages/spq/spq')
});
const UserPoint = Loadable({
  loader: () => import('../../../src/pages/spq/UserPoint')
});
const SPQreview = Loadable({
  loader: () => import('../../../src/pages/spq/SPQreview')
});

const SessionListing = Loadable({
  loader: () => import('../../../src/pages/session/SessionListing')
});
const SessionWaitingRoom = Loadable({
  loader: () => import('../../../src/pages/session/SessionWaitingRoom')
});
const LiveSession = Loadable({
  loader: () => import('../../../src/pages/session/LiveSession')
});

const Notification = Loadable({
  loader: () => import('../../../src/pages/notification/Notification')
});

const ShareLoginNew = Loadable({
  loader: () => import('../../../src/pages/shareLogin/ShareLoginNew')
});

const ClinicalVideoLanding = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalVideoLanding')
});

const SearchResultV2 = Loadable({
  loader: () => import('../../../src/pages/Search/SearchV3')
});
const SearchList = Loadable({
  loader: () => import('../../../src/pages/Search/SearchList')
});

const UploadCertificate = Loadable({
  loader: () => import('../../../src/pages/certificates/UploadCertificate')
});
const CertificatesListing = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificatesListing')
});

const ProfileVideos = Loadable({
  loader: () => import('../../../src/pages/userProfile/ProfileVideos')
});

const GRlanding = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingLanding')
});
const GRlisting = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingListing')
});
const GRcover = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingCover')
});
const GRcontent = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingContent')
});
const GRsummary = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingSummary')
});
const MedwikiLandingV2 = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiLandingV2')
});
const Demo = Loadable({
  loader: () => import('../../../src/pages/Demo')
});
// const MedwikiDetails = Loadable({
//   loader: () => import('../../../src/pages/uiv3/medwiki/MedwikiDetails')
// });
const MedwikiDetailsV2 = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiDetailsV2')
});

const PollDetails = Loadable({
  loader: () => import('../../../src/pages/spq/PollDetails')
});

const CertificateDetails = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificateDetails')
});

const QueryListing = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/QueryListing')
});

const UpcomingSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/UpcomingSessionsList')
});

const PreviousSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/PrviousSession')
});

const SpeakerProfile = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/SpeakerProfile')
});

const SPQquestion = Loadable({
  loader: () => import('../../../src/pages/spq/SPQquestion')
});

const SPQsubmitSuccessfully = Loadable({
  loader: () => import('../../../src/pages/spq/SPQsubmitSuccessfully')
});

const SPQcompleted = Loadable({
  loader: () => import('../../../src/pages/spq/SPQcompleted')
});
const SPQlanding = Loadable({
  loader: () => import('../../../src/pages/spq/SPQlanding')
});

const SessionDetailsOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/SessionDetailsOutlet')
});
const LiveSessionOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/LiveSessionOutlet')
});

const SPQdetails = Loadable({
  loader: () => import('../../../src/pages/spq/SPQdetails')
});

const UserProfile = Loadable({
  loader: () => import('../../../src/pages/userProfile/UserProfileBkp.jsx')
});

const SpecialityLanding = Loadable({
  loader: () => import('../../../src/pages/speciality/SpecialityLanding')
});

const CommonListing = Loadable({
  loader: () => import('../../pages/listing/CommonListing')
});

export const newRoutes = [
  {
    layout: <DefaultLayout />,
    subpath: [
      {
        path: routeNames.dashboard.listing,
        component: <CommonListing />,
        module: 'generic'
      },
      {
        path: routeNames.dashboard.landing,
        component: <Dashboard />,
        module: 'generic'
      },
      {
        path: routeNames.userProfile.landing,
        component: <UserProfile />,
        module: 'generic'
      },

      {
        path: routeNames.speciality.landing,
        paramPath: [':id', ':id/:title'],
        component: <SpecialityLanding />,
        module: 'speciality'
      },

      {
        path: routeNames.speciality.vault,
        component: <Vault />,
        module: 'speciality'
      },
      {
        path: routeNames.helpAndSupport.landing,
        component: <HelpAndSupport />,
        module: 'generic'
      },
      {
        path: routeNames.search.landing,
        component: <SearchResultV2 />,
        module: 'generic'
      },

      {
        path: routeNames.search.listing,
        component: <SearchList />,
        module: 'generic'
      },

      {
        path: routeNames.certificate.upload,
        component: <UploadCertificate />,
        module: 'generic'
      },
      {
        path: routeNames.certificate.landing,
        component: <CertificatesListing />,
        module: 'generic'
      },
      {
        path: routeNames.cpdReview.landing,
        component: <CpdReview />,
        module: 'generic'
      },
      {
        path: routeNames.sessions.upcoming,
        paramPath: [':id'],
        component: <UpcomingSessionList />,
        module: 'generic'
      },
      {
        path: routeNames.sessions.previous,
        paramPath: [':id'],
        component: <PreviousSessionList />,
        module: 'generic'
      },
      {
        path: routeNames.sessions.queryListing,
        paramPath: [':id'],
        component: <QueryListing />,
        module: 'generic'
      },
      {
        path: routeNames.spq.question,
        paramPath: [':id', ':id/:title'],
        component: <SPQquestion />,
        module: 'spq'
      },
      {
        path: routeNames.spq.review,
        paramPath: [':id', ':id/:title'],
        component: <SPQreview />,
        module: 'spq'
      },
      {
        path: routeNames.spq.result,
        paramPath: [':id', ':id/:title'],
        component: <SPQsubmitSuccessfully />,
        module: 'spq'
      },
      {
        path: routeNames.spq.attempt,
        component: <SPQcompleted />,
        module: 'spq'
      },
      {
        path: routeNames.spq.listing,
        component: <SPQ />,
        paramPath: ['', ':type'],
        module: 'spq'
      },
      {
        path: routeNames.sessions.landing,
        component: <SessionListing />,
        module: 'session'
      },
      {
        path: routeNames.epaper.landing,
        component: <EbookListing />,
        module: 'epub'
      },

      {
        path: routeNames.medwiki.landing,
        component: <MedwikiLandingV2 />,
        module: 'medwiki'
      },
      {
        path: routeNames.demo.landing,
        component: <Demo />,
        module: 'generic'
      },
      {
        path: routeNames.clinicalVideo.landing,
        component: <ClinicalVideoLanding />,
        module: 'clinicalVideo'
      },
      {
        path: routeNames.spq.landing,
        component: <SPQlanding />,
        module: 'spq'
      },
      {
        path: routeNames.spq.points,
        component: <UserPoint />,
        module: 'generic'
      },

      {
        path: routeNames.course.landing,
        component: <GRlanding />,
        module: 'courses'
      },

      {
        path: routeNames.course.summary,
        paramPath: [':id', ':id/:title'],
        component: <GRsummary />,
        module: 'courses'
      },
      {
        path: routeNames.notification.landing,
        component: <Notification />,
        module: 'generic'
      },

      {
        path: '/SPQ',
        component: <SPQ />,
        module: 'spq'
      },
      {
        path: routeNames.course.listing,
        component: <GRlisting />,
        module: 'courses'
      },
      {
        path: routeNames.helpAndSupport.landing,
        component: <HelpAndSupport />,
        module: 'helpAndSupport'
      },
      {
        path: routeNames.getSupport.landing,
        component: <HelpAndSupport />,
        module: 'getSupport'
      },
      {
        path: routeNames.resources.landing,
        component: <ResourcesPage />,
        module: 'resources'
      },
      {
        path: routeNames.resources.calendar,
        component: <CalendarPage />,
        module: 'resources'
      },
      {
        path: routeNames.sessions.leaderboard,
        component: <LeaderboardPage />,
        module: 'sessions'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    // SpecialityLayout: <SpecialityDetailsLayout />,
    subpath: [
      {
        path: routeNames.medwiki.detail,
        paramPath: [':id', ':id/:title'],
        component: <MedwikiDetailsV2 />,
        module: 'medwiki'
      },
      {
        path: routeNames.clinicalVideo.detail,
        paramPath: [':id', ':id/:title'],
        component: <ClinicalVideoDetails />,
        module: 'clinicalVideo'
      },

      {
        path: routeNames.epaper.detail,
        paramPath: [':id', ':id/:title'],
        component: (
          <div className="w-100 d-flex flex-column position-relative speciality-portal--defaultLayout">
            <div className="container-fluid">
              <Row className="align-items-start flex-nowrap">
                <EbookDetails />
              </Row>
            </div>
          </div>
        ),

        module: 'epub'
      },
      {
        path: routeNames.course.content,
        paramPath: [':id', ':id/:title', ':id/:title/:module_active'],
        component: <GRcontent />,
        module: 'courses'
      },
      {
        path: routeNames.sessions.speaker,
        paramPath: [':id', ':id/:title', ':id/:title/:active'],
        component: <SpeakerProfile />,
        module: 'generic'
      },
      {
        path: routeNames.certificate.detail,
        paramPath: [':id', ':id/:title'],
        component: <CertificateDetails />,
        module: 'generic'
      },
      {
        path: routeNames.spq.detail,
        paramPath: [':id', ':id/:title'],
        component: <SPQdetails />,
        module: 'spq'
      },
      {
        path: routeNames.spq.poll,
        paramPath: [':id', ':id/:title'],
        component: <PollDetails />,
        module: 'spq'
      },

      {
        path: routeNames.sessions.detail,
        paramPath: [':id', ':id/:title'],
        component: <SessionDetailsOutlet />,
        module: 'session'
      },

      {
        path: routeNames.sessions.live,
        paramPath: [':id', ':id/:title'],
        component: <LiveSessionOutlet />,
        module: 'session'
      },
      {
        path: routeNames.course.cover,
        paramPath: [':id', ':id/:title'],
        component: <GRcover />,
        module: 'gr'
      },
      {
        path: routeNames.resources.details,
        paramPath: [':id', ':id/:title'],
        component: <ConferenceDetailsPage />,
        module: 'resources'
      }
    ]
  }
];
