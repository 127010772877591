import {
  ACCESS_TOKEN,
  CLEAR_NOTIFICATION_ARRAY,
  FETCH_NOTIFICATION_COUNT,
  FETCH_NOTIFICATION_LIST,
  UPDATE_LIST
} from '../constants/notification.constant';

let initialState = {
  today: [],
  week: [],
  more: [],
  count: 0,
  count_called: false,
  accessToken: ''
};
export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_LIST:
      return action?.payload?.is_new_fetch
        ? {
            ...state,
            today: action?.payload?.today,
            week: action?.payload?.week,
            more: action?.payload?.more
          }
        : {
            ...state,
            today: [...state.today, ...action?.payload?.today],
            week: [...state.week, ...action?.payload?.week],
            more: [...state.more, ...action?.payload?.more]
          };
    case FETCH_NOTIFICATION_COUNT:
      return {
        ...state,
        count: action?.payload ?? 0,
        count_called: true
      };
    case UPDATE_LIST:
      if (state?.today?.length > 0 && action.payload.type == 'today') {
        let newToday = [...state.today];
        let index = newToday?.findIndex((_n) => _n.temp_id == action.payload.temp_id);
        let objTochange = newToday[index];
        objTochange.status = 1;

        return {
          ...state,
          today: newToday
        };
      } else if (state?.week?.length > 0 && action.payload.type == 'week') {
        let newWeek = [...state.week];
        let index = newWeek?.findIndex((_n) => _n.temp_id == action.payload.temp_id);
        let objTochange = newWeek[index];
        objTochange.status = 1;
        return {
          ...state,
          week: newWeek
        };
      } else if (state?.more?.length > 0 && action.payload.type == 'more') {
        let newMore = [...state.more];
        let index = newMore?.findIndex((_n) => _n.temp_id == action.payload.temp_id);
        let objTochange = newMore[index];
        objTochange.status = 1;
        return {
          ...state,
          more: newMore
        };
      }
    case CLEAR_NOTIFICATION_ARRAY:
      return {
        ...state,
        today: [],
        week: [],
        more: []
      };
    case ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      };

    default:
      return state;
  }
};
