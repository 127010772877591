export const appTourConstants = {
  GET_APP_TOUR_DATA_SUCCESS: 'GET_APP_TOUR_DATA_SUCCESS',
  GET_APP_TOUR_DATA_FALIURE: 'GET_APP_TOUR_DATA_FALIURE',

  SEND_APP_TOUR_ANALYTICS_SUCCESS: 'SEND_APP_TOUR_ANALYTICS_SUCCESS',
  SEND_APP_TOUR_ANALYTICS_FALIURE: 'SEND_APP_TOUR_ANALYTICS_FALIURE',

  TOOGLE_APP_TOUR_MODAL_VIEW: 'TOOGLE_APP_TOUR_MODAL_VIEW',
  RESET_APP_STORE: 'RESET_APP_STORE',

  CLOSE_APP_TOUR_MODAL: 'CLOSE_APP_TOUR_MODAL'
};
