export const bannerLocalConstant = {
  GLOBAL_BANNER_DATA: `global_banner_data_new`,
  GLOBAL_EXCLUTION_LIST_DATA: `global_exclution_list_data`,
  BANNER_CARD_INDEX: `banner_card_index`,
  GLOBAL_BANNER_SETTING_DATA: `global_banner_setting_data`,
  PLAYED_CATEGORY: 'played_category',
  BANNER_POSITION_TOP: `top`,
  BANNER_POSITION_SIDE_TOP: `side-top`,
  BANNER_POSITION_BOTTOM: `bottom`,
  BANNER_POSITION_SIDE_BOTTOM: `side-bottom`,
  BANNER_POSITION_SECTION_HEADER: `section-header`,
  BANNER_POSITION_VIDEO: `video`,
  BANNER_POSITION_IN_VIDEO_FLOATING: `video-floating`,
  BANNER_POSITION_INTERSTITIAL: `interstitial`,
  BANNER_POSITION_LIST: `listing`,
  BANNER_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss'
};
