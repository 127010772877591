import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleLoadingBar } from '../Store/actions';

const LoaderLine = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(toggleLoadingBar(false));
    }, 1000);
    return () => {
      dispatch(toggleLoadingBar(true));
    };
  }, [location]);

  const loading = useSelector((state) => state.common.loadingBar);
  if (loading) {
    return <div className="loader-line"></div>;
  } else {
    return null;
  }
};

export default LoaderLine;
