import axios from 'axios';
import { GET_GLOBAL_DATA } from '../apis/banner.apis';
import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { bannerData as data } from '../demoData';
import { sortBannerByType } from '../utils/sortBannerByType';
import { separateByPosition } from '../utils/separateByPosition';
import { getSessionStorage, setSessionStorage } from '../../../common/common';

const staleTime = 900000;
const cacheTime = 900000;

function concatObjectValues(obj) {
  const resultArray = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check if the value is an array and concatenate its elements
      if (Array.isArray(value)) {
        resultArray.push(...value);
      } else {
        // For non-array values, just push the value to the result array
        resultArray.push(value);
      }
    }
  }

  return resultArray;
}

const fetchGlobalCampaign = async ({ queryKey }) => {
  return axiosInstance.get(GET_GLOBAL_DATA).then((response) => {
    const data = separateByPosition(response.data.data);
    const timestamp_added = response.data.timestamp_added;
    const sessionData = JSON.parse(getSessionStorage('banner_static_item', '{}'));
    const sessionTimeStamp = JSON.parse(getSessionStorage('banner_static_timestamp', 'null'));

    console.log('sessionTimeStamp', sessionTimeStamp);

    if (Object.keys(sessionData).length > 0 && sessionTimeStamp) {
      return {
        data: sessionData,
        timestamp_added: sessionTimeStamp
      };
    } else {
      setSessionStorage(`banner_static_item`, JSON.stringify(data));
      setSessionStorage('banner_static_timestamp', timestamp_added);
      return {
        data,
        timestamp_added
      };
    }
  });
};

export const useGlobalCampaign = (id, position, type, excludedCampigns) => {
  return useQuery([GET_GLOBAL_DATA], fetchGlobalCampaign, {
    // select: (response) => response.data.data,
    select: (response) => {
      // console.group('Global:', position);
      // console.log('resp', response);
      if (response?.data && position in response.data && response.data[position]?.length > 0) {
        let bannerData = response.data[position];
        console.log('bannerData', bannerData, response, position);
        if (bannerData && Array.isArray(bannerData) && bannerData.length > 0) {
          console.log('inside if  ');
          var filteredByType = [];
          if (type === 'floating') {
            filteredByType = bannerData.filter(
              (_elem) =>
                _elem.target_type.toLowerCase() === type.toLowerCase() &&
                _elem.max_view_per_session >= 0 &&
                _elem.bal_view_per_user_per_camp >= 0 &&
                !excludedCampigns?.ids.includes(id)
            );
          } else {
            filteredByType = bannerData.filter(
              (_elem) =>
                _elem.target_type.toLowerCase() === type.toLowerCase() &&
                !excludedCampigns?.ids.includes(id)
            );
          }

          filteredByType.sort((a, b) => parseInt(b.ad_factor) - parseInt(a.ad_factor));
          return {
            timestamp_added: response.timestamp_added,
            data: filteredByType
          };
        } else {
          console.log('inside else ');
        }
      } else {
        console.log('inside return  ');
        return {
          timestamp_added: '',
          data: []
        };
      }
      // console.groupEnd('Global:', position);

      // const filterData = filteredByType?.filter(
      //   (item) => item.position.split(',').includes(position.toLowerCase())
      //   // parseInt(item.max_view_per_session) > 0 &&
      //   // parseInt(item.max_view_per_campaign) > 0 &&
      //   // parseInt(item.view_per_day) > 0 &&
      //   // parseInt(item.target_views) > 0
      // );
    },
    // enabled: !id,
    cacheTime: cacheTime,
    staleTime: staleTime,
    refetchOnWindowFocus: false
    // initialData: { data: [], timestamp_added_global: '' }
  });
};
