import { clinicalVideosConstant } from '../constants/clinicalVideo.constant';
import { commonConstants } from '../constants/commonConstants';

const initialState = {
  clinicalVideos: [],
  relatedClinicalVideos: [],
  clinicalDetails: {}
};

export const clinicalVideosReducer = (state = initialState, action) => {
  switch (action.type) {
    case clinicalVideosConstant.GET_ARCHIVE_VIDEO_LIST_SUCCESS:
      if (state.clinicalVideos && action.payload.data) {
        return {
          ...state,
          clinicalVideos: [...state.clinicalVideos, ...action.payload.data]
        };
      } else if (state.clinicalVideos) {
        return {
          ...state,
          clinicalVideos: [...state.clinicalVideos]
        };
      }

    case clinicalVideosConstant.GET_ARCHIVE_VIDEO_LIST_FALIURE:
    case clinicalVideosConstant.GET_RELATED_VIDEO_LIST_FALIURE:
    case clinicalVideosConstant.GET_ARCHIVE_VIDEO_WITH_SPECIALITIES_FALIURE:
    case clinicalVideosConstant.GET_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };
    case clinicalVideosConstant.GET_RELATED_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        relatedClinicalVideos: [...state.relatedClinicalVideos, ...action.payload.data]
      };

    // case clinicalVideosConstant.GET_RELATED_VIDEO_LIST_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };
    case clinicalVideosConstant.GET_ARCHIVE_VIDEO_WITH_SPECIALITIES_SUCCESS:
      return {
        ...state,
        clinicalVideos: [...state.clinicalVideos, ...action.payload.data]
      };

    // case clinicalVideosConstant.GET_ARCHIVE_VIDEO_WITH_SPECIALITIES_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };
    case clinicalVideosConstant.GET_DETAILS_SUCCESS:
      return {
        ...state,
        clinicalDetails: action.payload.data
      };

    // case clinicalVideosConstant.GET_DETAILS_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
