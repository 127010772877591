import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { GET_BANNER_SETTINGS } from '../apis/banner.apis';
import { isMobile } from 'react-device-detect';

const staleTime = 300000;
const cacheTime = 600000;

const fetchBannerSettings = ({ queryKey }) => {
  return axiosInstance.get(GET_BANNER_SETTINGS);
};

export const useBannerSettings = (position, type) => {
  return useQuery([GET_BANNER_SETTINGS], fetchBannerSettings, {
    // select: (response) => response.data.data,
    select: (response) => {
      const bannerSettings = response.data.data;
      if (module && Array.isArray(bannerSettings) && bannerSettings.length > 0) {
        const filteredSettings = bannerSettings.filter(
          (item) => item.position.toLowerCase() === position.toLowerCase()
        );

        if (type === 'video') {
          var videoSettings = bannerSettings.filter(
            (item) => item.position.toLowerCase() === 'video'
          );
        }
        if (filteredSettings.length > 0) {
          const payload = {
            ...filteredSettings[0],
            height: isMobile
              ? filteredSettings[0].mobile.split('x')[1]
              : filteredSettings[0].desktop.split('x')[1],
            width: isMobile
              ? filteredSettings[0].mobile.split('x')[0]
              : filteredSettings[0].desktop.split('x')[0],
            dimention: isMobile ? filteredSettings[0].mobile : filteredSettings[0].desktop
          };

          if (type === 'video') {
            payload.videoSettings = videoSettings[0];
          }
          return payload;
        }
      }
    },
    cacheTime: cacheTime,
    staleTime: staleTime,
    refetchOnWindowFocus: false
  });
};
