import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import './css/style.scss';

import { CgArrowLongRight, RiPlayCircleFill } from '../../../components/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import SwiperCore from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';

// import reactHtmlParser from 'react-html-parser';
// import {reactHtmlParser} from '../../../common/common'
import {
  specialityPopOver,
  reactHtmlParser,
  isLocked,
  removeHtmlTags,
  DEMO_USER_PICTURE,
  timeDifference,
  getSessionCategoryIcon,
  getLocalStorage
} from '../../../common/common';
import { OverlayTrigger, ProgressBar, Spinner, Tooltip } from 'react-bootstrap';

import { useLocation } from 'react-router-dom';
import { medwiki_static_image } from '../../../common/assets.common';
import InView, { useInView } from 'react-intersection-observer';
import { useTrackUserActivity } from '../../../common/hooks/useTrackUserActivity';
import { routeNames } from '../../../router/constants';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';
import { CLICK_CARD, TRACK_ACTIVITY_EVENTS } from '../../../TrackerFolder/constants/ActionType';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { OpixTrackerWrapper } from '../../../tracking/OpixTrackerWrapper';
import ClinicalVideoCardV1 from '../../clinicalVideo/ClinicalVideoCardV1';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import CountDown from '../../../pages/shareLogin/Components/CountDown';
import SpecialitiesPill from '../../SpecialitiesPill';
import { CARD_CLICK, CARD_VIEW } from '../../tracking/constants';
import TrackerWrapper from '../../tracking/TrackerWrapper';
import LazyImage from '../../CustomLibraries/LazyImage';
import { onCardClick } from '../../tracking/UserActions';
import ShareCard from '../../CustomLibraries/videoPlayerV1/components/ShareCard';
import DoctorsSectionForCard from '../../DoctorSectionForCard/DoctorsSectionForCard';

// install Swiper modules
SwiperCore.use([EffectFade, Autoplay]);

const ListingCard = forwardRef((props, ref) => {
  console.log('props', props);

  const [aspectImageContainerSize, setAspectImageContainerSize] = useState('0px');
  const { contentAccessRedirect } = useContentAccessRedirect();
  const cardData = props;
  const { hide_time = false } = cardData || {};
  const { trackActivityEvent } = useTracker();
  let premiumContent = cardData?.is_locked;
  console.log('card data', premiumContent);

  const [sponserLogo, setSponserLogo] = useState([]);
  const [isHoverd, setIsHoverd] = useState(false);

  const [activeHover, setActiveHover] = useState(false);

  useEffect(() => {
    if (cardData?.sponsor_logo) {
      setSponserLogo(cardData?.sponsor_logo.split(','));
    }
  }, []);

  const imageContainerRef = useRef();

  const specialityArray =
    cardData?.specialities && Array.isArray(cardData?.specialities)
      ? cardData?.specialities
      : typeof cardData?.specialities === 'string'
      ? cardData?.specialities.split(',').map((s) => s.trim())
      : [];

  const setImageConatinerSize = () => {
    if (imageContainerRef.current) {
      const videoDivWidth = imageContainerRef.current ? imageContainerRef.current.clientWidth : 0;
      const aspectWidth = videoDivWidth / 1.7786;
      setAspectImageContainerSize(aspectWidth > 0 ? Math.round(aspectWidth) : 0);
      // console.log('aspect size is ' + Math.round(aspectWidth));
    }
  };

  console.log(props?.startSessionCountDown, 'props?.startSessionCountDown');

  useEffect(() => {
    setImageConatinerSize();
  }, []);

  const diff = dayjs(cardData?.start_datetime).diff(dayjs(new Date()));
  let isLiveStart =
    cardData && cardData?.start_datetime && cardData?.end_datetime
      ? timeDifference(cardData?.start_datetime) >= 0 && timeDifference(cardData?.end_datetime) <= 0
      : false;

  let sponsorLogoData =
    cardData?.sponsorLogo && typeof cardData?.sponsorLogo === 'string'
      ? cardData?.sponsorLogo.split(',')
      : cardData?.sponsorLogo || [];

  return (
    <TrackerWrapper
      data={cardData}
      type={CARD_VIEW}
      className="cardV1 w-100 float-start ListingCardBox shadow rounded-3 border border-light overflow-hidden bg-white position-relative"
      ref={ref}
    >
      <div
        className={`w-100 float-start ListingCardBoxPic cursorPointer position-relative overflow-hidden ${
          cardData?.imgClass ? 'p-3' : ''
        }`}
        onClick={() => {
          trackActivityEvent(
            CARD_CLICK,
            onCardClick(cardData.id || cardData?.type_id, cardData.type)
          );
          if (cardData?.onClick) {
            cardData?.onClick();
          }
        }}
        ref={imageContainerRef}
        style={{ height: `${aspectImageContainerSize}px` }}
      >
        {/* only for product guide */}
        {/* {'categoryName' in cardData &&
          'specialities' in cardData &&
          'is_share' in cardData &&
          specialityArray?.length == 0 && (
            <div className="d-flex align-items-center justify-content-between py-2 ps-3 pe-2">
              {cardData?.categoryName && (
                <p className="text-danger bg-danger rounded-pill bg-opacity-10 p-1 px-3 fw-normal m-0 fs-5">
                  {cardData?.categoryName}
                </p>
              )}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ShareCard
                  gtmTagVariable={`gtm_cl_quizCard_share`}
                  data={{
                    title: cardData?.survey_title,
                    url: cardData?.deeplink,
                    is_share: cardData?.is_share
                  }}
                />
              </div>
            </div>
          )} */}

        {/* session timer */}
        {cardData && cardData?.startSessionCountDown && (
          <div className="d-flex align-items-center justify-content-between cardTimeLogo position-absolute top-0 start-0 p-3 p-lg-4 w-100">
            {props?.startSessionCountDown &&
            cardData?.start_datetime &&
            cardData?.session_status != 4 &&
            cardData?.session_status != 3 &&
            cardData?.session_status != 6 &&
            !isLiveStart &&
            diff > 0 ? (
              <div className="ssnCardV1Boxtimer">
                <CountDown date={cardData?.start_datetime} />
              </div>
            ) : null}
          </div>
        )}

        {/* sponsor when video  */}
        {cardData?.mediaType == 'video' && sponsorLogoData && (
          <div className="d-flex align-items-center justify-content-between z-3 position-absolute top-0 start-0 p-3 p-lg-4 w-100">
            {sponsorLogoData.length === 1 && (
              <div className="crdV1sponser position-absolute top-0 start-0 mt-2 ms-2 rounded m-0 text-center">
                <div className="crdV1sponserIn position-relative w-100 d-flex align-items-start justify-content-start bg-white rounded-2 p-1">
                  <LazyImage
                    src={sponsorLogoData[0]}
                    // className="position-absolute start-50 top-50 translate-middle mw-100 mh-100 w-auto h-auto"
                    className="mw-100 mh-100 w-auto h-auto"
                    alt="sponsor logo"
                    logo
                  />
                </div>
              </div>
            )}
            {sponsorLogoData?.length > 1 && (
              <Swiper
                className="crdV1sponser position-absolute rounded m-0 text-center bg-white"
                navigation={false}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 2500
                }}
                effect="fade"
              >
                {sponsorLogoData?.map((logo, index) => (
                  <SwiperSlide
                    className="crdV1sponserIn position-relative bg-white w-100"
                    key={index + 1}
                  >
                    <LazyImage
                      src={logo}
                      className="position-absolute start-50 top-50 translate-middle mw-100 mh-100 w-auto h-auto"
                      alt="sponsor logo"
                      logo
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        )}

        {/* full screen position-absolute */}
        {cardData?.session_status == 3 && (
          <div className="position-absolute z-3 w-100 h-100 top-0 start-0 bg-black bg-opacity-50 text-white d-flex align-items-center justify-content-center">
            <h4 className="fw-medium fs-4">Session Closed</h4>
          </div>
        )}

        {/*session bar */}
        {cardData?.session_status && (
          <div className="d-flex align-items-center justify-content-between fw-medium text-white fs-5 position-absolute bottom-0 start-0 w-100 px-3 py-2 bg-secondary z-3">
            {cardData?.session_status == 4 ? (
              <span className="position-relative z-1">Upcoming TBA</span>
            ) : (
              <span className="position-relative z-1">
                {dayjs(cardData?.start_datetime).format(!hide_time ? 'ddd, D MMM' : 'MMM , YYYY')}
              </span>
            )}
            {cardData?.session_status != 4 && !hide_time && (
              <span className="position-relative z-1">
                {dayjs(cardData?.start_datetime).format('hh:mm A')}
              </span>
            )}
          </div>
        )}

        {/* share and hert icon */}
        {cardData?.isCompleted && cardData?.isCompleted > 0 ? (
          <div className="bg-white bg-opacity-50 position-absolute top-0 end-0 p-2 mt-2 me-2 rounded-2 d-flex align-items-center justify-content-center gap-2 z-3">
            {/* <i className="flaticon-share-arrow cursorPointer fs-3 text-white"></i> */}
            {'categoryName' in cardData && 'specialities' in cardData && 'is_share' in cardData && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ShareCard
                  gtmTagVariable={`gtm_cl_quizCard_share`}
                  data={{
                    title: cardData?.survey_title,
                    url: cardData?.deeplink,
                    is_share: cardData?.is_share
                  }}
                  customClass={'text-white fs-2'}
                />
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {/* position bottom bar */}

        <div className="position-absolute start-0 bottom-0 z-1 p-3 d-flex justify-content-betweens w-100 align-items-center mb-2 w-100 overflow-hidden">
          {/* cetificate */}
          {cardData?.isCertificate && (
            <div className="grCardV2Certificate rounded-3 overflow-hidden text-black fs-5 bg-white shadow p-3 d-flex justify-content-between align-items-center ms-3 overflow-hidden position-relative">
              <span className="grCardV2CertificateIcon bg-secondary position-absolute top-0 start-0 h-100 pe-2 d-flex justify-content-center align-items-center ps-2">
                <i className="flaticon-badge fs-2 text-white"></i>
              </span>
              <span className="ps-5">Certified</span>
            </div>
          )}

          {/* points */}
          {cardData?.point && (
            <div className="ms-auto bg-secondary  text-white p-2 rounded-2 d-flex flex-column align-items-center justify-content-center lh-1 px-3">
              <span className="fs-4 fw-semibold">{cardData?.point}</span>
              <span className="fs-6">Points</span>
            </div>
          )}
        </div>

        {/* media check */}
        {cardData?.mediaType == 'video' ? (
          <VideoCard cardData={cardData} />
        ) : cardData?.internalType == 'epub' ? (
          <img
            src={cardData?.image ? cardData?.image : cardData?.fallbackImage}
            alt="banner"
            className={`object-fit-cover w-100 h-100 float-start bg-light bg-opacity-50 ${
              cardData?.imgClass ? 'rounded' : ''
            }`}
          />
        ) : (
          <div className="position-relative w-100 h-100">
            <LazyImage
              src={cardData?.image ? cardData?.image : cardData?.fallbackImage}
              alt="banner"
              fallback_image={cardData?.fallbackImage}
              className="object-fit-cover w-100 h-100"
            />

            {/* {cardData?.startSessionCountDown && ( */}
            <div
              className="position-absolute top-0 start-0 w-100 h-100 bg-dark bg-opacity-10"
              style={{
                // opacity: 0.5,
                zIndex: 1
              }}
            ></div>
            {/* )} */}
          </div>
        )}
      </div>

      <div className="medWikiCrdV1Btm w-100 float-start p-3">
        {/* poll */}
        {/* {cardData?.point && (
          <div className="mb-2">
            <span className="border border-black rounded-2 py-1 px-2 fs-5 text-black fw-semibold lh-1">
              50 Points
            </span>
          </div>
        )} */}

        {'categoryName' in cardData &&
          'specialities' in cardData &&
          'is_share' in cardData &&
          specialityArray?.length > 0 && (
            <SpecialitiesPill
              is_completed={cardData?.isCompleted}
              contentType={cardData?.contentType || ''}
              logo={cardData?.logo || ''}
              cardName={cardData?.categoryName || ''}
              specialities={specialityArray || []}
              isShare={cardData?.is_share || false}
              data={cardData}
            />
          )}

        {/*  title or doctor card  or description or button or sponsor  */}
        <div className="w-100 float-start d-flex flex-column text-start">
          {/* title */}
          {cardData?.title && (
            <div className="cursorPointer position-relative w-100" onClick={cardData?.onClick}>
              <h4 className="text-black fs-4 fw-semibold line-clamp-2 text-break">
                {removeHtmlTags(cardData?.title)}
              </h4>
            </div>
          )}

          {/* description */}
          {cardData?.description && (
            <div
              className={`cursorPointer position-relative w-100 mt-2 ${
                cardData?.internalType !== 'survey' ? 'bg-light bg-opacity-50 my-3 p-3 ' : ''
              }rounded-2`}
              onClick={cardData?.onClick}
            >
              <h4 className="text-black fw-normal line-clamp-2 text-break">
                {removeHtmlTags(cardData?.description)}
              </h4>
            </div>
          )}

          {/* certificate module */}
          {cardData?.duration && (
            <ul className="cardV2Statistics mt-2 mb-0 ps-0 d-flex gap-2 flex-wrap fs-5">
              <li className="me-3 mb-2 d-flex align-items-center gap-2">
                <i className="flaticon-layers fs-3 text-dark"></i> {cardData?.modulesCount} Modules
              </li>
              <li className="me-3 mb-2 d-flex align-items-center gap-2">
                <i className="flaticon-clock-1 fs-3 text-dark opacity-75"></i>
                {cardData?.duration} hr
              </li>
            </ul>
          )}

          {/* doctor card */}
          {cardData?.doctorCard && (
            // <div
            //   className="crdV1DoctorBox text-start w-100 float-start mt-2 cursorPointer"
            //   onClick={cardData?.doctorCard?.onClick}
            // >
            //   <div className="rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center gap-3 border border-1 border-light w-fit-content ps-1 pe-3 py-1 w-100">
            //     <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden icon-size-48 flex-shrink-0">
            //       <LazyImage
            //         src={cardData?.doctorCard?.image || ''}
            //         className="object-fit-cover h-100 w-100 float-start"
            //         alt={cardData?.doctorCard?.name || ''}
            //         fallback_image={DEMO_USER_PICTURE || ''}
            //       />
            //     </div>
            //     <div className="crdV1DoctorContent flex-grow-1">
            //       <h4 className="text-black fs-4 line-clamp-1">{cardData?.doctorCard?.name}</h4>
            //       <p className="d-block text-dark fs-5 line-clamp-1">
            //         {cardData?.doctorCard?.Department}
            //       </p>
            //     </div>
            //   </div>
            // </div>
            <div className="mt-2">
              <DoctorsSectionForCard cardData={cardData} />
            </div>
          )}

          {/* sponsor & button */}
          {cardData?.buttonName && (
            <div className="d-flex justify-content-between align-items-center position-relative mt-3">
              {/* sponsor */}

              {sponsorLogoData.length === 1 && (
                <div className="crdV1sponser rounded m-0 text-center bg-white">
                  <div className="crdV1sponserIn position-relative bg-white w-100">
                    <LazyImage
                      src={sponsorLogoData[0]}
                      className="position-absolute start-50 top-50 translate-middle mw-100 mh-100 w-auto h-auto"
                      // className="mw-100 mh-100 w-auto h-auto"
                      alt="sponsor logo"
                      logo
                    />
                  </div>
                </div>
              )}
              {sponsorLogoData?.length > 1 && (
                <Swiper
                  className="crdV1sponser position-absolute rounded m-0 text-center bg-white"
                  navigation={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 2500
                  }}
                  effect="fade"
                >
                  {sponsorLogoData?.map((logo, index) => (
                    <SwiperSlide
                      className="crdV1sponserIn position-relative bg-white w-100"
                      key={index + 1}
                    >
                      <LazyImage
                        src={logo}
                        className="position-absolute start-50 top-50 translate-middle mw-100 mh-100 w-auto h-auto"
                        alt="sponsor logo"
                        logo
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}

              {/* button */}
              {cardData?.buttonName && (
                <div className="ms-auto mt-0">
                  <button
                    onClick={cardData?.onClick}
                    className={`btn btn-sm border-1 border-${
                      cardData?.buttonColor || 'primary'
                    } d-flex gap-2 align-items-center ${cardData?.imgClass ? 'bg-primary' : ''}`}
                  >
                    <span
                      className={`text-${cardData?.buttonColor || 'primary'} ${
                        cardData?.imgClass ? 'fs-5' : ''
                      }`}
                    >
                      {cardData?.buttonName}
                    </span>
                    <CgArrowLongRight
                      customClass={`fs-4 medwiki_landing_feature_card_view_details_icon text-${cardData?.buttonColor}`}
                      gtmTagVariable="gtm_cl_medwiki_landing_feature_card"
                    />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </TrackerWrapper>
  );
});
export default memo(ListingCard);

const VideoCard = ({ cardData = '' }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoRendered, setVideoRendered] = useState(false);
  const [clVideoref, inView] = useInView({
    fallbackInView: false,
    threshold: 0
  });
  const playerRef = useRef();
  const { hide_time = false } = cardData || {};
  const onReady = () => {
    if (cardData?.play_time) {
      const timeToStart = cardData?.play_time;
      playerRef.current.seekTo(timeToStart, 'seconds');
    }
  };

  const user = getLocalStorage('user', false);
  const haveAutoPlayAccess =
    user && (user?.user_master_status == 3 || user?.user_master_status == 5) ? true : false;

  const handleVideoShow = () => {
    if (user && haveAutoPlayAccess) {
      setLoading(true);
      setShowVideo(true);
      setTimeout(() => {
        setShowVideo(false);
        setVideoRendered(false);
      }, 30000);
    }
  };
  const handleVideoClose = () => {
    setShowVideo(false);
    setLoading(false);
    setVideoRendered(false);
  };
  useEffect(() => {
    if (isMobile && !inView) {
      handleVideoClose();
    }
  }, [inView]);
  function format(seconds) {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = pad(date.getUTCMinutes());
    const ss = pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${mm}:${ss}`;
    }
    return `${mm}:${ss}`;
  }

  function pad(string) {
    return ('0' + string).slice(-2);
  }
  const handleStart = useCallback(() => {
    setLoading(false);
    setVideoRendered(true);
  }, []);

  if (!cardData?.type_id) return null;
  return (
    <div
      className="w-100 h-100 clVideoCardV1BoxPic cursorPointer cmnVideoBoxContainer position-relative overflow-hidden"
      ref={clVideoref}
      onMouseEnter={handleVideoShow}
      onMouseLeave={handleVideoClose}
      onTouchMove={handleVideoShow}
    >
      {showVideo ? (
        <div className="w-100 h-100 cmnVideosDbV2Video">
          <ReactPlayer
            ref={playerRef}
            onReady={onReady}
            onStart={handleStart}
            url={cardData?.video?.src}
            playing={true}
            controls={false}
            width="100%"
            height="100%"
            muted={true}
            playsinline={true}
          />
        </div>
      ) : null}

      <LazyImage
        src={cardData?.image ?? cardData?.fallbackImage}
        alt="video"
        className="object-fit-cover h-100 w-100 float-start"
        fallback_image={cardData?.fallbackImage}
      />
      <div
        className={`overlay cursorPointer d-flex align-items-center justify-content-center ${
          loading ? 'dbClLoading' : ''
        }${videoRendered ? 'dbClAfterLoading' : ''} gtm_cl_clinicalVideoCard`}
      >
        <RiPlayCircleFill customClass="text-white fs-52 position-absolute top-50 start-50 translate-middle" />
        <Spinner animation="border" />
      </div>
      <div
        className="w-100 h-100 cmnVideosTrigr cursorPointer gtm_cl_clinicalVideoCard"
        // onClick={cardData?.onClick}
      ></div>

      {/* {cardData?.video?.videoDuration && (
        <span className="text-white fs-4 clVideoCardV1Time rounded position-absolute z-3 bg-black bg-opacity-75 py-1 px-2">
          {format(cardData?.video?.videoDuration)}
        </span>
      )} */}
      {/*  cardData?.session_status && hide_time  == only for web vid */}
      {cardData?.video?.videoDuration && (
        <span
          className={`text-white fs-4 ${
            cardData?.session_status && hide_time ? 'top-0 end-0 m-2' : 'clVideoCardV1Time'
          } rounded position-absolute z-3 bg-black bg-opacity-75 py-1 px-2`}
        >
          {format(cardData?.video?.videoDuration)}
        </span>
      )}

      {loading && !isMobile && (
        <span className="text-white fs-4 clVideoCardV1Keep rounded position-absolute z-3 bg-black bg-opacity-75 py-1 px-2">
          Keep hovering to preview
        </span>
      )}

      {cardData?.video?.videoDuration &&
        cardData?.video?.play_time &&
        (parseInt(cardData?.video?.play_time) / parseInt(cardData?.video?.videoDuration)) * 100 >
          0 && (
          <ProgressBar
            now={
              (parseInt(cardData?.video?.play_time) / parseInt(cardData?.video?.videoDuration)) *
              100
            }
          />
        )}
    </div>
  );
};
