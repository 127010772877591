import { bannerConstants } from '../constants';
import { setSessionStorage } from '../../common/common';
import { filterJsonByPosition } from '../../components/banner/utils/filterJsonByPosition';
import { bannerLocalConstant } from '../../components/banner/utils/bannerLocalConstant';

const innitial_current_queue = {
  top_banner_data: [],
  side_top_banner_data: [],
  bottom_banner_data: [],
  side_bottom_banner_data: [],
  list_banner_data: [],
  video_banner_data: [],
  video_floating_banner_data: [],
  interstitial_banner_data: []
};

const innitial_banner_render_sequence = {
  top: true,
  'side-top': true,
  bottom: true,
  'side-bottom': true,
  listing: true,
  video: true,
  'video-floating': true,
  interstitial: true
};

let initialState = {
  banner_data: '',
  content_banner_data: '',
  banner_report_data: [],
  banner_exclution_list: [],
  banner_settings: [],
  content_data_get: false,
  banner_card_index: 0,
  current_banner_queue: innitial_current_queue,
  banner_render_sequence: [],
  is_interstitial_ads_show: false,
  is_interstitial_ads_eligible: true,
  played_campaign: [],
  is_consent_modal: false,
  is_consent_given: false,
  consent_text: '',
  showSponsorBanner: innitial_banner_render_sequence,
  banner_fetch_status: 0
};

export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case bannerConstants.GET_BANNER_DATA_SUCCESS:
      return {
        ...state,
        banner_data: getFormatedBannerData(action.payload.data)
      };
    case bannerConstants.GET_BANNER_DATA_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };
    case bannerConstants.GET_CONTENT_BANNER_DATA_SUCCESS:
      return {
        ...state,
        content_banner_data: getFormatedBannerData(action.payload.data)
      };
    case bannerConstants.GET_CONTENT_BANNER_DATA_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };

    case bannerConstants.SYNC_LOCAL_DATA_WITH_STORE_SUCCESS:
      return {
        ...state,
        banner_data: action.payload
      };
    case bannerConstants.SYNC_LOCAL_DATA_WITH_STORE_FALIURE:
      return {
        ...state,
        banner_data: {}
      };
    case bannerConstants.SYNC_EXCLUTION_LIST_WITH_STORE_SUCCESS:
      return {
        ...state,
        banner_exclution_list: action.payload
      };
    case bannerConstants.SYNC_LOCAL_DATA_WITH_STORE_FALIURE:
      return {
        ...state,
        banner_exclution_list: []
      };
    case bannerConstants.SYNC_BANNER_SETTINGS_WITH_STORE_SUCCESS:
      return {
        ...state,
        banner_settings: action.payload
      };
    case bannerConstants.SYNC_BANNER_SETTINGS_WITH_STORE_FALIURE:
      return {
        ...state,
        banner_settings: []
      };
    case bannerConstants.UPDATE_BANNER_REPORT:
      return {
        ...state,
        banner_report_data: [...state.banner_report_data, action.payload]
      };
    case bannerConstants.REPORT_BANNER_DATA_SUCCESS:
      return {
        ...state,
        banner_report_data: []
      };
    case bannerConstants.REPORT_BANNER_DATA_FALIURE:
      return {
        ...state,
        banner_report_data: [...state.banner_report_data]
      };
    case bannerConstants.CLEAR_BANNER_REPORT_DATA:
      return {
        ...state,
        banner_report_data: action.payload
      };
    case bannerConstants.GET_EXCLUTION_LIST_DATA_SUCCESS:
      return {
        ...state,
        banner_exclution_list: action.payload
      };
    case bannerConstants.GET_EXCLUTION_LIST_DATA_FALIURE:
      return {
        ...state,
        banner_exclution_list: []
      };
    case bannerConstants.GET_BANNER_SETTING_SUCCESS:
      return {
        ...state,
        banner_settings: action.payload
      };
    case bannerConstants.GET_CONTENT_BANNER_DATA_FALIURE:
      return {
        ...state,
        banner_settings: []
      };
    case bannerConstants.CLEAR_CONTENT_BANNER_DATA:
      return {
        ...state,
        content_banner_data: action.payload
      };
    case bannerConstants.CLEAR_GLOBAL_BANNER_DATA:
      return {
        ...state,
        banner_data: action.payload
      };
    case bannerConstants.CONTENT_BANNER_DATA_FETCHED:
      return {
        ...state,
        content_data_get: action.payload
      };
    case bannerConstants.CLEAR_CONTENT_BANNER_DATA_FETCHED:
      return {
        ...state,
        content_data_get: action.payload
      };
    case bannerConstants.UPDATE_BANNER_CARD_INDEX:
      return {
        ...state,
        banner_card_index: action.payload
      };
    case bannerConstants.UPDATE_CURRENT_QUEUE:
      setSessionStorage(bannerLocalConstant.PLAYED_CATEGORY, JSON.stringify(action.payload));

      // console.log('Banner reducer:', action.payload);
      return {
        ...state,
        current_banner_queue: action.payload
      };

    case bannerConstants.CAMPAIGN_PLAYED:
      return {
        ...state,
        played_campaign: [...state.played_campaign, action.payload]
      };

      break;
    case bannerConstants.UPDATE_BANNER_SEQUENCE:
      return {
        ...state,
        banner_render_sequence: action.payload
      };

    case bannerConstants.REMOVE_SPONSOR_ADS:
      let tempShowSponsorBanner = { ...state.showSponsorBanner };
      tempShowSponsorBanner[action.position] = action.payload;
      return {
        ...state,
        showSponsorBanner: { ...tempShowSponsorBanner }
      };

    case bannerConstants.RESET_SPONSOR_ADS_SHOW_STATUS:
      console.log('remove sponsor ads after', state.showSponsorBanner, action);
      return {
        ...state,
        showSponsorBanner: { ...innitial_banner_render_sequence }
      };

    case bannerConstants.CLEAN_CURRENT_QUEUE:
      const current_queue = {
        top_banner_data: [],
        side_top_banner_data: [],
        bottom_banner_data: [],
        side_bottom_banner_data: [],
        list_banner_data: [],
        video_banner_data: [],
        video_floating_banner_data: [],
        interstitial_banner_data: []
      };
      return {
        ...state,
        current_banner_queue: current_queue
      };

    case bannerConstants.TOOGLE_INTERSTITAL_ADS:
      return {
        ...state,
        is_interstitial_ads_show: !state.is_interstitial_ads_show
      };
    case bannerConstants.SHOW_INTERSTITAL_ADS:
      return {
        ...state,
        is_interstitial_ads_show: true
      };
    case bannerConstants.HIDE_INTERSTITAL_ADS:
      return {
        ...state,
        is_interstitial_ads_show: false
      };
    case bannerConstants.ENABLE_INTERSTITIAL_ADS:
      return {
        ...state,
        is_interstitial_ads_eligible: true,
        is_interstitial_ads_show: false
      };
    case bannerConstants.DISABLE_INTERSTITIAL_ADS:
      return {
        ...state,
        is_interstitial_ads_eligible: false,
        is_interstitial_ads_show: false
      };
    case bannerConstants.TOGGLE_CONSENT_MODAL:
      return {
        ...state,
        is_consent_modal: action.payload
      };
    case bannerConstants.SET_CONSENT_STATUS:
      return {
        ...state,
        is_consent_given: action.payload
      };
    case bannerConstants.SET_CONSENT_TEXT:
      return {
        ...state,
        consent_text: action.payload
      };

    case bannerConstants.UPDATE_BANNER_FETCH_STATUS:
      return {
        ...state,
        banner_fetch_status: action.payload
      };

    default:
      return state;
  }
};

const getFormatedBannerData = (params_data = []) => {
  let arg_data = params_data;
  // arg_data?.filter((item) => {
  //   // console.log('reducer view per day bf', item.view_per_day);
  //   item.view_per_day = item?.view_per_day * (item?.ad_factor > 0 ? item?.ad_factor : 1);
  //   // console.log('reducer view per day af', item.view_per_day);
  //   return item;
  // });

  if (arg_data?.length > 0) {
    const temp_local_banner_data = {
      top_banner_data: filterJsonByPosition(arg_data, bannerLocalConstant.BANNER_POSITION_TOP),
      side_top_banner_data: filterJsonByPosition(
        arg_data,
        bannerLocalConstant.BANNER_POSITION_SIDE_TOP
      ),
      bottom_banner_data: filterJsonByPosition(
        arg_data,
        bannerLocalConstant.BANNER_POSITION_BOTTOM
      ),
      side_bottom_banner_data: filterJsonByPosition(
        arg_data,
        bannerLocalConstant.BANNER_POSITION_SIDE_BOTTOM
      ),
      list_banner_data: filterJsonByPosition(arg_data, bannerLocalConstant.BANNER_POSITION_LIST),
      section_header_banner_data: filterJsonByPosition(
        arg_data,
        bannerLocalConstant.BANNER_POSITION_SECTION_HEADER
      ),
      video_banner_data: filterJsonByPosition(arg_data, bannerLocalConstant.BANNER_POSITION_VIDEO),
      video_floating_banner_data: filterJsonByPosition(
        arg_data,
        bannerLocalConstant.BANNER_POSITION_IN_VIDEO_FLOATING
      ),
      interstitial_banner_data: filterJsonByPosition(
        arg_data,
        bannerLocalConstant.BANNER_POSITION_INTERSTITIAL
      )
    };
    return temp_local_banner_data;
  } else {
    return '';
  }
};
