import {
  SAVE_FILE_CONSENT,
  SAVE_FILE_OBJECT,
  SAVE_FILE_PRESCRIPTION
} from '../constants/app.constants';

export const saveFileObject = (payload) => {
  return {
    type: SAVE_FILE_OBJECT,
    payload
  };
};
export const saveFileConsent = (payload) => {
  return {
    type: SAVE_FILE_CONSENT,
    payload
  };
};
export const saveFilePrescription = (payload) => {
  return {
    type: SAVE_FILE_PRESCRIPTION,
    payload
  };
};
