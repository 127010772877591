export const Fetch_Running_Election = 'Fetch_Running_Election';
export const FETCH_POPULAR_POST = 'FETCH_POPULAR_POST';
export const USER_SUGGESTION_CHANNEL = 'USER_SUGGESTION_CHANNEL';
export const USER_CHANNEL_LIST = 'USER_CHANNEL_LIST';
export const USER_CHANNEL_MANAGE_LIST = 'USER_CHANNEL_MANAGE_LIST';
export const USER_SUGGESTION_MANAGE_LIST = 'USER_SUGGESTION_MANAGE_LIST';
export const GET_CHANNEL_DETAILS = 'GET_CHANNEL_DETAILS';
export const GET_ALL_CHANNEL_DATA = 'GET_ALL_CHANNEL_DATA';
export const MOST_VIEWED_VIDEO = 'MOST_VIEWED_VIDEO';
export const RECENT_CME = 'RECENT_CME';
export const TRENDING_SPQ = 'TRENDING_SPQ';
export const GET_FEATURED_CHANNEL = 'GET_FEATURED_CHANNEL';
export const TRENDING_EBOOK = 'TRENDING_EBOOK';
export const MOST_FOLLOWED_CHANNEL = 'MOST_FOLLOWED_CHANNEL';
export const TOP_RATED_MEDWIKI = 'TOP_RATED_MEDWIKI';
export const FOLLOWED_COUNT = 'FOLLOWED_COUNT';
export const CHANNEL_ACTIVITY_COUNT = 'CHANNEL_ACTIVITY_COUNT';
