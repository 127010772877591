import React from 'react';
import {
  AiOutlineComment,
  BiLike,
  BiShare,
  BsUpload,
  CertificateBigIcon,
  CgArrowLongRight,
  ClinicalVideoLogo,
  FaCertificate,
  FaRegUser,
  GrEdit,
  Grlogo,
  GrView,
  HiMenuAlt2,
  LiveCmeLogo,
  MdOutlineArrowRightAlt,
  MdOutlineFeaturedVideo,
  MdOutlineTravelExplore,
  MedwikiLogo,
  RecentLogo,
  RiBarChart2Line,
  RiReservedFill,
  RiSearchLine,
  BsPlusLg,
  SpqLogo,
  VscDebugDisconnect,
  AiOutlineQuestionCircle
} from '../icons';
import {
  BiBuildings,
  BsArrowRight,
  BsCameraVideo,
  BsCardText,
  BsCircle,
  BsGraphUp,
  BsHeart,
  BsPencilSquare,
  CgProfile,
  FaRegUserCircle,
  GoGraph,
  GrCertificate,
  MdOutlineFeaturedPlayList,
  MdOutlineMedicalServices,
  MdOutlineQuiz,
  MdOutlineRecommend,
  MdOutlineStarRate,
  MdOutlineUpcoming,
  MdOutlineVideoCall,
  MdOutlineVolunteerActivism,
  RiReservedLine,
  RiSlideshowLine,
  TbBadge,
  TbCertificate,
  TbListDetails,
  ChangeSpeciality,
  MenuTab,
  ExploreMenu
} from './icons';

export const JoyrideIcons = [
  { name: 'BsUpload', icon: <BsUpload customClass="text-white fs-2" /> },
  { name: 'CertificateBigIcon', icon: <CertificateBigIcon customClass="text-white fs-2" /> },
  { name: 'CgArrowLongRight', icon: <CgArrowLongRight customClass="text-white fs-2" /> },
  { name: 'ClinicalVideoLogo', icon: <ClinicalVideoLogo className="text-white fs-2" /> },
  { name: 'FaCertificate', icon: <FaCertificate customClass="text-white fs-2" /> },
  { name: 'HiMenuAlt2', icon: <HiMenuAlt2 customClass="text-white fs-2" /> },
  { name: 'FaRegUser', icon: <FaRegUser customClass="text-white fs-2" /> },
  { name: 'LiveCmeLogo', icon: <LiveCmeLogo className="text-white fs-2" /> },
  { name: 'MdOutlineQuiz', icon: <MdOutlineQuiz className="text-white fs-2" /> },
  { name: 'MedwikiLogo', icon: <MedwikiLogo className="text-white fs-2" /> },
  { name: 'BsGraphUp', icon: <BsGraphUp customClass="text-white fs-2" /> },
  { name: 'GoGraph', icon: <GoGraph customClass="text-white fs-2" /> },
  { name: 'FaRegUserCircle', icon: <FaRegUserCircle customClass="text-white fs-2" /> },
  { name: 'MdOutlineRecommend', icon: <MdOutlineRecommend customClass="text-white fs-2" /> },
  {
    name: 'MdOutlineFeaturedPlayList',
    icon: <MdOutlineFeaturedPlayList customClass="text-white fs-2" />
  },
  { name: 'MdOutlineUpcoming', icon: <MdOutlineUpcoming customClass="text-white fs-2" /> },
  { name: 'RiReservedLine', icon: <RiReservedLine customClass="text-white fs-2" /> },
  { name: 'BsCircle', icon: <BsCircle customClass="text-white fs-2" /> },
  { name: 'MdOutlineVideoCall', icon: <MdOutlineVideoCall customClass="text-white fs-2" /> },
  {
    name: 'MdOutlineMedicalServices',
    icon: <MdOutlineMedicalServices customClass="text-white fs-2" />
  },
  { name: 'MdOutlineStarRate', icon: <MdOutlineStarRate customClass="text-white fs-2" /> },
  { name: 'BsPencilSquare', icon: <BsPencilSquare customClass="text-white fs-2" /> }, //pencile
  { name: 'BsCameraVideo', icon: <BsCameraVideo customClass="text-white fs-2" /> },
  { name: 'CgProfile', icon: <CgProfile customClass="text-white fs-2" /> },
  { name: 'TbCertificate', icon: <TbCertificate customClass="text-white fs-2" /> },
  { name: 'TbBadge', icon: <TbBadge customClass="text-white fs-2" /> },
  { name: 'TbListDetails', icon: <TbListDetails customClass="text-white fs-2" /> },
  { name: 'RiSlideshowLine', icon: <RiSlideshowLine customClass="text-white fs-2" /> },
  {
    name: 'AiOutlineQuestionCircle',
    icon: <AiOutlineQuestionCircle customClass="text-white fs-2" />
  },
  { name: 'BsArrowRight', icon: <BsArrowRight customClass="text-white fs-2" /> },
  {
    name: 'MdOutlineVolunteerActivism',
    icon: <MdOutlineVolunteerActivism customClass="text-white fs-2" />
  },
  { name: 'BiBuildings', icon: <BiBuildings customClass="text-white fs-2" /> },
  { name: 'circle', icon: <RiSearchLine customClass="text-white fs-2" /> },
  { name: 'RiSearchLine', icon: <RiSearchLine customClass="text-white fs-2" /> },
  { name: 'GrCertificate', icon: <GrCertificate customClass="text-white fs-2" /> },
  { name: 'RiBarChart2Line', icon: <RiBarChart2Line customClass="text-white fs-2" /> },
  { name: 'BsPlusLg', icon: <BsPlusLg customClass="text-white fs-2" /> },
  { name: 'RiReservedFill', icon: <RiReservedFill customClass="text-white fs-2" /> },
  { name: 'BsHeart', icon: <BsHeart customClass="text-white fs-2" /> },
  {
    name: 'MdOutlineArrowRightAlt',
    icon: <MdOutlineArrowRightAlt customClass="text-white fs-2" />
  },
  { name: 'GrEdit', icon: <GrEdit customClass="text-white fs-2" /> },
  { name: 'RecentLogo', icon: <RecentLogo customClass="text-white fs-2" /> },
  { name: 'SpqLogo', icon: <SpqLogo className="text-white fs-2" /> },
  { name: 'Grlogo', icon: <Grlogo className="text-white fs-2" /> },
  {
    name: 'MdOutlineTravelExplore',
    icon: <MdOutlineTravelExplore customClass="text-white fs-2" />
  },
  {
    name: 'MdOutlineFeaturedVideo',
    icon: <MdOutlineFeaturedVideo customClass="text-white fs-2" />
  },
  {
    name: 'BiShare',
    icon: <BiShare customClass="text-white fs-2" />
  },
  {
    name: 'GrView',
    icon: <GrView customClass="text-white fs-2" />
  },
  {
    name: 'VscDebugDisconnect',
    icon: <VscDebugDisconnect customClass="text-white fs-2" />
  },
  {
    name: 'BiLike',
    icon: <BiLike customClass="text-white fs-2" />
  },
  {
    name: 'AiOutlineComment',
    icon: <AiOutlineComment customClass="text-white fs-2" />
  },
  { name: 'BsCardText', icon: <BsCardText customClass="text-white fs-2" /> },
  { name: 'ChangeSpeciality', icon: <ChangeSpeciality /> },
  { name: 'MenuTab', icon: <MenuTab /> },
  { name: 'ExploreMenu', icon: <ExploreMenu /> }
];
