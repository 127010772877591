import axiosInstance from '../../Store/helper/axiosInstance.js';
// import Worker from '../worker/tracker.worker';
const url = process.env.REACT_APP_API_URL;

export async function sendTrackingReport(report) {
  console.log('TRACK REPORT', report);
  try {
    const response = await axiosInstance.post('tracking/data', report);

    let status_code = response.data.status_code;
    if (status_code == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    // alert(error)
    // navigator.sendBeacon(`${url}openapi/tracking`,report);
    return false;
  }
}

export async function sendTrackingReportOpen(report) {
  try {
    const response = await fetch(url + 'openapi/tracking', {
      method: 'POST',
      body: JSON.stringify(report)
    });
    const responseJson = await response.json();
    let status_code = responseJson.status_code;
    if (status_code == 200) {
      return responseJson;
    } else {
      return false;
    }
  } catch (error) {
    // alert(error)
    // navigator.sendBeacon(`${url}openapi/tracking`,report);
    return false;
  }
}

// export function trackEvent(_event="not found",_data="") {
//   if (_data) {
//     const myWorker = new Worker();
//     myWorker.postMessage({event:_event,data:_data});
//     myWorker.onmessage = (e) => {
//       if (e) {
//         console.log("Time Taken");
//       }
//     };
//     myWorker.terminate()
//  }
// }
