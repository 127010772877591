import { log } from '../../common/common';
import { logoutUser } from './login.action';
import { dashboardConstants } from '../constants/dashboard.constant';
import axiosInstance from '../helper/axiosInstance';
import nodeServiceInstance from '../helper/nodeServiceInstance';

const getDocVoiceSuccess = (payload) => {
  return {
    type: dashboardConstants.DOCTOR_VOICE_SUCCESS,
    payload
  };
};
const getDocVoiceFailure = (payload) => {
  return {
    type: dashboardConstants.DOCTOR_VOICE_FAILURE,
    payload
  };
};
const getSessionSuccess = (payload) => {
  return {
    type: dashboardConstants.SESSION_SLIDER_SUCCESS,
    payload
  };
};
const getSessionFailure = (payload) => {
  return {
    type: dashboardConstants.SESSION_SLIDER_FAILURE,
    payload
  };
};
const getMedwikiSuccess = (payload) => {
  return {
    type: dashboardConstants.TRENDING_COMP_SUCCESS,
    payload
  };
};
const getMedwikiFailure = (payload) => {
  return {
    type: dashboardConstants.TRENDING_COMP_FAILURE,
    payload
  };
};
const getTrendingGrSuccess = (payload) => {
  return {
    type: dashboardConstants.TRENDING_GR_SUCCESS,
    payload
  };
};
const getTrendingGrFailure = (payload) => {
  return {
    type: dashboardConstants.TRENDING_GR_FAILURE,
    payload
  };
};
const getSurveySuccess = (payload) => {
  return {
    type: dashboardConstants.TRENDING_SURVEY_SUCCESS,
    payload
  };
};
const getSurveyFailure = (payload) => {
  return {
    type: dashboardConstants.TRENDING_SURVEY_FAILURE,
    payload
  };
};
const getVideoSuccess = (payload) => {
  return {
    type: dashboardConstants.TRENDING_VIDEO_SUCCESS,
    payload
  };
};
const getVideoFailure = (payload) => {
  return {
    type: dashboardConstants.TRENDING_VIDEO_FAILURE,
    payload
  };
};
const getRecentSuccess = (payload) => {
  return {
    type: dashboardConstants.RECENT_SUCCESS,
    payload
  };
};
const getRecentFailure = (payload) => {
  return {
    type: dashboardConstants.RECENT_FAILURE,
    payload
  };
};

export const getDoctorVoice = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('dashboard/poll')
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch(getDocVoiceSuccess(response.data));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        } else {
          dispatch(getDocVoiceFailure(error));
        }
      });
  };
};
export const getSessionSlider = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('dashboard/session')
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch(getSessionSuccess(response.data));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        } else {
          dispatch(getSessionFailure(error));
        }
      });
  };
};

export const getUserInformation = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('dashboard/user?response')
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv26/dashboard/data?from=0&to=8
export const getTrending = (fetch_from, to, trendingType, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`dashboard/trending?from=${fetch_from}&to=${to}&type=${trendingType}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          switch (trendingType) {
            case 'all':
              dispatch(getRecentSuccess(response.data));
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        switch (trendingType) {
          case 'all':
            dispatch(getRecentFailure(error));
            break;
          default:
            break;
        }
      });
  };
};
export const getAllData = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('dashboard/data?from=0&to=8')
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch(getMedwikiSuccess(response.data));
          dispatch(getSessionSuccess(response.data));
          dispatch(getTrendingGrSuccess(response.data));
          dispatch(getSurveySuccess(response.data));
          dispatch(getVideoSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch(getMedwikiFailure(error));
        dispatch(getSessionFailure(error));
        dispatch(getTrendingGrFailure(error));
        dispatch(getSurveyFailure(error));
        dispatch(getVideoFailure(error));
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv26/dashboard/user?response

// speaciality
export const getSpecialityFeedData = (pageParam, speciality, type, callback) => {
  return async () => {
    nodeServiceInstance
      .get(
        `specialist/get-specialist-data?from=${pageParam}&to=15&speciality=${speciality}&type=${type}`
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};

export const updateUserViewSpeciality = (payload) => {
  return {
    type: dashboardConstants.CHANGE_USER_SPECIALITY_VIEW,
    payload
  };
};
