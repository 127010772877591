import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAccessError } from '../../Store/actions';

function AccessErrorModal() {
  const accessError = useSelector((state) => state.common.accessError);
  const dispatch = useDispatch();

  return (
    <Modal
      className="centerModal"
      size="md"
      centered
      show={accessError}
      onHide={() => dispatch(toggleAccessError(false))}
    >
      <Modal.Header className="position-relative p-4 border-0 mb-1" closeButton>
        <Modal.Title className="fs-16 text-black fw-semibold">Please Note</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <form>
          <div className="w-100 centerModalFrm">
            <div className="row mt-n4">
              <div className="w-100 fw-medium fs-4 my-2 text-black">
                Your account is pending verification.
              </div>
              <div className="w-100 fw-medium fs-4 my-2 text-black">
                As MediSamvad is for registered medical Practitioners only, we need to verify your
                registration. Please allow us some time to onboard you.
              </div>
              <div className="d-flex justify-content-end">
                <div
                  className="btn btn-outline-primary btn-sm mt-4 "
                  onClick={() => dispatch(toggleAccessError(false))}
                >
                  Ok
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AccessErrorModal;
