import React, { memo, useEffect, useState } from 'react';
import '../css/medwikiDetailsV2.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import MedwikiRelatedPostLoader from '../../../skeleton/medwiki/MedwikiDetailsV2/MedwikiRelatedPostLoader';
import MedwikiRelatedPostCard from '../MedwikiDetailsComponents/MedwikiRelatedPostCard';
import { routeNames } from '../../../router/constants';
import { default_images } from '../../../common/defaultImages';
import {
  ClinicalVideoLogo,
  Grlogo,
  LiveCmeLogo,
  MedwikiLogo,
  SpqLogo
} from '../../../components/icons';
import useRedirect from '../../../common/hooks/useRedirect';
const MedwikiPopular = ({
  posts,
  isLoading,
  title,
  onExploreMoreClick,
  buttonText,
  eventDateShow
}) => {
  const render_Medwiki_Top_Post = (item, index) => {
    const type = item?.trending_type ? item?.trending_type : item?.type;
    switch (type) {
      case 'comp': {
        return (
          <MedwikiRelatedPostCard
            gtmTagVariable="gtm_cl_medwiki_details_medwiki"
            key={index + 1}
            DetailPageName={routeNames.medwiki.detail}
            data={item}
            typeId={item.type_id}
            pageQuestion={item.question}
            class_name={'medwikiRelatedPost mb-4 medwiki '}
            pageName={item?.sub_category || item?.category}
            item={item}
            default_image={default_images.medwiki}
            logo={<MedwikiLogo className={'fs-3 text-white'} />}
            eventDate={eventDateShow ? item?.date : null}
          />
        );
      }
      case 'survey': {
        return (
          <MedwikiRelatedPostCard
            gtmTagVariable="gtm_cl_medwiki_details_survey"
            key={index + 1}
            DetailPageName={item.category == 'poll' ? routeNames.spq.poll : routeNames.spq.detail}
            typeId={item.survey_id}
            data={item}
            pageQuestion={item.survey_title}
            class_name={'medwikiRelatedPost mb-4 spqBox p-3 rounded-3 '}
            pageName={item?.sub_category || item?.category}
            item={item}
            default_image={item.category == 'quiz' ? default_images.quiz : default_images.survey}
            logo={<SpqLogo className={'fs-3 text-white'} />}
            eventDate={eventDateShow ? item?.date : null}
          />
        );
      }
      case 'gr': {
        return (
          <MedwikiRelatedPostCard
            gtmTagVariable="gtm_cl_medwiki_details_gr"
            key={index + 1}
            DetailPageName={routeNames.grandRound.detail}
            typeId={item.type_id}
            pageQuestion={item.title}
            class_name={'medwikiRelatedPost mb-4 grTraining '}
            pageName={item?.sub_category || item?.category}
            item={item}
            default_image={default_images.gr}
            logo={<Grlogo className={'fs-3 text-white'} />}
            data={item}
            eventDate={eventDateShow ? item?.date : null}
          />
        );
      }
      case 'session': {
        return (
          <MedwikiRelatedPostCard
            gtmTagVariable="gtm_cl_medwiki_details_session"
            key={index + 1}
            DetailPageName={routeNames.sessions.detail}
            typeId={item.session_id}
            pageQuestion={item.session_topic}
            class_name={'medwikiRelatedPost mb-4 liveCME '}
            pageName={item?.sub_category || item?.category}
            item={item}
            default_image={default_images.session}
            logo={<LiveCmeLogo className={'fs-3 text-white'} />}
            data={item}
            eventDate={item?.start_datetime}
          />
        );
      }
      case 'video_archive': {
        if (!item?.type_id) return null;
        else
          return (
            <MedwikiRelatedPostCard
              gtmTagVariable="gtm_cl_medwiki_details_videoArchive"
              key={index + 1}
              DetailPageName={routeNames.clinicalVideo.detail}
              typeId={item.type_id}
              pageQuestion={item.question}
              class_name={'medwikiRelatedPost mb-4 clinicalVideo '}
              pageName={item?.sub_category || item?.category}
              item={item}
              default_image={default_images.clinical}
              logo={<ClinicalVideoLogo className={'fs-3 text-white'} />}
              data={item}
              eventDate={eventDateShow ? item?.date : null}
            />
          );
      }
    }
  };

  console.log('posts', posts);

  const { redirectTo } = useRedirect();

  return (
    <>
      {isLoading == false && posts && posts.length > 0 ? (
        <div
          className="w-100 medwikiRelatedBox bg-white rounded-3 shadow text-center p-3 p-md-4"
          id="medwiki_related_post"
        >
          <h2 className="text-black fs-3 fw-medium text-start mb-4">
            {title?.split(' ')[0]}
            <span className="text-primary">
              {title?.slice(title?.split(' ')[0].length, title?.length)}
            </span>
          </h2>
          <div className="medwikiRelatedBoxIn">
            {posts &&
              posts.map((item, index) => (item ? render_Medwiki_Top_Post(item, index) : null))}
          </div>
          <a
            href="javascript:void(0)"
            className="w-100 fs-4 fw-medium btn btn-secondary rippleEffect mt-4 rounded-3 gtm_cl_medwiki_details_medwikiRelatedPost_exploreMore py-3"
            onClick={() => onExploreMoreClick()}
            id="medwiki_related_post_view_more"
          >
            {buttonText ? buttonText : 'Explore More'}
          </a>
        </div>
      ) : isLoading == true ? (
        <MedwikiRelatedPostLoader title={title} />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(MedwikiPopular);
