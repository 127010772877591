import React, { memo } from 'react';
import LazyImage from '../CustomLibraries/LazyImage';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import DoctorCircleCards from '../doctorCircles/DoctorCircleCards';
import './css/doctorSectionForCard.scss';
import { DEMO_USER_PICTURE } from '../../common/common';
import { routeNames } from '../../router/constants';
import useRedirect from '../../common/hooks/useRedirect';
const DoctorsSectionForCard = ({ cardData, type, id, className = '', gtmTagVariable = '' }) => {
  console.log(cardData, 'cardData');
  const { redirectTo } = useRedirect();
  const DoctorSwiper = ({ cardData }) => {
    return (
      <Swiper spaceBetween={6} slidesPerView={'auto'} loop={false}>
        {cardData?.session_doctor_entities?.map((_doc, _idx) => {
          return _doc?.session_doctor_id ? (
            <SwiperSlide
              key={_idx + 1}
              className="crdV1DoctorBox text-start w-auto float-start mw-100 border border-1 border-light px-3 py-2 rounded"
              onClick={() => {
                if (_doc?.session_doctor_id) {
                  redirectTo(
                    routeNames.sessions.speaker,
                    _doc?.session_doctor_id,
                    _doc?.session_doctor_name
                  );
                }
              }}
            >
              <div className="w-100 rounded crdV1DoctorBoxIn d-flex justify-content-between align-items-center  gap-3">
                {_doc?.session_doctor_image && (
                  <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden icon-size-48 flex-shrink-0">
                    <LazyImage
                      src={_doc?.session_doctor_image}
                      alt={_doc?.session_doctor_name}
                      className={`object-fit-cover h-100 w-100 float-start ${gtmTagVariable}_doctorsSectionForCard`}
                      // id={`${gtmTagVariable}_doctorsSectionForCard_image_${_doc?.session_doctor_id}`}
                      fallback_image={DEMO_USER_PICTURE}
                      logo
                    />
                  </div>
                )}
                <div className="crdV1DoctorContent ps-md-4 ps-3">
                  {_doc?.session_doctor_name && (
                    <h4
                      className={`text-black fs-4 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                    >
                      {_doc?.session_doctor_name}
                    </h4>
                  )}
                  {_doc?.DepartmentName && (
                    <p
                      className={`d-block text-dark fs-5 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                    >
                      {_doc?.DepartmentName}
                    </p>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ) : null;
        })}
      </Swiper>
    );
  };
  return (
    <>
      {cardData?.session_doctor_entities?.length <= 2 && (
        <div
          className={`w-100 float-start cmncrdV1Doctor text-start cursorPointer ${
            className ? className : ''
          }`}
        >
          {/* if single doctor ====================*/}
          {cardData?.session_doctor_entities?.length == 1 &&
            cardData?.session_doctor_entities[0]?.session_doctor_id && (
              <div
                className="crdV1DoctorBox text-start w-100 float-start"
                onClick={() => {
                  if (cardData?.session_doctor_entities[0]?.session_doctor_id) {
                    redirectTo(
                      routeNames.sessions.speaker,
                      cardData?.session_doctor_entities[0]?.session_doctor_id,
                      cardData?.session_doctor_entities[0]?.session_doctor_name
                    );
                  }
                }}
              >
                <div className="rounded w-100 mb-2 crdV1DoctorBoxIn d-flex justify-content-between align-items-center gap-3 border border-1 border-light w-fit-content ps-1 pe-3 py-1">
                  <div className="crdV1DoctorPic rounded-circle position-relative overflow-hidden icon-size-48 flex-shrink-0">
                    <LazyImage
                      src={cardData?.session_doctor_entities[0]?.session_doctor_image}
                      alt={cardData?.session_doctor_entities[0]?.session_doctor_name}
                      className={`object-fit-cover h-100 w-100 float-start ${gtmTagVariable}_doctorsSectionForCard`}
                      // id={`${gtmTagVariable}_doctorsSectionForCard_image_${cardData?.session_doctor_entities[0]?.session_doctor_id}`}
                      fallback_image={DEMO_USER_PICTURE}
                      logo
                    />
                  </div>
                  <div className="crdV1DoctorContent flex-grow-1">
                    <h4
                      className={`text-black fs-4 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                    >
                      {cardData?.session_doctor_entities[0]?.session_doctor_name}
                    </h4>
                    <p
                      className={`d-block text-dark fs-5 line-clamp-1 ${gtmTagVariable}_doctorsSectionForCard`}
                    >
                      {cardData?.session_doctor_entities[0]?.DepartmentName}
                    </p>
                  </div>
                </div>
              </div>
            )}
          {/* if single doctor ====================*/}

          {/* if multi doctor ===========*/}
          {cardData?.session_doctor_entities?.length > 1 && <DoctorSwiper cardData={cardData} />}
          {/* if multi doctor =============*/}
        </div>
      )}
      {cardData?.session_doctor_entities?.length > 2 ? (
        type != 'channel_share' ? (
          <div className="w-100 float-start cmncrdV1Doctor text-start ps-3 ps-md-4 cursorPointer">
            <DoctorCircleCards
              type={type}
              // id={id}
              data={cardData}
              gtmTagVariable={`${gtmTagVariable}_doctorsSectionForCard`}
            />
          </div>
        ) : (
          <div className="w-100 float-start cmncrdV1Doctor text-start ps-3 ps-md-4 cursorPointer">
            <DoctorSwiper cardData={cardData} />
          </div>
        )
      ) : null}
    </>
  );
};

export default memo(DoctorsSectionForCard);
