import { channelConstants } from '../constants/channelConstants';
import { commonConstants } from '../constants/commonConstants';

let initialState = {
  userChannelList: [],
  userSuggestionList: [],
  allDetail: [],
  popularPosts: []
};

export const channelReducer = (state = initialState, action) => {
  switch (action.type) {
    case channelConstants.GET_USER_CHANNEL_SUCCESS:
      return {
        ...state,
        userChannelList: [...state.userChannelList, ...action.payload.data]
      };

    case channelConstants.GET_USER_CHANNEL_FALIURE:
    case channelConstants.GET_USER_CHANNEL_SUGGESTION_FALIURE:
    case channelConstants.GET_CHANNEL_DETAILS_FALIURE:
    case channelConstants.GET_CHANNEL_ALL_DATA_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };

    case channelConstants.GET_USER_CHANNEL_SUGGESTION_SUCCESS:
      return {
        ...state,
        userSuggestionList: [...state.userSuggestionList, ...action.payload.data]
      };

    // case channelConstants.GET_USER_CHANNEL_SUGGESTION_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case channelConstants.GET_CHANNEL_DETAILS_SUCCESS:
      return {
        ...state,
        ChannelDetail: action.payload.data[0]
      };

    // case channelConstants.GET_CHANNEL_DETAILS_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case channelConstants.GET_CHANNEL_ALL_DATA_SUCCESS:
      return {
        ...state,
        allDetail: action.payload.data
      };

    // case channelConstants.GET_CHANNEL_ALL_DATA_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;

    case channelConstants.GET_POPULAR_POST_SUCCESS:
      return {
        ...state,
        popularPosts: [...state.popularPosts, ...action.payload.data]
      };

    case channelConstants.GET_POPULAR_POST_FALIURE:
      return {
        ...state,
        error: action.payload.data
      };

    case channelConstants.FILTERED_ARRAY:
      return {
        ...state,
        userChannelList: state.userChannelList.filter(
          (channel) => channel.type_id != action.payload
        )
      };

    case channelConstants.CLEAR_SUGGESTION_ARRAY:
      return {
        ...state,
        userSuggestionList: []
      };

    case channelConstants.CLEAR_POPULAR_POST_ARRAY:
      return {
        ...state,
        popularPosts: []
      };

    default:
      return state;
  }
};
