import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { getLocalStorage, setLocalStorage } from '../../common/common';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('consentAccepted');
    if (hasAccepted !== 'true') {
      setTimeout(() => setShowBanner(true), 1000);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('consentAccepted', 'true');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div
      className="position-fixed bottom-0 start-50 translate-middle-x p-3 animate-slide-up"
      style={{
        zIndex: 1050,
        maxWidth: '100%',
        width: '400px'
      }}
    >
      <Alert
        className="m-0 p-4"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          borderRadius: '12px',
          boxShadow: '0 -5px 25px rgba(0,0,0,0.1)',
          border: 'none'
        }}
      >
        <style>
          {`
            @keyframes slideUp {
              from { transform: translate(-50%, 100%); opacity: 0; }
              to { transform: translate(-50%, 0); opacity: 1; }
            }
            .animate-slide-up {
              animation: slideUp 0.5s ease-out;
            }
            .custom-btn {
              transition: all 0.3s ease;
            }
            .custom-btn:hover {
              transform: translateY(-2px);
              box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            }
          `}
        </style>
        <h4 className="mb-3 fs-4 fw-semibold text-dark">We use cookies</h4>
        <p className="mb-3 fs-6 text-dark">
          Cookies help us deliver the best experience on our website. By using our website, you
          agree to the use of cookies.
        </p>
        <Button variant="secondary" onClick={handleAccept} className="w-100 custom-btn">
          Accept
        </Button>
        <Button
          variant="link"
          className="position-absolute top-0 end-0 p-2"
          onClick={() => setShowBanner(false)}
          style={{ fontSize: '1.2rem', color: '#999', textDecoration: 'none' }}
        >
          &times;
        </Button>
      </Alert>
    </div>
  );
};

export default CookieConsentBanner;
