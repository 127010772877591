import React, { useState } from 'react';
import '../styles/banner_card.scss';
function BannerItemCard({ title, bannerDiv, buttonTxt, customClass, btnClick, removeDiv }) {
  const [isHoverd, setIsHoverd] = useState(false);
  const [activeHover, setActiveHover] = useState(false);
  if (removeDiv) return <></>;
  return (
    <div
      title={title ? title : 'Sponsored Ad'}
      className={customClass ? customClass : 'w-100'}
      id="banner-container"
    >
      <div className="w-100 bannerCardV1Box">
        <div className="w-100 bannerCardV1BoxPic">
          <>
            <div>{bannerDiv}</div>
          </>
        </div>
        <div className="w-100 bannerCrdV1Btm">
          <div className="crdV1topPart w-100 text-start">
            <h2 className="text-black fs-16 fw-medium">{title}</h2>
          </div>
        </div>
        <div className="w-100 cardV1Ftr d-flex align-items-center justify-content-between px-3 pb-3">
          <span>Sponsored</span>

          <button
            className="cardV1FtrBtn ms-2 fw-medium rippleEffect rounded cursorPointer"
            onClick={btnClick}
            onMouseEnter={() => {
              setIsHoverd(true);
              setActiveHover(true);
            }}
            onMouseLeave={() => {
              setIsHoverd(false);
              setActiveHover(false);
            }}
            style={{
              border: `${isHoverd && activeHover ? '1px solid transparent' : '1px solid #355fd3'}`,
              backgroundColor: `${isHoverd && activeHover ? '#355fd3' : 'transparent'}`
            }}
          >
            <span
              style={{
                color: `${isHoverd && activeHover ? '#fff' : '#355fd3'}`
              }}
              className="d-flex align-items-center gap-1"
            >
              {buttonTxt ? buttonTxt : 'Click Now'}
              <i className="flaticon-next fs-4 ms-2"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BannerItemCard;
