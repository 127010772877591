import React from 'react';
import { useQueryClient } from 'react-query';
import { GET_ALL_CHANNEL_DATA } from '../QueryKeys/channel.key';
import { SPECIALITY_FEED_DATA } from '../QueryKeys/dashboard.key';

const useFeedInitialData = (content_type, content_id) => {
  const queryClient = useQueryClient();
  const contentChannelQueries = queryClient.getQueriesData(GET_ALL_CHANNEL_DATA);

  const contentDashboardQueries = queryClient.getQueriesData(SPECIALITY_FEED_DATA);

  const contentChannelData =
    contentChannelQueries.length > 0
      ? contentChannelQueries
          ?.map((query) => {
            if (query[1] && query[1]?.data?.data) {
              return query[1]?.data?.data;
            }
          })
          ?.flat()
      : [];

  const contentDashboardData =
    contentDashboardQueries.length > 0
      ? contentDashboardQueries
          ?.map((query) => {
            if (query[1] && query[1]?.pages && query[1]?.pages?.length > 0) {
              console.log('contentEachQueryData', query[1]);
              return query[1]?.pages?.map((page) => page?.data?.data)?.flat();
            }
          })
          ?.flat()
      : [];

  const contentChannel = contentChannelData?.find((_m) => {
    if (_m) {
      let type = _m.type || _m.trending_type;
      let type_id = _m.type_id || _m.session_id || _m.survey_id;
      return type == content_type && type_id == content_id;
    }
  });

  const contentUiV3Dashboard = contentDashboardData?.find((_m) => {
    if (_m) {
      let type = _m.type || _m.trending_type;
      let type_id = _m.type_id || _m.session_id || _m.survey_id;
      return type == content_type && type_id == content_id;
    }
  });

  return [contentChannel || null, contentUiV3Dashboard || null];
};

export default useFeedInitialData;
