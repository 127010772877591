import { commonConstants } from '../constants/commonConstants';
import { medwikiConstants } from '../constants/medwikiConstants';

let initialState = {
  MedWikiList: [],
  RelatedMedwiki: [],
  medwikiDetails: {},
  all_specialities: [],
  is_speciality_called: false
};

export const medwikiReducer = (state = initialState, action) => {
  switch (action.type) {
    case medwikiConstants.GET_MEDWIKI_LIST_SUCCESS:
    case medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_SUCCESS:
    case medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_SUCCESS:
      return {
        ...state,
        MedWikiList: [...state.MedWikiList, ...action.payload.data]
      };

    case medwikiConstants.GET_MEDWIKI_LIST_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };

    // case medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_SUCCESS:
    //   return {
    //     ...state,
    //     MedWikiList: [...state.MedWikiList, ...action.payload.data]
    //   };

    case medwikiConstants.GET_ALL_SPECILAITIES_SUCCESS:
      console.log('REDUCER RESPONSE', action?.payload);
      return {
        ...state,
        all_specialities:
          action?.payload?.speciality_data?.length > 0 ? action?.payload?.speciality_data : [],
        is_speciality_called: true
      };

    // case medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_SUCCESS:
    //   return {
    //     ...state,
    //     MedWikiList: [...state.MedWikiList, ...action.payload.data]
    //   };

    case medwikiConstants.GET_MEWIKI_DETAIL_SUCCESS:
    case medwikiConstants.GET_MEWIKI_DETAIL_FALIURE:
      return {
        ...state,
        medwikiDetails: action.payload.data
      };

    // case medwikiConstants.GET_MEWIKI_DETAIL_FALIURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case medwikiConstants.GET_ALL_SPECILAITIES_FALIURE:
      return {
        ...state,
        error: action?.payload?.data
      };

    case medwikiConstants.GET_RELATED_MEDWIKI_SUCCESS:
      return {
        ...state,
        RelatedMedwiki: [...state.RelatedMedwiki, ...action.payload.data]
      };

    case medwikiConstants.GET_RELATED_MEDWIKI_FALIURE:
      return {
        ...state,
        error: action.payload.data
      };

    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return {
        ...state,
        MedWikiList: [],
        RelatedMedwiki: [],
        medwikiDetails: {}
      };

    default:
      return state;
  }
};
