import React from 'react';
import Loadable from '../Loadable';
import { routeNames } from '../constants';

const SessionWaitingroomOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/SessionWaitingroomOutlet')
});

export const newPrivateRoute = [
  {
    path: routeNames.sessions.waitingRoom,
    paramPath: [':id', ':id/:title'],
    component: <SessionWaitingroomOutlet />,
    module: 'session'
  }
];
