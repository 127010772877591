import axios from 'axios';
import { getLocalStorage, log, setLocalStorage, setSessionStorage } from '../../common/common';

import { commonConstants } from '../constants/commonConstants';
import axiosInstance from '../helper/axiosInstance';
import { logoutUser } from './login.action';
import { updateUserViewSpeciality } from './dashboard.action';
import { shareActions } from './shareActions';
import { shareConstants } from '../constants/shareConstants';
import axiosOpenInstance from '../helper/axiosOpenInstance';

const url = process.env.REACT_APP_API_URL;

export const ClearArray = () => {
  return {
    type: commonConstants.CLEAR_ARRAY_SUCCESS
  };
};
export const toggleReference = (payload) => {
  return {
    type: commonConstants.REFERENCE_HANDLER,
    payload
  };
};
export const toggleMoreMobile = (payload) => {
  return {
    type: commonConstants.TOGGLE_MORE_MOBILE,
    payload
  };
};
export const updateCurrentlyOpenedMenu = (payload) => {
  return {
    type: commonConstants.UPDATE_CURRENT_MODULE,
    payload
  };
};
export const GetComments = (id, type, start, fetchCommentLimit, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`comment/fetch?type_id=${id}&type=${type}&from=${start}&limit=${fetchCommentLimit}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: commonConstants.GET_COMMENTS_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        dispatch({
          type: commonConstants.GET_COMMENTS_FALIURE,
          payload: error
        });
      });
  };
};

export function GetReplyComments(cid, start, fetchCommentLimit, callback) {
  return (dispatch) => {
    axiosInstance
      .get(`comment/fetch_reply?cid=${cid}&from=${start}&limit=${fetchCommentLimit}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: commonConstants.GET_REPLY_COMMENTS_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        dispatch({
          type: commonConstants.GET_REPLY_COMMENTS_FALIURE,
          payload: error
        });
      });
  };
}

export function PostComment(jsonObj, callback) {
  return (dispatch) => {
    axiosInstance
      .post('comment/save', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: commonConstants.POST_COMMENT_SUCCESS
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        dispatch({
          type: commonConstants.POST_COMMENT_FAILURE
        });
      });
  };
}

export function getFcmToken(setFirebaseToken) {
  return (dispatch) => {
    axiosInstance
      .get('fcm/token')
      .then((res) => {
        setFirebaseToken(res?.data?.data?.token);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
      });
  };
}
export function getFcmTokenByUserToken(userToken, cb) {
  return (dispatch) => {
    axiosOpenInstance({
      url: `${url}fcm/token`,
      method: 'GET',
      headers: {
        Authorization: userToken
      }
    })
      .then((res) => {
        cb(res?.data?.data?.token);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
      });
  };
}
export const getUserDetails = (callback, updateSpeciality = false) => {
  return (dispatch) => {
    axiosInstance
      .get('user/detail')
      .then((response) => {
        callback(response.data);
        dispatch({
          type: commonConstants.GET_USER_DETAILS_SUCCESS,
          payload: response?.data?.data
        });

        dispatch(
          shareActions(
            'login',
            0,
            shareConstants.GET_SESSION_SHARE_SUCCESS,
            shareConstants.GET_SESSION_SHARE_FALIURE,
            '',
            '',
            response?.data?.data?.master_user_id,
            '',
            (response) => {
              console.log(response);

              if (response.status_code == 200) {
                //   setLoader(true)
              }
            }
          )
        );

        const selectedSpeciality = getLocalStorage('selectedSpeciality', null);
        if (!selectedSpeciality) {
          if (parseInt(response?.data?.data?.master_user_type_id) != 1) {
            dispatch(
              updateUserViewSpeciality([
                {
                  master_specialities_id: '0',
                  specialities_name: 'All',
                  icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/all_speciality.svg',
                  status: '1'
                }
              ])
            );
            setLocalStorage('selectedSpeciality', {
              master_specialities_id: '0',
              specialities_name: 'All',
              icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/all_speciality.svg',
              status: '1'
            });
          }
        }

        if (updateSpeciality && response?.data?.data?.speciality) {
          dispatch(updateUserViewSpeciality(JSON.parse(response?.data?.data.speciality)));
        }
        if (response?.data?.data) {
          setLocalStorage('user', response?.data?.data);
        }
        if (response?.data?.data?.menu && JSON.parse(response?.data?.data?.menu)?.main == 'dnr') {
          setLocalStorage('main_menu', 'dnr');
        } else {
          setLocalStorage('main_menu', '');
        }
      })
      .catch((error) => {
        callback('error');
        dispatch({
          type: commonConstants.GET_USER_DETAILS_FAILURE,
          payload: error
        });
      });
  };
};

export const syncUserDetailsWithStore = (data) => {
  return (dispatch) => {
    dispatch({
      type: commonConstants.GET_USER_DETAILS_SUCCESS,
      payload: data
    });
  };
};

export const postError = (jsonObj, callback) => {
  return () => {
    axiosOpenInstance({
      url: `${url}openapi/add_trackingError`,
      method: 'POST',
      data: jsonObj
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback('error');
      });
  };
};
export const postMenu = (jsonObj, callback) => {
  return () => {
    axiosInstance
      .post('user/updateMenu', JSON.stringify(jsonObj))
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback('error');
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv19/user/updateMenu

export const fetchRelatedContent = (id, spec, fetch_from, to, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`related/trending?type=all&speciality=${spec}&type_id=${id}&from=${fetch_from}&to=${to}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: commonConstants.RELATED_FETCH_SUCCESS,
            payload: response?.data?.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const fetchPopularSpecialities = (type, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`topspeciality/data?type=${type}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const fetchCTA = (type, type_id, callback) => {
  return async (dispatch) => {
    dispatch({
      type: commonConstants.CTA_FETCH_LOADING
    });
    axiosInstance
      .get(`cta/listing?type=${type}&type_id=${type_id}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: commonConstants.CTA_FETCH_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        dispatch({
          type: commonConstants.CTA_FETCH_FAILED,
          payload: error
        });
      });
  };
};

//action for cta view
export const viewedCta = (cta_id) => {
  return {
    type: commonConstants.CTA_VIEWED,
    payload: cta_id
  };
};
export const postAnalyticsCTA = (data, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('cta/ctareport', { ...data, utm_source: getLocalStorage('utm_source', '') })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};
export const addToCTA = (payload) => {
  return {
    type: commonConstants.ADD_TO_CTA,
    payload
  };
};
export const updateCtaSchedule = (post_data, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('cta/update_schedule', post_data)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};

export const clearCTA = () => {
  return { type: 'CLEAR_CTA' };
};
export const renderHeaderOnce = () => {
  return { type: commonConstants.RENDER_HEADER };
};
export const changeShowSearch = (payload) => {
  return { type: commonConstants.TOGGLE_SEARCH_OPEN, payload };
};
export const sendSpeakerInvitationAction = (data = 0, callback) => {
  // if (!data) {
  //   return false;
  // }
  let user_data = getLocalStorage('user', {});
  // if (user_data) {
  //   let { user_speaker_invitation_status } = user_data;
  // }
  let status_data = new FormData();
  status_data.append('status', data);
  return (dispatch) => {
    axiosInstance
      .post('user/invitation_acceptance', status_data)
      .then((response) => {
        if (response.data.status_code == 200 && user_data) {
          // let { user_speaker_invitation_status } = user_data;
          user_data.user_speaker_invitation_status = data;
          setSessionStorage('speaker_modal_view', true);
          setLocalStorage('user', user_data);
        }
        if (response?.data) {
          callback(response?.data);
          dispatch({
            type: commonConstants.SEND_SPEAKER_INVITATION_SUCCESS,
            data: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        dispatch({
          type: commonConstants.SEND_SPEAKER_INVITATION_FALIURE,
          data: error
        });
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv23/topspeciality/data?type=comp
//https://developer.clirnet.com/knowledge/rnv23/cta/listing?type=session&type_id=221
export const checkUserTheme = (data, callback) => {
  return async (dispatch) => {
    if (data && typeof data == 'object' && data?.user_master_id) {
    }
  };
};
//
export function updateSpeciality(jsonObj, callback) {
  return (dispatch) => {
    axiosInstance
      .post('speciality/update', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
}

export function changeExploreKey(payload) {
  return { type: commonConstants.TOGGLE_EXPLORE_KEY, payload };
}
export function resetStore() {
  return { type: commonConstants.RESET_STORE };
}

export const toggleCommentFocus = (payload) => {
  return {
    type: commonConstants.TOGGLE_COMMENT_FOCUS,
    payload
  };
};

export const toggleLoadingBar = (payload) => {
  return {
    type: commonConstants.TOGGLE_LOADING_BAR,
    payload
  };
};

export const toggleAccessError = (payload) => {
  return {
    type: commonConstants.ACCESS_ERROR,
    payload
  };
};

export const toggleGlobalModal = (payload) => {
  return {
    type: commonConstants.GLOBAL_MODAL,
    payload
  };
};

export const toggleFormFocus = (payload) => {
  return {
    type: commonConstants.FOCUS_FORM,
    payload
  };
};
