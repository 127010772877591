import axios from 'axios';
import dayjs from 'dayjs';
import { getLocalStorage } from '../../common/common';
import {
  ACCESS_TOKEN,
  CLEAR_NOTIFICATION_ARRAY,
  FETCH_NOTIFICATION_COUNT,
  FETCH_NOTIFICATION_LIST,
  UPDATE_LIST
} from '../constants/notification.constant';
import axiosOpenInstance from '../helper/axiosOpenInstance';
const crm_url = process.env.REACT_APP_CRM;
const getUserId = () => {
  return getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : false;
};
const fetchNotisAction = (today, week, more, is_new_fetch) => {
  return {
    type: FETCH_NOTIFICATION_LIST,
    payload: { today, week, more, is_new_fetch }
  };
};
const fetchNotisCountAction = (payload) => {
  return {
    type: FETCH_NOTIFICATION_COUNT,
    payload
  };
};
const accessTokenAction = (payload) => {
  return {
    type: ACCESS_TOKEN,
    payload
  };
};
export const loginToCrm = (callback) => {
  return null;
  const post_data = {
    email: process.env.REACT_APP_CRM_LOGIN_EMAIL,
    password: process.env.REACT_APP_CRM_LOGIN_PASSWORD
  };
  return async (dispatch) => {
    axios
      .post(`${crm_url}login`, post_data)
      .then((response) => {
        console.log('CRM LOGIN response', response);
        if (response.data && response.status === 200) {
          callback(response.data);
          dispatch(accessTokenAction(response?.data?.access_token));
        }
      })
      .catch((error) => {
        callback('error');
      });
  };
};

export const fetchNotifications = (token, pagination, callback, is_new_fetch = false) => {
  const user_id = getUserId();
  return async (dispatch) => {
    if (user_id !== false) {
      axiosOpenInstance({
        method: 'GET',
        url: `${crm_url}notificationlog/getdatabyid/${user_id}?from=${pagination}&to=50`,
        headers: { Authorization: `Bearer ${token}` }
      })
        .then((response) => {
          if (response.data) {
            let notis = response.data?.data;
            callback(notis?.length > 0 ? notis : []);
            if (notis?.length > 0) {
              console.log('NOTIS', notis);
              const todayNotis = notis?.filter((n) => dayjs().isSame(n.datetime, 'day'));
              const weekNotis = notis?.filter(
                (n) => !dayjs().isSame(n.datetime, 'day') && dayjs().isSame(n.datetime, 'week')
              );
              const moreNotis = notis?.filter(
                (n) => !dayjs().isSame(n.datetime, 'day') && !dayjs().isSame(n.datetime, 'week')
              );
              dispatch(fetchNotisAction(todayNotis, weekNotis, moreNotis, is_new_fetch));
            }
          }
        })
        .catch((error) => {
          callback([]);
          dispatch(fetchNotisAction([], [], [], true));
        });
    }
  };
};
//notificationlog/readnotification
export const readNotification = (token, temp_id) => {
  const user_id = getUserId() ? getUserId() : 0;
  const postObj = { temp_id, user_id };
  return async (dispatch) => {
    // console.log('huiiiiiiii', user_id, typeof user_id);
    // alert(user_id, typeof user_id);
    if (user_id) {
      axiosOpenInstance({
        method: 'POST',
        url: `${crm_url}notificationlog/readnotification`,
        headers: { Authorization: `Bearer ${token}` },
        data: postObj
      })
        .then((resp) => {
          console.log(resp);
          axiosOpenInstance({
            method: 'GET',
            url: `${crm_url}notificationlog/getunreadnotification/${user_id}`,
            headers: { Authorization: `Bearer ${token}` }
          })
            .then((response) => {
              if (response.data) {
                let notis_count = response.data?.data;
                console.log('COUNT', notis_count);

                dispatch(fetchNotisCountAction(notis_count ?? 0));
              }
            })
            .catch((error) => {});
        })
        .catch((e) => console.log(e));
    }
  };
};
//http://msgdev.clirnet.com/api/notificationlog/getunreadnotification/879
export const fetchUnreadCount = (token, callback) => {
  const user_id = getUserId() ? getUserId() : 0;
  return async (dispatch) => {
    if (user_id) {
      axiosOpenInstance({
        method: 'GET',
        url: `${crm_url}notificationlog/getunreadnotification/${user_id}`,
        headers: { Authorization: `Bearer ${token}` }
      })
        .then((response) => {
          if (response.data) {
            let notis_count = response.data?.data;
            console.log('COUNT', notis_count);
            callback(notis_count > 0 ? notis_count : 0);
            dispatch(fetchNotisCountAction(notis_count ?? 0));
          }
        })
        .catch((error) => {
          callback(null);
        });
    }
  };
};
export const clearNotifications = () => {
  return {
    type: CLEAR_NOTIFICATION_ARRAY
  };
};
export const updateNotificationList = (payload) => {
  return {
    type: UPDATE_LIST,
    payload
  };
};
