import { commonConstants } from '../constants/commonConstants';
import { grandRoundConstants } from '../constants/grandround.constant';

let initialState = {
  grandRoundList: [],
  grandRoundArchived: [],
  error: ''
};

export const grandRoundReducer = (state = initialState, action) => {
  switch (action.type) {
    case grandRoundConstants.GET_GRANDROUND_LIST_SUCCESS:
      return {
        ...state,
        grandRoundList: [...state.grandRoundList, ...action.payload.data]
      };

    case grandRoundConstants.GET_GRANDROUND_LIST_FAILURE:
    case grandRoundConstants.GET_GRANDROUND_DETAILS_FAILURE:
    case grandRoundConstants.GET_GRANDROUND_SESSION_FAILURE:
    case grandRoundConstants.GET_GRANDROUND_MEDWIKI_FAILURE:
    case grandRoundConstants.GET_GRANDROUND_ARCHIVE_FAILURE:
    case grandRoundConstants.GET_GRANDROUND_SURVEY_FAILURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };
    case grandRoundConstants.GET_GRANDROUND_DETAILS_SUCCESS:
      return {
        ...state,
        grandRoundDetails: action.payload.data[0]
      };

    // case grandRoundConstants.GET_GRANDROUND_DETAILS_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };
    case grandRoundConstants.GET_GRANDROUND_SESSION_SUCCESS:
      return {
        ...state,
        grandRoundSessions: action.payload.data
      };

    // case grandRoundConstants.GET_GRANDROUND_SESSION_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };
    case grandRoundConstants.GET_GRANDROUND_MEDWIKI_SUCCESS:
      return {
        ...state,
        grandRoundMedwiki: action.payload.data
      };

    // case grandRoundConstants.GET_GRANDROUND_MEDWIKI_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };
    case grandRoundConstants.GET_GRANDROUND_ARCHIVE_SUCCESS:
      return {
        ...state,
        grandRoundArchived: [...state.grandRoundArchived, ...action.payload.data]
      };

    // case grandRoundConstants.GET_GRANDROUND_ARCHIVE_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };
    case grandRoundConstants.GET_GRANDROUND_SURVEY_SUCCESS:
      return {
        ...state,
        grandRoundSurvey: action.payload.data
      };

    // case grandRoundConstants.GET_GRANDROUND_SURVEY_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.payload?.message || ""
    //   };

    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
