import React, { memo } from 'react';
import '../css/medwikiDetailsV2.scss';
import { ClinicalVideoLogo, RiPlayCircleFill } from '../../../components/icons';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useRedirect from '../../../common/hooks/useRedirect';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';
import { EVENT } from '../../../TrackerFolder/constants/ActionType';

import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { reactHtmlParser } from '../../../common/common';
import { routeNames } from '../../../router/constants';
import { CARD_CLICK, CARD_VIEW } from '../../../components/tracking/constants';
import { onCardClick, trackEvent } from '../../../components/tracking/UserActions';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import dayjs from 'dayjs';
const MedwikiRelatedPostCard = ({
  gtmTagVariable = '',
  DetailPageName,
  typeId,
  sponsor_id,
  pageQuestion,
  class_name,
  pageName,
  item,
  default_image,
  logo,
  data,
  eventDate = null
}) => {
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();

  const type = (item && item?.trending_type) || (item && item?.type);

  return (
    <div className={`${class_name}d-flex justify-content-between align-items-center`}>
      <div className="medwikiRelatedPostPic position-relative overflow-hidden rounded-3 d-flex justify-content-center align-items-center bg-white">
        <LazyImage
          src={
            item && (item?.image || item?.cover_image)
              ? item?.cover_image || item?.image
              : default_image
          }
          alt="Related post"
          className="object-fit-cover w-100 h-100 float-start cursorPointer"
          fallback_image={default_image}
          onClick={() => {
            trackActivityEvent(CARD_CLICK, onCardClick(typeId, 'medwiki'));
            redirectTo(DetailPageName, typeId, pageQuestion);
          }}
        />

        {(type === 'video' || type === 'video_archive') && (
          <RiPlayCircleFill customClass="text-white fs-30 position-absolute top-50 start-50 translate-middle" />
        )}

        <div
          className={`overlay cursorPointer ${gtmTagVariable}_medwikiRelatedPost`}
          onClick={() => {
            trackActivityEvent(CARD_CLICK, onCardClick(typeId, 'medwiki'));
            redirectTo(DetailPageName, typeId, pageQuestion);
          }}
        ></div>
      </div>
      <TrackerWrapper
        data={data}
        type={CARD_VIEW}
        className="medwikiRelatedPostDetails text-start ps-4 d-flex flex-column"
      >
        <div className="d-block ">
          <h5
            className={
              eventDate
                ? 'text-secondary fs-5 fw-medium mb-1 cursorPointer bg-secondary bg-opacity-10 rounded py-1 px-3 d-inline'
                : `fs-5 fw-normal mb-1 cursorPointer ${gtmTagVariable}_medwikiRelatedPost_specialities`
            }
            onClick={() => {
              trackActivityEvent(CARD_CLICK, onCardClick(typeId, 'medwiki'));
              redirectTo(DetailPageName, typeId, pageQuestion);
            }}
          >
            {eventDate ? (
              dayjs(eventDate).format('ddd, DD MMM |  h:mm a')
            ) : (
              <p className="text-capitalize">{pageName}</p>
            )}
          </h5>
        </div>
        <h4
          className={`fs-4 fw-medium text-black line-clamp-2 cursorPointer ${gtmTagVariable}_medwikiRelatedPost`}
          onClick={() => {
            trackActivityEvent(CARD_CLICK, onCardClick(typeId, 'medwiki'));
            redirectTo(DetailPageName, typeId, pageQuestion);
          }}
        >
          {reactHtmlParser(pageQuestion)}
        </h4>
      </TrackerWrapper>
    </div>
  );
};

export default memo(MedwikiRelatedPostCard);
