export const filterJsonByPosition = (data = [], matching_value = '') => {
  return data.filter((x) => {
    return (
      x.position === matching_value &&
      (x.target_type === 'Filler' ||
        (x.max_view_per_session >= 0 &&
          x.bal_view_per_user_per_camp > 0 &&
          x.total_view_done_per_day_campaign < x.view_per_day &&
          x.view_per_day > 0))
    );
  });
};
