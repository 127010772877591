import React, { memo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDefaultRouter } from './defaultRouter';

function IndexRouter() {
  const router = useDefaultRouter();

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default memo(IndexRouter);
