export const dashboardConstants = {
  DOCTOR_VOICE_SUCCESS: 'DOCTOR_VOICE_SUCCESS',
  DOCTOR_VOICE_FAILURE: 'DOCTOR_VOICE_FAILURE',

  SESSION_SLIDER_SUCCESS: 'SESSION_SLIDER_SUCCESS',
  SESSION_SLIDER_FAILURE: 'SESSION_SLIDER_FAILURE',

  TRENDING_COMP_SUCCESS: 'TRENDING_COMP_SUCCESS',
  TRENDING_COMP_FAILURE: 'TRENDING_COMP_FAILURE',

  TRENDING_GR_SUCCESS: 'TRENDING_GR_SUCCESS',
  TRENDING_GR_FAILURE: 'TRENDING_GR_FAILURE',

  TRENDING_SURVEY_SUCCESS: 'TRENDING_SURVEY_SUCCESS',
  TRENDING_SURVEY_FAILURE: 'TRENDING_SURVEY_FAILURE',

  TRENDING_VIDEO_SUCCESS: 'TRENDING_VIDEO_SUCCESS',
  TRENDING_VIDEO_FAILURE: 'TRENDING_VIDEO_FAILURE',

  RECENT_SUCCESS: 'RECENT_SUCCESS',
  RECENT_FAILURE: 'RECENT_FAILURE',

  CHANGE_USER_SPECIALITY_VIEW: 'CHANGE_USER_SPECIALITY_VIEW'
};
