import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../router/constants';
import importStatements from './importStatements';
import { useDispatch } from 'react-redux';
import {
  focusLoginBar,
  getSessionDetail,
  toggleAccessError,
  toggleFormFocus,
  toggleLoadingBar
} from '../../Store/actions';
import { getLocalStorage, isEligibleForSpeciality, removeHtmlTags } from '../../common/common';

const useRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const convertToSlug = (Text) => {
    return Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/[^\w\s]/gi, '-');
  };

  const findImportStatement = (path) => {
    if (!path) return null;
    return importStatements.find(
      (statement) => statement.path === path || statement.path === `/${path}`
    );
  };

  const onError = (e) => {
    console.log('ON_ERROR', e);
    // navigate('/', { replace: true });
  };
  const redirect = (moduleName, id = '', name = '', extra = '', state = {}, replace) => {
    const sessionRoutes = [
      routeNames.sessions.detail,
      routeNames.sessions.waitingRoom,
      routeNames.sessions.live
    ];
    const urlParams = `${moduleName}/${id}/${convertToSlug(removeHtmlTags(name))}${
      extra ? `?${extra}` : ''
    }`;
    const url = `${moduleName}${extra ? `?${extra}` : ''}`;
    const doNavigate = () => {
      const stateToPass = {
        replace,
        state: { ...state, ...location }
      };
      navigate(
        moduleName?.startsWith('/') ? (id ? urlParams : url) : id ? `/${urlParams}` : `/${url}`,
        stateToPass
      );
      //dispatch(toggleLoadingBar(false));
    };

    if (sessionRoutes.includes(moduleName) || sessionRoutes.includes(`/${moduleName}`)) {
      dispatch(getSessionDetail(id, () => doNavigate()));
    } else {
      doNavigate();
    }
  };
  const redirectTo = (moduleName, id = '', name = '', extra = '', state = {}, replace = false) => {
    const user = getLocalStorage('user', false);
    const dontHaveAccess = user && user?.user_master_status == 4 ? true : false;

    if (!user) {
      dispatch(toggleFormFocus(true));
      return null;
    } else if (dontHaveAccess) {
      dispatch(toggleAccessError(true));
      return null;
    } else {
      dispatch(toggleLoadingBar(true));
      try {
        let importStatement = findImportStatement(moduleName);
        if (importStatement) {
          const isSpecialityEligible = isEligibleForSpeciality(getLocalStorage('user', '{}'));
          let statement = isSpecialityEligible
            ? importStatement.statement()
            : importStatement?.oldStatement
            ? importStatement?.oldStatement()
            : importStatement?.statement();
          statement
            .then((module) => {
              redirect(moduleName, id, name, extra, state, replace);
            })
            .catch((error) => {
              redirect(moduleName, id, name, extra, state, replace);
            });
        } else {
          redirect(moduleName, id, name, extra, state, replace);
        }
      } catch (error) {
        redirect(moduleName, id, name, extra, state, replace);
      }
    }
  };
  return { redirectTo };
};

export default useRedirect;
