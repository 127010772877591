import React, { Suspense, lazy } from 'react';
// import LoadableComponent from 'react-loadable';

// import loadable from '@loadable/component';
import LoadingFallback from './fallback/LoadingFallback';
// const lib = 'react-loadable';
const Loadable = ({ lib = 'lazy', loader, Loading }) => {
  const LazyComponent =
    lib === 'lazy' ? lazy(loader) : lib === '@loadable/component' ? lazy(loader) : () => null;
  return (props) =>
    lib == 'lazy' ? (
      <Suspense fallback={Loading ? <Loading /> : <LoadingFallback />}>
        <LazyComponent {...props} />
      </Suspense>
    ) : lib == '@loadable/component' ? (
      <LazyComponent fallback={Loading ? <Loading /> : <LoadingFallback />} {...props} />
    ) : (
      <LazyComponent {...props} />
    );
};

export default Loadable;
