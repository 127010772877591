import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { openInNewTab } from '../banner/utils/openInNewTab';
import { UploadIcon } from '../icons';

function RedirectModal({ show, url, onHide }) {
  //   // useEffect(() => {
  //   //   if (show) {
  //   //     setTimeout(() => {
  //   //       openInNewTab(url);
  //   //     }, 3000);
  //   //   }
  //   // }, [show]);
  useEffect(() => {
    const button1 = document.getElementById('redirect-button');
    setTimeout(() => {
      if (show) {
        button1?.click();
      }
    }, 3000);
  }, [show]);

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Body className="d-flex align-items-center flex-column gap-4 text-center">
        <UploadIcon />
        <h1 className="fs-4 text-black fw-semibold">
          You’re being redirected to Alkem’s Corporate Page. Please wait while we are redirecting
          you.
        </h1>
        <Button
          id="redirect-button"
          variant="primary"
          onClick={() => {
            onHide();
            window.open(url, '_blank');
          }}
        >
          Okay
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default RedirectModal;
