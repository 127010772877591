import React, { memo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ConsentModal = ({
  onAgreeConsent,
  is_consent_modal,
  title = '',
  consent_text = '',
  onHide
}) => {
  const [isConsentChecked, setIsConsentChecked] = useState(true);
  const onAgree = () => {
    //dispatch action to set consent status to true
    if (!isConsentChecked) {
      //toast message to check the consent checkbox
      toast.error('Please accept the above terms and conditions');
      return;
    }
    onAgreeConsent();
  };
  if (!is_consent_modal || !consent_text) return null;
  return (
    <Modal
      className="centerModal"
      // data-bs-theme="dark"
      size="md"
      centered
      show={is_consent_modal}
      onHide={onHide}
    >
      <Modal.Body>
        <form>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between gap-3 align-items-start mb-2">
              {title && <h1 className="fs-4 mt-2 text-black">{title}</h1>}
              <div
                className="icon-size-32 rounded-circle d-inline-flex transition rippleEffect bg-transparent clr_v3_icon-btn-1 text-black flex-shrink-0 fs-2 cursorPointer align-items-center justify-content-center ms-auto"
                onClick={onHide}
              >
                <i className="flaticon-close fs-4"></i>
              </div>
            </div>
            {consent_text && <p className="fs-5 mb-20">{consent_text}</p>}
            <Form.Check
              type="checkbox"
              className="d-flex align-items-start fs-5"
              label={'I accept the terms & conditions'}
              checked={isConsentChecked}
              value={isConsentChecked}
              onChange={() => setIsConsentChecked((prev) => !prev)}
            />
            <Button
              variant="primary"
              className="ms-sm-auto mt-sm-2 mt-4 px-sm-20 px-4"
              onClick={onAgree}
            >
              Okay
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ConsentModal);
