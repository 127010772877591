import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export default function ShareCard({ data, customClass, shareClick, parentClass = '' }) {
  const [shareData, setShareData] = useState({});
  const [extraCustomClass, setextraCustomClass] = useState('');

  useEffect(() => {
    let tempData = {};
    tempData = {
      title: data.title,
      // text: data.text,
      url: data.url
    };
    setShareData(tempData);

    if (customClass) {
      setextraCustomClass(customClass);
    }
  }, []);

  const share_url = async () => {
    if (navigator.canShare) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.info('Copied to clipboard');
      copy_to_Clipboard(shareData.url);
    }
  };
  const copy_to_Clipboard = (str) => {
    let textarea;
    let result;
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = str;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, str); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }

    return true;
  };

  if (
    (shareData && 'undefined' === typeof shareData.url) ||
    !shareData.url ||
    null === shareData.url ||
    '' === shareData.url ||
    'null' === shareData.url ||
    'undefined' === typeof shareData.url ||
    shareData.url == ''
  ) {
    return null;
  }
  return (
    <>
      <a
        onClick={() => {
          share_url();
          if (shareClick && typeof shareClick == 'function') {
            shareClick();
          }
        }}
        href="javascript:void(0)"
        className={`cardV1DotsMenu text-black icon-size-28 btn p-0 rounded-circle flex-shrink-0 d-inline-flex align-items-center justify-content-center ${parentClass}`}
      >
        <i
          className={`flaticon-share-arrow cursorPointer ${
            customClass ? customClass : 'fs-3 text-black'
          }`}
        ></i>
      </a>
    </>
  );
}
