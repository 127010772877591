import React, { memo, useEffect, useState } from 'react';

import dayjs from 'dayjs';

function CountDown(props) {
  const calculateTimeLeft = () => {
    const datetime = dayjs(props?.date).diff(dayjs(new Date()));

    let timeLeft = {};

    if (datetime > 0) {
      timeLeft = {
        days: Math.floor(datetime / (1000 * 60 * 60 * 24)),
        hours: Math.floor((datetime / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((datetime / 1000 / 60) % 60),
        seconds: Math.floor((datetime / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
  });

  const getFormatedData = (val = 0) => {
    // if (val > 0) {
    return val.toString().padStart(2, 0);
    // } else {
    //   return val.toString().padStart(2, 0);
    // }
  };

  return (
    <>
      <div className="text-center sessionLoginV2Timer border border-light py-2 px-0 d-inline-block rounded position-relative z-3">
        <div className="row m-0">
          {timeLeft?.days > 0 ? (
            <>
              <div className="col py-0 px-3">
                <h4 className="fw-normal fs-5 text-white">
                  <span className="fw-semibold fs-16 d-block position-relative pb-1 mb-1">
                    {getFormatedData(timeLeft.days)}
                  </span>
                  Days
                </h4>
              </div>
              <div className="col py-0 px-3">
                <h4 className="fw-normal fs-5 text-white">
                  <span className="fw-semibold fs-16 d-block position-relative pb-1 mb-1">
                    {getFormatedData(timeLeft.hours)}
                  </span>
                  Hours
                </h4>
              </div>
            </>
          ) : timeLeft?.hours > 0 ? (
            <>
              <div className="col py-0 px-3">
                <h4 className="fw-normal fs-5 text-white">
                  <span className="fw-semibold fs-16 d-block position-relative pb-1 mb-1">
                    {getFormatedData(timeLeft.hours)}
                  </span>
                  Hours
                </h4>
              </div>
              <div className="col py-0 px-3">
                <h4 className="fw-normal fs-5 text-white">
                  <span className="fw-semibold fs-16 d-block position-relative pb-1 mb-1">
                    {getFormatedData(timeLeft.minutes)}
                  </span>
                  Minutes
                </h4>
              </div>
            </>
          ) : (
            <>
              <div className="col py-0 px-3">
                <h4 className="fw-normal fs-5 text-white">
                  <span className="fw-semibold fs-16 d-block position-relative pb-1 mb-1">
                    {getFormatedData(timeLeft.minutes)}
                  </span>
                  Minutes
                </h4>
              </div>
              <div className="col py-0 px-3">
                <h4 className="fw-normal fs-5 text-white">
                  <span className="fw-semibold fs-16 d-block position-relative pb-1 mb-1">
                    {getFormatedData(timeLeft.seconds)}
                  </span>
                  Seconds
                </h4>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default memo(CountDown);

// 2021-12-22 13:42:30
