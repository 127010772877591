import React, { useEffect, useState } from 'react';
import { getLocalStorage } from '../common/common';
import { useDispatch } from 'react-redux';
import { loginFailure } from '../Store/actions/login.action';
const PublicRoute = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!getLocalStorage('isLoggedIn', false)) {
      dispatch(loginFailure());
    }
  }, []);

  return children;
};
export default PublicRoute;
