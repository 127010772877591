import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../Store/helper/axiosInstance';
import { generateUtmExt } from '../../common/common';
import { DASHBOARD_ALL_DATA } from '../QueryKeys/dashboard.key';
import {
  MEDWIKI_DETAILS,
  MEDWIKI_FEATURED,
  MEDWIKI_NEW_TOP_POST_INFINITYQUERY,
  MEDWIKI_POPULAR_POST_INFINITYQUERY,
  MEDWIKI_SPECIALITIES,
  MEDWIKI_TOP_COMMENTS,
  MEDWIKI_TOP_COMMENTS_POST_INFINITYQUERY,
  MEDWIKI_TOP_POST_INFINITYQUERY,
  MEDWIKI_TOP_RATED,
  MEDWIKI_TOP_RATED_POST_INFINITYQUERY
} from '../QueryKeys/medwiki.key';
import { GET_VAULT_LIST } from '../QueryKeys/vault.key';
import useFeedInitialData from '../utils/useFeedInitialData';

const staleTime = 300000;
const cacheTime = 600000;
export const useMedwikiFeatured = (isFeatured = false, onSuccess, onError) => {
  return useQuery(
    [MEDWIKI_FEATURED, isFeatured],
    () => {
      if (isFeatured === 'featured=true') {
        return axiosInstance.get(`knwlg/feed?from=0&to=5&featured=true`);
      } else {
        return axiosInstance.get(
          `knwlg/feed?from=0&to=5${isFeatured ? '&type=featured' : '&type=popular'}`
        );
      }
    },

    {
      onSuccess,
      onError,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};

export const useMedwikiListing = (isFeatured = false, type, subtype) => {
  return useQuery(
    [MEDWIKI_FEATURED, isFeatured, type, subtype],
    () =>
      axiosInstance.get(
        `knwlg/feed?from=0&to=5${
          isFeatured ? '&type=featured' : '&type=' + type + '&sub_type=' + subtype
        }`
      ),
    {
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};

export const useMedwikiPopularPosts = () => {
  return useInfiniteQuery(
    [MEDWIKI_POPULAR_POST_INFINITYQUERY],
    ({ pageParam = 0 }) => axiosInstance.get(`knwlg/feed?from=${pageParam}&to=5&type=featured`),
    {
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};
const fetchMedwiki = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`knwlg/feedDetail?type_id=${id}&type=comp${generateUtmExt()}`);
};

export const useMedwikiDetails = (id, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useQuery([MEDWIKI_DETAILS, id], fetchMedwiki, {
    initialData: () => {
      const medwikiFeatured = queryClient
        .getQueryData(MEDWIKI_FEATURED)
        ?.data?.data?.find((_m) => _m.type_id == id);
      const medwikiVault = queryClient
        .getQueryData(GET_VAULT_LIST)
        ?.data?.data?.find((_m) => _m.type_id == id);
      const medwikiDashboard = queryClient
        .getQueryData(DASHBOARD_ALL_DATA)
        ?.data?.data?.find((_m) => _m.type_id == id);
      const [medwikiChannel, medwikiUiV3Dashboard] = useFeedInitialData('comp', id);

      const medwiki =
        medwikiFeatured ||
        medwikiVault ||
        medwikiDashboard ||
        medwikiChannel ||
        medwikiUiV3Dashboard;
      if (medwiki) {
        return { data: { data: { ...medwiki, answer_htm: medwiki?.answer } } };
      } else {
        return undefined;
      }
    },
    select: (response) => response.data.data,
    onSuccess,
    onError,
    enabled: !!id,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};

export const useMedwikiSpecialities = (onSuccess, onError) => {
  return useQuery(MEDWIKI_SPECIALITIES, () => axiosInstance.get('topspeciality/data?type=comp'), {
    onSuccess,
    onError,
    select: (response) => response.data.data,
    staleTime: staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false
  });
};

export const useMedwikiTopPosts = (id, enabled, speciality_ids) => {
  return useInfiniteQuery(
    [MEDWIKI_TOP_POST_INFINITYQUERY, id, speciality_ids],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `related/trending?type=all&speciality=${speciality_ids}&type_id=${id}&from=${pageParam}&to=5`
      ),
    {
      enabled: enabled,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

//medwiki top rated
export const useMedwikiTopRated = (type, onSuccess, onError) => {
  return useQuery(
    MEDWIKI_TOP_RATED,
    () => axiosInstance.get(`knwlg/topratedmedwiki?to=10&from=0&type=${type}`),
    {
      onSuccess,
      onError,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};

export const useMedwikiTopRatedPosts = () => {
  return useInfiniteQuery(
    [MEDWIKI_TOP_RATED_POST_INFINITYQUERY],
    ({ pageParam = 0 }) => axiosInstance.get(`knwlg/topratedmedwiki?to=10&from=${pageParam}`),
    {
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

//medwiki top comment
export const useMedwikiTopcomments = (onSuccess, onError) => {
  return useQuery(
    MEDWIKI_TOP_COMMENTS,
    () => axiosInstance.get('knwlg/topcommentedmedwiki?to=5&from=0'),
    {
      onSuccess,
      onError,
      select: (response) => response.data.data,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};

export const useMedwikiTopCommentedPosts = () => {
  return useInfiniteQuery(
    [MEDWIKI_TOP_COMMENTS_POST_INFINITYQUERY],
    ({ pageParam = 0 }) => axiosInstance.get(`knwlg/topcommentedmedwiki?to=5&from=${pageParam}`),
    {
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

//medwiki rating (likes)
const addUserGivenRatting = (data) => {
  return axiosInstance.post('knwlg/rating', data);
};
// const useRatting = (onSuccess, onError) => {
//   return useQuery('MEDWIKI_DETAILS', addUserGivenRatting, {
//     onSuccess,
//     onError
//   });
// }
export const useAddUserGivenRatting = (id) => {
  const queryClient = useQueryClient();
  return useMutation(addUserGivenRatting, {
    onSuccess: () => {
      queryClient.invalidateQueries([MEDWIKI_DETAILS, id]);
    }
  });
};

//medwiki channel
// const addChannelFollowHandler = (data) => {
//   return axiosInstance.post('channel/follow_switching', JSON.stringify(data));
// };
// export const useAddChannelFollowHandler = () => {
//   return useMutation(addChannelFollowHandler);
// };

export const useMedwikiNewTopPosts = (medwikiType) => {
  return useInfiniteQuery(
    [MEDWIKI_NEW_TOP_POST_INFINITYQUERY, medwikiType],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        medwikiType === 'topCommented'
          ? `knwlg/topcommentedmedwiki?to=10&from=${pageParam}`
          : medwikiType === 'topRated'
          ? `knwlg/topratedmedwiki?to=10&from=${pageParam}`
          : medwikiType === 'popular'
          ? `knwlg/feed?from=${pageParam}&to=10`
          : `knwlg/feed?from=${pageParam}&to=10`
      ),
    {
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};
