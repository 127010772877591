export const channelConstants = {
  GET_USER_CHANNEL_SUCCESS: 'GET_USER_CHANNEL_SUCCESS',
  GET_USER_CHANNEL_FALIURE: 'GET_USER_CHANNEL_FALIURE',

  GET_USER_CHANNEL_SUGGESTION_SUCCESS: 'GET_USER_CHANNEL_SUGGESTION_SUCCESS',
  GET_USER_CHANNEL_SUGGESTION_FALIURE: 'GET_USER_CHANNEL_SUGGESTION_FALIURE',

  GET_CHANNEL_DETAILS_SUCCESS: 'GET_CHANNEL_DETAILS_SUCCESS',
  GET_CHANNEL_DETAILS_FALIURE: 'GET_CHANNEL_DETAILS_FALIURE',

  GET_CHANNEL_ALL_DATA_SUCCESS: 'GET_CHANNEL_ALL_DATA_SUCCESS',
  GET_CHANNEL_ALL_DATA_FALIURE: 'GET_CHANNEL_ALL_DATA_FALIURE',

  FOLLOW_SWITCHING_SUCCESS: 'FOLLOW_SWITCHING_SUCCESS',
  FOLLOW_SWITCHING_FALIURE: 'FOLLOW_SWITCHING_FALIURE',

  GET_POPULAR_POST_SUCCESS: 'GET_POPULAR_POST_SUCCESS',
  GET_POPULAR_POST_FALIURE: 'GET_POPULAR_POST_FALIURE',

  FILTERED_ARRAY: 'FILTERED_ARRAY',

  CLEAR_SUGGESTION_ARRAY: 'CLEAR_SUGGESTION_ARRAY',
  CLEAR_POPULAR_POST_ARRAY: 'CLEAR_POPULAR_POST_ARRAY'
};
