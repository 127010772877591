import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { EXCLUDE_CAMPAIGN } from '../apis/banner.apis';
import { isMobile } from 'react-device-detect';

const staleTime = 300000;
const cacheTime = 600000;

const fetchBannerSettings = ({ queryKey }) => {
  return axiosInstance.get(EXCLUDE_CAMPAIGN);
};

export const useExcludeCampaign = (id, content_type, onSuccess) => {
  const queryClient = useQueryClient();
  return useQuery([EXCLUDE_CAMPAIGN], fetchBannerSettings, {
    // select: (response) => response.data.data,
    select: (response) => {
      const exclusionList = response.data.data;
      const filteredExclusionList = exclusionList.filter((item) => item.type === content_type);
      // if (exclusionList.length > 0) {
      //   onSuccess();
      // }
      if (filteredExclusionList.length > 0) {
        return filteredExclusionList[0];
      }
    },
    cacheTime: cacheTime,
    staleTime: staleTime,
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess
  });
};
