import { bannerConstants } from '../constants/banner.constant';

import axiosInstance from '../helper/axiosInstance.js';
import axiosOpenInstance from '../helper/axiosOpenInstance';
import { filterJsonByPosition } from '../../components/banner/utils/filterJsonByPosition';
// import { bannerTestData } from '../../components/banner/bannerTestData';
// export const bannerActions = {
//   getBannerDataById,
// };

export const getExclutionList = () => {
  return async (dispatch) => {
    axiosInstance
      .get('campaign/excludeCampaign')
      .then((response) => {
        dispatch({
          type: bannerConstants.GET_EXCLUTION_LIST_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: bannerConstants.GET_EXCLUTION_LIST_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const getBannerSetting = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('campaign/setting')
      .then((response) => {
        callback(response.data);
        dispatch({
          type: bannerConstants.GET_BANNER_SETTING_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        callback(error);
        dispatch({
          type: bannerConstants.GET_BANNER_SETTING_FALIURE,
          payload: error
        });
      });
  };
};

export const getPlatformBanner = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('campaign/GlobalCampaign')
      .then((response) => {
        callback(response.data);
        // callback(bannerTestData);
        dispatch({
          type: bannerConstants.GET_BANNER_DATA_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        callback(error);
        dispatch({
          type: bannerConstants.GET_BANNER_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const getBannerDataById = (type = 'not_found', content_id = 0, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`campaign/contentCampaign?content_type=${type}&content_type_id=${content_id}`)
      .then((response) => {
        callback(response);
        dispatch({
          type: bannerConstants.GET_CONTENT_BANNER_DATA_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        callback('error');
        dispatch({
          type: bannerConstants.GET_CONTENT_BANNER_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const addBannerReport = (arr = [], callback) => {
  console.log('adding banner report', arr);
  if (arr?.length > 0) {
    return async (dispatch) => {
      axiosOpenInstance
        .post(process.env.REACT_APP_BANNER_REPORTING_ENDPOINT, arr)
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          callback(error);
        });
    };
  } else {
    return false;
  }
};

export const addBannerClickReport = (arg = '', callback) => {
  // alert("clicked data " + JSON.stringify(arg));
  if (arg) {
    return async (dispatch) => {
      axiosInstance
        .post('campaign/bannerclick', JSON.stringify(arg))
        .then((response) => {
          callback(response.data);

          dispatch({
            type: bannerConstants.ADD_BANNER_CLICK_REPORT_SUCCESS,
            payload: response.data
          });
        })
        .catch((error) => {
          callback(error);
          dispatch({
            type: bannerConstants.ADD_BANNER_CLICK_REPORT_FALIURE,
            payload: error
          });
        });
    };
  }
};
//campaign/report_consent_click

export const addBannerConsentClickReport = (campaign_id = '', device_details, callback) => {
  // alert("clicked data " + JSON.stringify(arg));
  if (campaign_id) {
    return async (dispatch) => {
      axiosInstance
        .post('campaign/report_consent_click', { campaign_id, data: device_details })
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          callback(error);
        });
    };
  }
};
export const syncLocalDataWithReduxStore = (local_data = []) => {
  return (dispatch) => {
    if (local_data && typeof local_data == 'object') {
      dispatch({
        type: bannerConstants.SYNC_LOCAL_DATA_WITH_STORE_SUCCESS,
        payload: local_data
      });
    } else {
      dispatch({
        type: bannerConstants.SYNC_LOCAL_DATA_WITH_STORE_FALIURE,
        payload: []
      });
    }
  };
};

export const syncExclutionListWithReduxStore = (local_data = []) => {
  console.table('syncing...');
  return (dispatch) => {
    if (local_data && local_data?.length > 0) {
      dispatch({
        type: bannerConstants.SYNC_EXCLUTION_LIST_WITH_STORE_SUCCESS,
        payload: local_data
      });
    } else {
      dispatch({
        type: bannerConstants.SYNC_EXCLUTION_LIST_WITH_STORE_FALIURE,
        payload: []
      });
    }
  };
};

export const syncBannerSettingsWithReduxStore = (local_data = []) => {
  console.table('syncing...');
  return (dispatch) => {
    if (local_data && local_data?.length > 0) {
      dispatch({
        type: bannerConstants.SYNC_BANNER_SETTINGS_WITH_STORE_SUCCESS,
        payload: local_data
      });
    } else {
      dispatch({
        type: bannerConstants.SYNC_BANNER_SETTINGS_WITH_STORE_FALIURE,
        payload: []
      });
    }
  };
};

export const setBannerByPosition = (main_banner_data = []) => {
  let top_banner_data = [];
  let bottom_banner_data = [];
  let side_top_banner_data = [];
  let side_bottom_banner_data = [];

  if (main_banner_data?.length > 0) {
    top_banner_data = filterJsonByPosition(main_banner_data, 'top');
    bottom_banner_data = filterJsonByPosition(main_banner_data, 'bottom');
    side_top_banner_data = filterJsonByPosition(main_banner_data, 'side-top');
    side_bottom_banner_data = filterJsonByPosition(main_banner_data, 'side-bottom');
  }
  return (dispatch) => {
    dispatch({
      type: bannerConstants.FILTER_TOP_BANNER_SUCCESS,
      payload: top_banner_data
    });
    dispatch({
      type: bannerConstants.FILTER_SIDE_TOP_BANNER_SUCCESS,
      payload: side_top_banner_data
    });
    dispatch({
      type: bannerConstants.FILTER_SIDE_BOTTOM_BANNER_SUCCESS,
      payload: side_bottom_banner_data
    });
    dispatch({
      type: bannerConstants.FILTER_BOTTOM_BANNER_SUCCESS,
      payload: bottom_banner_data
    });
  };
};

export const updateReportData = (data = {}) => {
  return (dispatch) => {
    if (data && typeof data == 'object') {
      dispatch({
        type: bannerConstants.UPDATE_BANNER_REPORT,
        payload: data
      });
    }
  };
};

export const updateBannerShowStatus = (data = {}) => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.RESET_SPONSOR_ADS_SHOW_STATUS,
      payload: data
    });
  };
};

// ;
export const content_data_get_done = () => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.CONTENT_BANNER_DATA_FETCHED,
      payload: true
    });
  };
};

export const content_data_get_pending = () => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.CLEAR_CONTENT_BANNER_DATA_FETCHED,
      payload: false
    });
  };
};

export const clearBannerReport = () => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.CLEAR_BANNER_REPORT_DATA,
      payload: []
    });
  };
};

export const clearContentBannerData = () => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.CLEAR_CONTENT_BANNER_DATA,
      payload: ''
    });
  };
};

export const clearGlobalBannerData = () => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.CLEAR_GLOBAL_BANNER_DATA,
      payload: ''
    });
  };
};

export const updateVideoBannerData = (data = {}) => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.UPDATE_BANNER_DATA,
      payload: data
    });
  };
};

export const updateBannerCardIndex = (_ind = 0) => {
  return {
    type: bannerConstants.UPDATE_BANNER_CARD_INDEX,
    payload: _ind ?? 0
  };
};

export const toogleInterstitialAds = () => {
  return {
    type: bannerConstants.TOOGLE_INTERSTITAL_ADS
  };
};

export const showInterstitialAds = () => {
  return {
    type: bannerConstants.SHOW_INTERSTITAL_ADS
  };
};

export const hideInterstitialAds = () => {
  return {
    type: bannerConstants.HIDE_INTERSTITAL_ADS
  };
};

export const enableInterstitialAds = () => {
  return {
    type: bannerConstants.ENABLE_INTERSTITIAL_ADS
  };
};

export const disableInterstitialAds = () => {
  return {
    type: bannerConstants.DISABLE_INTERSTITIAL_ADS
  };
};

export const updateCurrentQueue = (data = '') => {
  if (data) {
    return (dispatch) => {
      dispatch({
        type: bannerConstants.UPDATE_CURRENT_QUEUE,
        payload: data
      });
    };
  }
};

export const updatePlayedCampaignQueue = (data = '') => {
  if (data) {
    return (dispatch) => {
      dispatch({
        type: bannerConstants.CAMPAIGN_PLAYED,
        payload: data
      });
    };
  }
};

export const updateCampaignSequence = (data = '') => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.UPDATE_BANNER_SEQUENCE,
      payload: data
    });
  };
};

export const removeSponsorAds = (position, data = true) => {
  return (dispatch) => {
    dispatch({
      type: bannerConstants.REMOVE_SPONSOR_ADS,
      payload: data,
      position
    });
  };
};

export const cleanCurrentQueue = () => {
  return {
    type: bannerConstants.CLEAN_CURRENT_QUEUE
  };
};
//create a action to toggle consent modal visibility
export const toggleConsentModal = (payload) => {
  return {
    type: bannerConstants.TOGGLE_CONSENT_MODAL,
    payload
  };
};
//create a action to set the consent text
export const setConsentText = (payload) => {
  return {
    type: bannerConstants.SET_CONSENT_TEXT,
    payload
  };
};
//action to set the consent status
export const setConsentStatus = (payload) => {
  return {
    type: bannerConstants.SET_CONSENT_STATUS,
    payload
  };
};

export const updateBannerfetchStatus = (payload) => {
  return {
    type: bannerConstants.UPDATE_BANNER_FETCH_STATUS,
    payload
  };
};
