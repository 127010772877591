import { routeNames } from '../../router/constants';

export const SAVE_FILE_OBJECT = 'SAVE_FILE_OBJECT';
export const SAVE_FILE_CONSENT = 'SAVE_FILE_CONSENT';
export const SAVE_FILE_PRESCRIPTION = 'SAVE_FILE_PRESCRIPTION';
export const OPEN_CAMERA = 'OPEN_CAMERA';

export const BROWSE_FOR_DNR_CONSENT = 'BROWSE_FOR_DNR_CONSENT';
export const BROWSE_FOR_DNR_PRESCRIPTION = 'BROWSE_FOR_DNR_PRESCRIPTION';
export const DEFAULT_MENU = {
  main: 'home',
  menu: [
    {
      id: '1',
      'short-name': 'Home',
      module_name: 'dashboard',
      url: 'dashboard',
      icon: 'HOME',
      title: 'Home',
      premium: 0
    },
    {
      id: '2',
      'short-name': 'K-hub',
      module_name: 'KHUB',
      url: 'contents',
      icon: 'KHUB',
      title: 'Knowledge Hub',
      premium: 0,
      displayIcon: false,
      popDescription: ' Stay updated with the latest research and medical updates'
    },
    {
      id: '3',
      'short-name': 'CPD',
      module_name: 'community',
      url: 'sessions',
      icon: 'CPD',
      title: 'CPD',
      premium: 0,
      displayIcon: false,
      popDescription: 'Access expert led webinars'
    },
    {
      id: '4',
      'short-name': 'Resources',
      url: 'resources',
      icon: 'RESOURCES',
      title: 'Resources',
      module_name: 'Resources',
      premium: 0
    },

    {
      id: '6',
      'short-name': 'Product',
      module_name: 'ddx',
      url: 'https://www.alkemlabs.com/our-offerings/rx-products',
      icon: 'PRODUCT',
      title: 'Alkem Rx Products',
      external: true,
      premium: 0
    },
    {
      id: '7',
      'short-name': 'Profile',
      url: 'profile',
      icon: 'PROFILE',
      title: 'My Profile',
      module_name: 'Profile',
      premium: 0
    },
    {
      id: '8',
      'short-name': 'Logout',
      url: 'Logout',
      icon: 'LOGOUT',
      title: 'Logout',
      module_name: 'Logout',
      premium: 0,
      logout: true
    }
  ]
};
