import React, { memo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
const TooltipContainer = ({
  text = 'element',
  placement = 'left',
  rootClose = true,
  trigger = isMobile ? 'click' : ['hover', 'focus'],
  children
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      rootClose={rootClose}
      trigger={trigger}
      // show={false}
      overlay={text ? <Tooltip className="fs-5">{text}</Tooltip> : <></>}
    >
      {children}
    </OverlayTrigger>
  );
};

export default memo(TooltipContainer);
