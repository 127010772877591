import React from 'react';
import { Card } from 'react-bootstrap';
import { TrophyIcon } from '../../components/icons';
import LazyImage from '../../components/CustomLibraries/LazyImage';
import { useLeaderBoard } from '../../Queries/QueryHooks/session.hook';
import RenderCard from './component/RenderCard';

function LeaderboardPage() {
  const { data: allUserData, isLoading } = useLeaderBoard();
  const data = allUserData?.all_list;

  return (
    <div className="w-100 float-start desktopBodyIn">
      <Card className="rounded-4 m-2 m-md-0 leaderBoard position-relative overflow-hidden">
        <div className="text-center">
          <h1 className="px-5 py-3 text-white fs-2 d-inline-block bg-danger rounded-4 rounded-top-0 text-uppercase mb-4">
            Leaderboard
          </h1>
        </div>

        <Card.Body className="p-4 p-md-5">
          <div className="row gy-4 gy-lg-20 mx-n3">
            {!isLoading && data?.length > 0 && (
              <>
                <RenderCard bgPrimary={true} className={'col-lg-4 px-3'} data={data} />
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default LeaderboardPage;

// function RenderCard({ data, className }) {
//   const colors = ['gold', 'silver', 'bronze'];
//   return (
//     <>
//       {data &&
//         data?.map((data, index) => {
//           return (
//             <>
//               <div className={`leaderBoard-card ${className}`}>
//                 <div
//                   key={data?.id}
//                   className={`d-flex gap-4 align-items-center justify-content-start leaderBoard-card__body`}
//                 >
//                   {index < 3 ? (
//                     <TrophyIcon className={`flex-shrink-0`} color={colors[index]} />
//                   ) : (
//                     <span className="fs-3 text-danger flex-shrink-0 text-nowrap"># {index}</span>
//                   )}
//                   <div className="icon-size-48 flex-shrink-0 bg-light rounded-3 border border-2">
//                     <LazyImage
//                       src={data?.profile_image}
//                       className="object-fit-cover h-100 w-100 float-start"
//                     />
//                   </div>
//                   <div className="fw-semibold  fs-4 line-clamp-1">
//                     {data?.first_name} {data?.middle_name} {data?.last_name}
//                   </div>
//                   <div className="ms-auto text-nowrap">
//                     <b className="fs-4 text-primary">{data?.user_point}</b> Points
//                   </div>
//                 </div>
//               </div>
//             </>
//           );
//         })}
//     </>
//   );
// }
