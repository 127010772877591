import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';

//Full calender
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayjs from 'dayjs';
import interactionPlugin from '@fullcalendar/interaction';

function EventCalendar({ events = [], calendarData = [], setClickedDate }) {
  function handleDateClick(e) {
    const clickedDate = e.dateStr;
    const matchingEvents = calendarData.filter(
      (event) =>
        dayjs(event?.start)?.format('YYYY-MM-DD') <= clickedDate &&
        dayjs(event?.end)?.format('YYYY-MM-DD') >= clickedDate
    );

    if (matchingEvents.length > 0) {
      setClickedDate([matchingEvents]);
    } else {
      setClickedDate([]);
    }
  }

  return (
    <>
      <style jsx>{`
        .fc-daygrid-day-top .fc-daygrid-day-number {
          color: gray !important;
        }
        .fc .fc-col-header-cell-cushion {
          color: gray !important;
        }
        .fc-day-other {
          background-color: #f8f8f8 !important;
        }
      `}</style>

      <Card className="cursorPointer shadow p-4 border-0 fullCalendar rounded-2">
        <Card.Body>
          <FullCalendar
            plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin]}
            dayMaxEvents={4}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth'
            }}
            events={events}
            dateClick={handleDateClick}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default EventCalendar;
