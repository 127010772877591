export const SESSION_FEATURED = 'SESSION_FEATURED';
export const SESSION_UPCOMING = 'SESSION_UPCOMING';
export const SESSION_RESERVED = 'SESSION_RESERVED';
export const SESSION_CLOSED = 'SESSION_CLOSED';
export const SESSION_RESERVED_INFINITE = 'SESSION_RESERVED_INFINITE';
export const SESSION_MASTER_DOCTOR = 'SESSION_MASTER_DOCTOR';
export const SESSION_MASTER_DOCTOR_INFINITE = 'SESSION_MASTER_DOCTOR_INFINITE';
export const SESSION_DETAILS = 'SESSION_DETAILS';
export const IS_MULTIDAY_SESSION = 'IS_MULTIDAY_SESSION';
export const MASTER_DOCTOR_DETAILS = 'MASTER_DOCTOR_DETAILS';
export const MASTER_UPCOMING = 'MASTER_UPCOMING';
export const MASTER_PREVIOUS = 'MASTER_PREVIOUS';
export const MASTER_QUERIES = 'MASTER_QUERIES';
export const LIVE_SESSION_LIST = 'LIVE_SESSION_LIST';
export const SPECIALITY_SESSION = 'SPECIALITY_SESSION';
export const LEADERBOARD = "LEADERBOARD"