import { generateUtmExt, log } from '../../common/common';
import { medwikiConstants } from '../constants/medwikiConstants';

import { logoutUser } from './login.action';
import axiosInstance from '../helper/axiosInstance';

export const getMedwikiTopRatedList = (medwikiPagination, callback, extratype = '') => {
  return async (dispatch) => {
    axiosInstance
      // .get(`knwlg/topcommentedmedwiki?from=${medwikiPagination}&to=5${extratype}`)
      .get(`knwlg/topratedmedwiki?from=${medwikiPagination}&to=10${extratype}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_FALIURE,
          payload: error
        });
      });
  };
};
export const getMedwikiMostCommentedList = (medwikiPagination, callback, extratype = '') => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlg/topcommentedmedwiki?from=${medwikiPagination}&to=5${extratype}`)
      // .get(`knwlg/feed?from=${medwikiPagination}&to=5${extratype}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_FALIURE,
          payload: error
        });
      });
  };
};
export const getMedwikiList = (medwikiPagination, callback, extratype = '') => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlg/feed?from=${medwikiPagination}&to=10${extratype}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_FALIURE,
          payload: error
        });
      });
  };
};

export const getMedwikiTopRatedListWithVideo = (
  medwikiWithVideoPagination,
  typevid = '',
  callback
) => {
  return async (dispatch) => {
    axiosInstance
      // .get(`knwlg/topcommentedmedwiki?from=${medwikiWithVideoPagination}&to=5&type=${typevid}`)
      .get(`knwlg/topratedmedwiki?from=${medwikiWithVideoPagination}&to=10&type=${typevid}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_FALIURE,
          payload: error
        });
      });
  };
};
export const getMedwikiMostCommentedListWithVideo = (
  medwikiWithVideoPagination,
  typevid = '',
  callback
) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlg/topcommentedmedwiki?from=${medwikiWithVideoPagination}&to=5&type=${typevid}`)
      // .get(`knwlg/feed?from=${medwikiWithVideoPagination}&to=5&type=${typevid}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_FALIURE,
          payload: error
        });
      });
  };
};
export const getMedwikiListWithVideo = (medwikiWithVideoPagination, typevid = '', callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlg/feed?from=${medwikiWithVideoPagination}&to=10&type=${typevid}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_VIDEO_FALIURE,
          payload: error
        });
      });
  };
};

export const getAllSpecialities = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('authnew/getallspeciality')
      .then((response) => {
        callback(response.data);
        let speciality_data = response.data?.data;
        dispatch({
          type: medwikiConstants.GET_ALL_SPECILAITIES_SUCCESS,
          payload: speciality_data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: medwikiConstants.GET_ALL_SPECILAITIES_FALIURE
        });
      });
  };
};

export const getMedwikiTopRatedListWithSpecialities = (
  compendium_pagination_limit,
  parse_id = '',
  typevid = '',
  callback
) => {
  return async (dispatch) => {
    log('parseId', parse_id);
    axiosInstance
      // .get(
      //   `knwlg/topcommentedmedwiki?from=${compendium_pagination_limit}&to=5&spIds=${parse_id}&type=${typevid}`
      // )
      .get(
        `knwlg/topratedmedwiki?from=${compendium_pagination_limit}&to=10&spIds=${parse_id}&type=${typevid}`
      )
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_FALIURE,
          payload: error
        });
      });
  };
};
export const getMedwikiMostCommentedListWithSpecialities = (
  compendium_pagination_limit,
  parse_id = '',
  typevid = '',
  callback
) => {
  return async (dispatch) => {
    log('parseId', parse_id);
    axiosInstance
      .get(
        `knwlg/topcommentedmedwiki?from=${compendium_pagination_limit}&to=5&spIds=${parse_id}&type=${typevid}`
      )
      // .get(`knwlg/feed?from=${compendium_pagination_limit}&to=5&spIds=${parse_id}&type=${typevid}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_FALIURE,
          payload: error
        });
      });
  };
};
export const getMedwikiListWithSpecialities = (
  compendium_pagination_limit,
  parse_id = '',
  typevid = '',
  callback
) => {
  return async (dispatch) => {
    log('parseId', parse_id);
    axiosInstance
      .get(`knwlg/feed?from=${compendium_pagination_limit}&to=10&spIds=${parse_id}&type=${typevid}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: medwikiConstants.GET_MEDWIKI_LIST_WITH_SPECIALITIES_FALIURE,
          payload: error
        });
      });
  };
};

export const GetMedwikiDetails = (id, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`knwlg/feedDetail?type_id=${id}&type=comp${generateUtmExt()}`)
      .then((response) => {
        callback(response.data);
        console.log(response.data);
        dispatch({
          type: medwikiConstants.GET_MEWIKI_DETAIL_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        log('The Error', error);
        dispatch({
          type: medwikiConstants.GET_MEWIKI_DETAIL_FALIURE,
          payload: error
        });
      });
  };
};

export const GetRelatedMedwiki = (speccs, typeId, view_more, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`knwlg/related?type=comp&speciality=${speccs}&type_id=${typeId}&from=${view_more}&to=5`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: medwikiConstants.GET_RELATED_MEDWIKI_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
        dispatch({
          type: medwikiConstants.GET_RELATED_MEDWIKI_FALIURE,
          payload: error
        });
      });
  };
};
