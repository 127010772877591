import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { TrophyIcon } from '../../../components/icons';
import React from 'react';

export default function RenderCard({ data, className, bgPrimary = false }) {
  const colors = ['gold', 'silver', 'bronze'];
  return (
    <>
      {data &&
        data?.length > 0 &&
        data?.map((data, index) => {
          return (
            <>
              <div className={`${bgPrimary ? 'leaderBoard-card' : ''} ${className || ''}`}>
                <div
                  key={index}
                  className={`${
                    bgPrimary
                      ? 'leaderBoard-card__body'
                      : 'leaderBoard-card__body leaderBoard-card__bodyList'
                  } d-flex gap-3 align-items-center justify-content-start`}
                >
                  {index < 3 ? (
                    <TrophyIcon className="flex-shrink-0" color={colors[index]} />
                  ) : (
                    <span className="fs-3 text-danger flex-shrink-0 text-nowrap">
                      # {index + 1}
                    </span>
                  )}
                  <div className="icon-size-48 flex-shrink-0 bg-light rounded-3 border border-2">
                    <LazyImage
                      src={data?.profile_image}
                      className="object-fit-cover h-100 w-100 float-start"
                    />
                  </div>
                  <div
                    className={`${
                      bgPrimary ? '' : 'flex-column-reverse align-items-start'
                    } d-flex w-100`}
                  >
                    <div className="fw-semibold fs-4 line-clamp-1 flex-grow-1">
                      {data?.title} {data?.first_name} {data?.middle_name} {data?.last_name}
                    </div>
                    <div className={`${bgPrimary ? 'fs-4' : 'fs-5'} text-nowrap flex-shrink-0`}>
                      <b className="text-primary">{data?.user_point}</b> Points
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
}
