import { getLocalStorage } from '../../common/common';
import { loginConstants } from '../constants/login.constant';

let initialState = {
  isLogin: getLocalStorage('isLoggedIn', false),
  error: '',
  loginDetails: {},
  userDetails: null
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true
      };

    case loginConstants.LOGIN_FAILURE:
    case loginConstants.LOG_OUT:
      return {
        ...state,
        isLogin: false
      };
    case loginConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload.data
      };

    case loginConstants.USER_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };

    default:
      return state;
  }
};
