import { certificateConstants } from '../constants/certificate.constant';
import { commonConstants } from '../constants/commonConstants';

const initialState = {
  certList: [],
  certDetails: {}
};

export const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case certificateConstants.GET_CERT_LIST_SUCCESS:
      return {
        ...state,
        certList: [...state.certList, ...action.payload.data]
      };

    case certificateConstants.GET_CERT_LIST_FALIURE:
      return {
        ...state,
        certList: []
      };

    case certificateConstants.GET_CERT_DETAILS_SUCCESS:
      return {
        ...state,
        certDetails: action.payload.data
      };

    case certificateConstants.GET_CERT_DETAILS_FALIURE:
      return {
        ...state,
        certDetails: {}
      };

    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
