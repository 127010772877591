//ACTION TYPE

export const PAGE_VIEW = 'PAGE_VIEW';
export const CARD_VIEW = 'CARD_VIEW';
export const CARD_CLICK = 'CARD_CLICK';
export const CTA_CLICK = 'CTA_CLICK';
export const CTA_VIEW = 'CTA_VIEW';
export const LIKE_CLICK = 'LIKE_CLICK';
export const VAULT_CLICK = 'VAULT_CLICK';
export const COMMENT_SUBMIT = 'COMMENT_SUBMIT';
export const COMMENT_LIKE_CLICK = 'COMMENT_LIKE_CLICK';
export const COMMENT_REPLY_SUBMIT = 'COMMENT_REPLY_SUBMIT';
export const EXPLORE_MORE_CLICK = 'EXPLORE_MORE_CLICK';
export const GET_RECORDING_CLICK = 'GET_RECORDING_CLICK';

export const BANNER_LOAD = 'BANNER_LOAD';
//services name

export const MEDWIKI_SERVICE = 'comp';
