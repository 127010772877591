import { compose, createStore, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const root = async () => {
  const { rootReducer } = await import('./reducers/index');
  return rootReducer;
};
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

export default store;
