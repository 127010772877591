import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAccessError, toggleFormFocus, toggleGlobalModal } from '../../Store/actions';

function GlobalModal() {
  const globalModalData = useSelector((state) => state.common.globalModal);
  const dispatch = useDispatch();

  return (
    <Modal
      className="centerModal"
      size={globalModalData?.size ? globalModalData?.size : 'md'}
      centered
      show={globalModalData?.show}
      onHide={() =>
        dispatch(
          toggleGlobalModal({
            show: false,
            message: ''
          })
        )
      }
    >
      <Modal.Body className="p-4">
        <form>
          <div className="w-100 centerModalFrm">
            <div className="row mt-n4 p-4">
              {globalModalData?.icon ? (
                <div className="w-100 fw-medium fs-4 my-2 text-black text-center">
                  {globalModalData?.icon}
                </div>
              ) : (
                <></>
              )}
              {globalModalData?.message ? (
                <div className="w-100 fw-medium fs-4 my-2 text-black text-center">
                  {globalModalData?.message}
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-center">
                <div
                  className="btn btn-outline-primary btn-sm mt-4 "
                  onClick={() => {
                    dispatch(
                      toggleGlobalModal({
                        show: false,
                        message: ''
                      })
                    );
                  }}
                >
                  Login Now
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default GlobalModal;
