import { getLocalStorage, isEligibleForSpeciality } from '../../common/common';
import { settingsConstant } from '../constants/settings.constants';
const userDetails = () => {
  return getLocalStorage('user', false) ? getLocalStorage('user', {}) : null;
};
const getSpecialityOfUser = () => {
  let specialityArray = getLocalStorage('user', false)
    ? JSON.parse(getLocalStorage('user', {})?.speciality ?? '[]')
    : [];
  if (specialityArray?.length > 0) {
    return specialityArray[0]?.specialities_name;
  } else {
    return '';
  }
};
const initialState = {
  themeName: getSpecialityOfUser(),
  is_speciality_dashboard: userDetails()?.spec_dashboard_eligibility_status ?? false,
  baseClass: 'w-100'
};
export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingsConstant.CHAGE_THEME:
      return {
        ...state,
        themeName: action.payload
      };
    case settingsConstant.UPDATE_IS_SPECIALITY:
      return {
        ...state,
        is_speciality_dashboard: action.payload
      };
    case settingsConstant.CHANGE_BASE_CLASS:
      return {
        ...state,
        baseClass: action.payload
      };
      
    default:
      return state;
  }
};
