import React, { memo, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getLocalStorage, isIosApp, removeLocalStorage } from '../../common/common';
import { GrClose } from '../../components/icons';
import { routeNames } from '../../router/constants';
import './css/headerMobileDetails.scss';
import LoaderLine from '../LoaderLine';
import useRedirect from '../../common/hooks/useRedirect';
import useGetParams from '../../common/hooks/useGetParams';
function HeaderMobileDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectTo } = useRedirect();
  const { id, title } = useGetParams();
  const dispatch = useDispatch();
  const isFromNotification = getLocalStorage('autoAuthFromNotification', false);
  const isFromAutoAuth = getLocalStorage('autoAuthFromAppUrlListener', false);
  const current_menu = useSelector((state) => state.common.current_menu);

  const redirectUrl = useSelector((state) => state.session.redirectUrl);
  function onExitClick() {
    if (redirectUrl?.startsWith(window.location.origin)) {
      let replacedNavigation = redirectUrl.replace(window.location.origin + '/#', '');
      redirectTo(replacedNavigation);
    } else {
      window.location.href = redirectUrl;
    }
  }

  const cancelClick = () => {
    if (redirectUrl) {
      onExitClick();
      return;
    }

    if (location?.state?.from_login) {
      if (current_menu?.toLowerCase()?.includes('session')) {
        navigate(routeNames.sessions.landing);
      } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
        navigate(routeNames.medwiki.landing);
      } else if (current_menu?.toLowerCase()?.includes('spq')) {
        navigate(routeNames.spq.landing);
      } else if (current_menu?.toLowerCase()?.includes('clvideo')) {
        navigate(routeNames.clinicalVideo.landing);
      } else if (current_menu?.toLowerCase()?.includes('gr')) {
        navigate(routeNames.grandRound.landing);
      } else if (current_menu?.toLowerCase()?.includes('ebook')) {
        navigate(routeNames.epaper.landing);
      } else if (current_menu?.toLowerCase()?.includes('channel')) {
        navigate(routeNames.channel.landing);
      } else if (current_menu?.toLowerCase()?.includes('dnr')) {
        navigate(routeNames.discussRefer.landing);
      } else if (current_menu?.toLowerCase()?.includes('certificate')) {
        navigate(routeNames.certificate.landing);
      } else if (current_menu?.toLowerCase()?.includes('training')) {
        navigate(routeNames.course.landing);
      } else {
        navigate(routeNames.dashboard.landing);
      }
    } else {
      if (location.pathname?.toLowerCase().includes('livesession')) {
        redirectTo(routeNames.sessions.detail, id, title);
      } else if (current_menu?.toLowerCase()?.includes('session')) {
        navigate(routeNames.sessions.landing);
      } else {
        navigate(-1);
      }
    }
  };

  const HandleAppReload = () => {
    window.location.reload();
  };

  return (
    <Navbar fixed="top" className="mblHeaderDetails flex-column p-0">
      <LoaderLine />
      {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100 bg-light"></div> : <></>}
      <div className="mblHeaderDetailsMain w-100 bg-white shadow d-flex justify-content-between align-items-center">
        <div
          className="mblDtlsNavLeft gtm_cl_header_clirnet_logo d-flex align-items-center  flex-column"
          onClick={() => navigate(routeNames.dashboard.landing)}
        >
          <img
            src={'https://assets.medisamvad.com/static/iconLogo.svg'}
            alt="top Logo"
            id="header_mobileDetails_clirnet_logo"
            className="float-start gtm_cl_header_clirnet_logo"
            width={40}
          />
          <h5 class="fs-7 ps-1">
            Powered by <span class="text-secondary fw-semibold">ALKEM</span>
          </h5>
        </div>
        <div className="d-flex align-items-center ms-auto gap-4">
          <div className="mblDtlsNavRight" onClick={cancelClick}>
            <GrClose customClass="text-black fs-30" id={'header_mobileDetails_close'} />
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default memo(HeaderMobileDetails);
