import dayjs from 'dayjs';
// const dayjs(new Date().getTime()).format("HH:mm:ss") = dayjs(new Date().getTime()).format("HH:mm:ss");
import {
  PAGE_VIEW,
  CLOSE_PAGE_VIEW,
  CLICK_CARD,
  CLICK_BEGIN_BUTTON,
  CHANGE_TAB,
  CLICK_SHARE_MENU,
  CLICK_SHARE,
  REDIRECT,
  CLICK_VIEW_RESULT,
  OPEN_NEW_TAB,
  EVENT,
  SEARCH,
  CHANGE_OPTIONS,
  CHANGE_INPUT,
  VALIDATION_ERROR,
  CLICK_CANCEL_SESSION,
  VAULT_PRESS,
  SELECT_FILTER_SPECIALITY,
  SELECT_FILTER_SESSION_TYPE,
  CLICK_CANCEL_REASON,
  LIKE_PRESS,
  CLICK_FILTER,
  CLICK_DETAILS,
  CLICK_CANCEL_QUESTION,
  NOT_FOUND,
  DEFAULT_ID,
  CLICK_RELATED_CONTENT,
  CLICK_SEARCH,
  CLICK_EDIT_PROFILE,
  CLICK_LOGIN_BUTTON,
  CLICK_REGISTER_BUTTON,
  CLICK_VERIFY_OTP,
  CLICK_RESEND_OTP,
  MASTERDOCTOR_CLICK,
  ATTCH_FILE_CLICK,
  SUBMIT_SESSION_CLICK,
  ON_FOLLOW_CLICK,
  CLICK_VIEW_ALL_SUGGESTION,
  ON_UNFOLLOW_CLICK,
  ON_MANAGE_CLICK,
  NOT_FROM_DEEPLINK,
  CLICK_GET_CALL_INSTEAD,
  DO_PAGINATION,
  DOWNLOAD_CONTENT,
  SELECT_QUESTION_OPTION,
  VIDEO_TRACKING,
  ON_VIEW_ALL_BUTTON_CLICK,
  MOVE_TO_NEXT_PAGE,
  CLICK_ADD_PROFILE,
  CLICK_DELETE_PROFILE,
  ACCESS_REQUESTED,
  CLICK_ACTIVITY_CARD,
  AUTOAUTH_ERROR,
  AUTOAUTH_SIGN_UP_CLICK,
  SESSION_INVITATION_ERROR,
  SESSION_INVITATION_SIGN_UP_CLICK,
  ATTEND_SESSION_CLICK,
  CONNECT_DOCTOR_CLICK,
  CLICK_RESERVE_BUTTON,
  SELECT_EBOOK_CARD,
  SPECIALITY_CLICK,
  CLICK_CTA_CARD,
  USER_RATING
} from '../constants/ActionType';
import { getLocalStorage } from '../../common/common';
export const onPageView = (page_name, id = 0, shareContentType = NOT_FROM_DEEPLINK) => {
  return {
    type: PAGE_VIEW,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    content_type: page_name,
    contentID: id,
    shareContentType: shareContentType,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onPageClose = (page_name) => {
  return {
    type: CLOSE_PAGE_VIEW,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageName: page_name,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onPagination = (count, page_name) => {
  return {
    type: DO_PAGINATION,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageCount: count,
    pageName: page_name,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onCardClick = (id, cardName) => {
  return {
    type: CLICK_CARD,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    cardName: cardName,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onUpload = (upload_section) => {
  return {
    type: 'EVENT',
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    upload_section
  };
};

export const onCtaCardClick = (cta_type, cta_type_id, type, type_id) => {
  return {
    type: CLICK_CTA_CARD,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cta_type,
    cta_type_id,
    target_type: type,
    target_type_id: type_id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onViewActivityClick = (activity = 'all') => {
  return {
    type: CLICK_ACTIVITY_CARD,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    activityCardName: activity,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onTabChange = (selcted = 'not found') => {
  return {
    type: CHANGE_TAB,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    selectedTab: selcted,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onCardMenuClick = (selectedId = 'not found', pageNames = 'not found') => {
  return {
    type: CLICK_SHARE_MENU,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    contentID: selectedId,
    pageName: pageNames,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onInnerPageMenuClick = (innerMenu = 'not found') => {
  return {
    type: innerMenu,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    innerPageMenu: innerMenu,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

//for spq begin button click
export const onBeginButtonClick = (id = DEFAULT_ID) => {
  return {
    type: CLICK_BEGIN_BUTTON,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    spq_id: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onRedirect = (from_page = NOT_FOUND, to_page = NOT_FOUND) => {
  return {
    type: REDIRECT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    from: from_page,
    to: to_page,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onViewResultClick = (id = 0) => {
  return {
    type: CLICK_VIEW_RESULT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    id: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onOpenNewTab = (rUrl = NOT_FOUND) => {
  return {
    type: OPEN_NEW_TAB,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    url: rUrl,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

//for inner page menu click
export const trackEvent = (eve = NOT_FOUND) => {
  return {
    type: EVENT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    event: eve,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onSearch = (pKeyword = NOT_FOUND, pResult = NOT_FOUND) => {
  return {
    type: SEARCH,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    keyword: pKeyword,
    result: pResult,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onInputBoxChange = (pType = NOT_FOUND, pValue = NOT_FOUND) => {
  return {
    type: CHANGE_INPUT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    name: pType,
    value: pValue,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onOptionChange = (pType = NOT_FOUND, pValue = NOT_FOUND) => {
  return {
    type: CHANGE_OPTIONS,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    option_name: pType,
    value: pValue,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onValidationError = (pMsg = NOT_FOUND) => {
  return {
    type: VALIDATION_ERROR,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    message: pMsg,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onClickCancelSession = (sessionId = 'not found') => {
  return {
    type: CLICK_CANCEL_SESSION,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: sessionId,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onVault = (typeId = 'not found', page_name = 'not found') => {
  return {
    type: VAULT_PRESS,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    contentID: typeId,
    pageName: page_name,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onLike = (typeId = 'not found', page_name = 'not found') => {
  return {
    type: LIKE_PRESS,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    contentID: typeId,
    pageName: page_name,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onUserRating = (typeId = 'not found', page_name = 'not found', rating = 0) => {
  return {
    type: USER_RATING,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    contentID: typeId,
    pageName: page_name,
    rating: rating,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onSelectSpeciality = (speciality = 'not found') => {
  return {
    type: SELECT_FILTER_SPECIALITY,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    speciality: speciality,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onSelectSesType = (ses_type = 'not found') => {
  return {
    type: SELECT_FILTER_SESSION_TYPE,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    session_type: ses_type,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onClickCancelReason = (reason) => {
  return {
    type: CLICK_CANCEL_REASON,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    reason: reason,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onClickFilter = () => {
  return {
    type: CLICK_FILTER,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onClickViewDetails = (doctorName = 'not found') => {
  return {
    type: CLICK_DETAILS,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    doctorName: doctorName,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onClickCancelQuestion = (session_topic = 'not found') => {
  return {
    type: CLICK_CANCEL_QUESTION,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    session_topic: session_topic,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onRelatedCLick = (id = DEFAULT_ID, type = NOT_FOUND) => {
  return {
    type: CLICK_RELATED_CONTENT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    content_type: type,
    content_id: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onShareClick = (id = DEFAULT_ID, type = NOT_FOUND, link = NOT_FOUND) => {
  return {
    type: CLICK_SHARE,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    content_type: type,
    content_id: id,
    sessionId: getLocalStorage('sessionId', 'null'),
    share_link: link
  };
};

export const onClickSearch = (search_text = '') => {
  return {
    type: CLICK_SEARCH,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    search_text: search_text,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
///profile
export const onClickEditProfile = (type, id = 0) => {
  return {
    type: CLICK_EDIT_PROFILE,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    profileItemType: type,
    id: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onClickAddProfile = (type) => {
  return {
    type: CLICK_ADD_PROFILE,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    profileItemType: type,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onClickDeleteProfile = (type, id) => {
  return {
    type: CLICK_DELETE_PROFILE,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    profileItemType: type,
    id: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onLoginButtonClick = (pageName, Mobile, Email) => {
  return {
    type: CLICK_LOGIN_BUTTON,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageName: pageName,
    Mobile: Mobile,
    Email: Email,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onRegisterButtonClick = (
  pageName,
  honarific,
  FullName,
  Email,
  Pin,
  Specilities,
  referal_code
) => {
  return {
    type: CLICK_REGISTER_BUTTON,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageName: pageName,
    honarific: honarific,
    FullName: FullName,
    Email: Email,
    Pin: Pin,
    Specilities: Specilities,
    ReferalCode: referal_code,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onOtpVerifyClick = (pageName) => {
  return {
    type: CLICK_VERIFY_OTP,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageName: pageName,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onResendOtpClick = (pageName) => {
  return {
    type: CLICK_RESEND_OTP,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageName: pageName,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onGetCallClick = (pageName) => {
  return {
    type: CLICK_GET_CALL_INSTEAD,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageName: pageName,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onMasterDoctorCLick = (pageName) => {
  return {
    type: MASTERDOCTOR_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    pageName: pageName,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onClickAttachFile = (fileType, url) => {
  return {
    type: ATTCH_FILE_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    fileType,
    url,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onSubmitButtonClick = (query) => {
  return {
    type: SUBMIT_SESSION_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    query: query,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onFollow = (id) => {
  return {
    type: ON_FOLLOW_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onViewAllSuggestion = () => {
  return {
    type: CLICK_VIEW_ALL_SUGGESTION,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onClickManage = () => {
  return {
    type: ON_MANAGE_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onUnFollow = (id) => {
  return {
    type: ON_UNFOLLOW_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const AccessRequested = (id) => {
  return {
    type: ACCESS_REQUESTED,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    cardId: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onDownloadFile = (content_type, title, pageName) => {
  return {
    type: DOWNLOAD_CONTENT,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    content_type: content_type,
    content_title: title,
    page_name: pageName,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onAnswerSelect = (option) => {
  return {
    type: SELECT_QUESTION_OPTION,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    selectedOption: option,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onVideoTrack = (trackData) => {
  return {
    type: VIDEO_TRACKING,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    tracking_data: trackData,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onViewAllClick = (con_type) => {
  return {
    type: ON_VIEW_ALL_BUTTON_CLICK,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    contentType: con_type,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onMoveNextPage = (
  type,
  bookId = 'NOT_FOUND',
  page = 'NOT_FOUND',
  chapter = 'NOT_EPUB_OR_NOT_FOUND'
) => {
  return {
    type: MOVE_TO_NEXT_PAGE,
    fileType: type,
    pageNumber: page,
    eBookId: bookId,
    ChapterName: chapter,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onAutoauthSignUp = (fname, lname, email, phoneNumber, page_name) => {
  return {
    type: AUTOAUTH_SIGN_UP_CLICK,
    firstName: fname,
    lastName: lname,
    email: email,
    phone_number: phoneNumber,
    pageName: page_name,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const autoAuthError = (err, pageName) => {
  return {
    type: AUTOAUTH_ERROR,
    error: err,
    pageName: pageName,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onSessionInvitationSignUp = (fname, lname, email, phoneNumber, page_name) => {
  return {
    type: SESSION_INVITATION_SIGN_UP_CLICK,
    firstName: fname,
    lastName: lname,
    email: email,
    phone_number: phoneNumber,
    pageName: page_name,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const sessionInvitationError = (err, pageName) => {
  return {
    type: SESSION_INVITATION_ERROR,
    error: err,
    pageName: pageName,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const attendSessionClick = (id, topic) => {
  return {
    type: ATTEND_SESSION_CLICK,
    session_id: id,
    session_topic: topic,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const connectDoctorClick = (name, id) => {
  return {
    type: CONNECT_DOCTOR_CLICK,
    doctor_name: name,
    doctor_id: id,
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const sessionReserveClick = (name, id) => {
  return {
    type: CLICK_RESERVE_BUTTON,
    session_title: name,
    session_id: id,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const ebookCardClick = (name, id) => {
  return {
    type: SELECT_EBOOK_CARD,
    card_Title: name,
    card_id: id,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};
export const onSpecialityContentClick = (spId, content) => {
  return {
    type: SPECIALITY_CLICK,
    speciality_id: spId,
    content,
    timestamp: dayjs(new Date().getTime()).format('HH:mm:ss'),
    sessionId: getLocalStorage('sessionId', 'null')
  };
};

export const onBannerReport = (eventType, position, other) => {
  return { type: eventType, position, ...other, sessionId: getLocalStorage('sessionId', 'null') };
};
