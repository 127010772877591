import { profileConstants } from '../constants/profile.constant';

let initialState = {
  education: [],
  membership: [],
  achievement: [],
  registration: []
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case profileConstants.GET_ALL_EDUCATION:
      return {
        ...state,
        education: action?.payload ?? []
      };
    case profileConstants.GET_ALL_MEMBERSHIP:
      return {
        ...state,
        membership: action?.payload ?? []
      };
    case profileConstants.GET_ALL_ACHIEVEMENT:
      return {
        ...state,
        achievement: action?.payload ?? []
      };
    case profileConstants.GET_ALL_REGISTRATION:
      return {
        ...state,
        registration: action?.payload ?? []
      };
    default:
      return state;
  }
};
