import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalCampaign } from './actions/useGlobalCampaign';
import ReactPlayer from 'react-player';
import ImageBannerPlayer from '../banner/ImageBannerPlayer';
import { useBannerSettings } from './actions/useBannerSettings';
import { useLocation } from 'react-router';
import ls from 'localstorage-slim';

import {
  downloadFromUrl,
  getAgent,
  getClientDeviceDetails,
  getLocalStorage,
  getSessionStorage,
  getUtmSource,
  removeSessionStorage,
  setSessionStorage,
  userDetails
} from '../../common/common';
import { useContentCampaign } from './actions/useContentCampaign';
import { useExcludeCampaign } from './actions/useExcludeCampaign';
import { addBannerClickReport, addBannerConsentClickReport } from '../../Store/actions';
import { useDispatch } from 'react-redux';
import ConsentModal from '../banner/ConsentModal';
import { openInNewTab } from './utils/openInNewTab';
import axiosInstance from '../../Store/helper/axiosInstance';
import { ADD_BANNER_REPORT, GET_CONTENT_CAMPAIGN_DATA, GET_GLOBAL_DATA } from './apis/banner.apis';
import { getPlatForm } from './utils/getPlatForm';
import { getCurrentTime } from './utils/getCurrentTime';
import packageJson from '../../../package.json';
import { useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { isMobile } from 'react-device-detect';

import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import useGetParams from '../hooks/useGetParams';
import { useTracker } from '../../TrackerFolder/TrackerComponent';
import { BANNER_LOAD } from '../tracking/constants';
import { onBannerLoad } from '../tracking/UserActions';
import { generatePayload, sendReport } from './utils/sendReport';
import {
  BANNER_COMPONENT_LOAD,
  BANNER_COMPONENT_UNLOAD,
  BANNER_COMPONENT_VIDEO_ERROR,
  BANNER_CONTENT_CAMPAIGN_RESPONSE,
  BANNER_CREATIVE_SELECTED,
  BANNER_GLOBAL_CAMPAIGN_RESPONSE
} from '../../TrackerFolder/constants/ActionType';
import { onBannerReport } from '../../TrackerFolder/actions';
import useRedirect from '../../common/hooks/useRedirect';
import useNetwork from '../../common/hooks/useNetwork';

function BannerItem({
  position,
  page_id: pageId = null,
  page_name = null,
  wrapper = null,
  layout = 'default',
  type = 'default',
  closedAdsCount = 0,
  setHasData = null,
  disableHasData = null,
  setBannerData = null,
  setPlayedDuration
}) {
  return null;
  const { trackActivityEvent } = useTracker();
  const { id } = useParams();
  const page_id = pageId ? pageId : id;
  const queryClient = useQueryClient();
  const [removeDiv, setRemoveDiv] = useState(true);
  const [bannerType, setBannerType] = useState('floating');
  const [currentData, setCurrentData] = useState(null);
  const [playingItem, setPlayingItem] = useState({});
  const [enableContentCampaign, setEnableContentCampaign] = useState(false);
  const [is_consent_modal, setIsConsentModal] = useState(false);
  const [playing, setPlaying] = useState(false);
  const timestamp_get = useRef();
  const api_url = process.env.REACT_APP_API_URL;
  const appVersion = packageJson.version;
  let visibilityChange = typeof document.hidden
    ? 'visibilitychange'
    : typeof document.msHidden
    ? 'msvisibilitychange'
    : typeof document.webkitHidden
    ? 'webkitvisibilitychange'
    : 'visibilitychange';
  const playingItemRef = useRef(playingItem);
  const bannerRef = useRef();
  const contentCampignRef = useRef();
  const networkState = useNetwork();

  const dispatch = useDispatch();
  const toggleConsentModal = (payload) => {
    setPlaying(!payload);
    setIsConsentModal(payload);
  };
  const { redirectTo } = useRedirect();

  const { data: excludedCampigns = { ids: [] } } = useExcludeCampaign(page_id, page_name, () => {
    setEnableContentCampaign(true);
  });
  const { data: currentSettings } = useBannerSettings(position, type);
  const { data: contentBannerData, isFetching: isContentCampaignDataFetching } = useContentCampaign(
    page_id,
    page_name,
    position,
    excludedCampigns,
    !!page_id,
    bannerType
  );
  const { data: globalBannerData, isFetching: isGlobalBannerFetching } = useGlobalCampaign(
    page_id,
    position,
    bannerType,
    excludedCampigns
  );
  const { data: bannerData, timestamp_added: timestamp_added_global } = globalBannerData || {};

  const {
    data: contentCampignData,
    timestamp_added: timestamp_added_content,
    hasData: hasContentCampaignData = true
  } = contentBannerData || {
    data: []
  };

  const cardRefNew = useRef(null);
  const [cardRef, inView] = useInView({
    fallbackInView: true,
    threshold: 0
  });

  useEffect(() => {
    if (inView) {
      setPlaying(true);
    } else {
      if (playingItemRef.current && 'creative_id' in playingItemRef.current) {
        //report partial view

        reportBannerView(playingItemRef.current);
        playingItemRef.current = {};
      }
      setPlaying(false);
    }
  }, [inView]);

  async function fetchOtherType(calledFrom) {
    await syncWithSessionStorage();
    if (bannerType === 'floating') {
      setBannerType('filler');
    }
  }

  //set the selected data
  useEffect(() => {
    // console.group('campaign:', position);
    if (bannerData !== undefined && contentCampignData !== undefined) {
      if (page_id != null) {
        //called from details page
        timestamp_get.current = timestamp_added_content;

        if (hasContentCampaignData) {
          if (!isContentCampaignDataFetching && Array.isArray(contentCampignData)) {
            if (contentCampignData.length > 0) {
              const payload = {
                ...JSON.parse(getSessionStorage('banner_item', '{}')),
                [position]: contentCampignData
              };

              setSessionStorage(`banner_item`, JSON.stringify(payload));
              setCurrentData(contentCampignData);
            } else {
              fetchOtherType('use effect else page id !=null');
            }
          }
        } else if (Array.isArray(bannerData) && bannerData.length > 0) {
          timestamp_get.current = timestamp_added_global;
          const payload = {
            ...JSON.parse(getSessionStorage('banner_item', '{}')),
            [position]: bannerData
          };
          setSessionStorage(`banner_item`, JSON.stringify(payload));
          setCurrentData(bannerData);
        } else {
          fetchOtherType('use effect else page id !=null');
        }
      } else {
        //called from non details page
        timestamp_get.current = timestamp_added_global;
        if (!isGlobalBannerFetching && Array.isArray(bannerData) && bannerData?.length > 0) {
          if (bannerData.length > 0) {
            const payload = {
              ...JSON.parse(getSessionStorage('banner_item', '{}')),
              [position]: bannerData
            };
            setSessionStorage(`banner_item`, JSON.stringify(payload));
            setCurrentData(bannerData);
          } else {
            fetchOtherType('banner data length < 0');
          }
        } else {
          if (!isGlobalBannerFetching && Array.isArray(bannerData) && bannerData?.length == 0) {
            fetchOtherType('in else');
          }
        }
      }
    }
  }, [bannerData, contentBannerData]);

  // useEffect(() => {
  //   if (currentData && Array.isArray(currentData) && currentData.length == 0 && page_id) {
  //     if (bannerData && Array.isArray(bannerData) && bannerData.length > 0) {
  //       const payload = {
  //         ...JSON.parse(getSessionStorage('banner_item', '{}')),
  //         [position]: bannerData
  //       };
  //       setSessionStorage(`banner_item`, JSON.stringify(payload));
  //       setCurrentData(bannerData);
  //     }
  //   }
  // }, [currentData]);

  //start showing banner
  useEffect(() => {
    if (
      currentSettings &&
      currentSettings != null &&
      currentData &&
      Array.isArray(currentData) &&
      currentData.length > 0 &&
      playing
    ) {
      if (playing) {
        sendCampaign(0);
      }
    }
  }, [currentData, currentSettings, playing]);

  useEffect(() => {
    if (currentData && Array.isArray(currentData) && currentData.length <= 0) {
      if (disableHasData && bannerType === 'filler') {
        disableHasData();
      }
    }
  }, [currentData, disableHasData, bannerType]);

  function getQualifiedCreatives(campaign) {
    var previouslyPlayed = JSON.parse(getSessionStorage(`banner_playing`, '{}'))[position] || [];
    var conflictCategoriesRunning =
      JSON.parse(getSessionStorage('banner_conflict_ids', '[]')) || [];

    switch (campaign?.target_type?.toLowerCase()) {
      case 'filler':
        return campaign?.items?.filter((item) => {
          return item.creative_dimension == currentSettings.dimention;
          // const itemConflictCategoryIds = item?.conflict_brand_category?.map(function (_item) {
          //   return _item['category_id'];
          // });
          // const foundConflict = itemConflictCategoryIds.some((_elem) =>
          //   conflictCategoriesRunning.includes(_elem)
          // );
          // if (foundConflict) {
          //   return false;
          // } else {
          //   return (
          //     item.creative_dimension == currentSettings.dimention &&
          //     !previouslyPlayed.includes(item.creative_id)
          //   );
          // }
        });

      default:
        return campaign?.items?.filter((item) => {
          const itemConflictCategoryIds =
            'conflict_brand_category' in item
              ? item?.conflict_brand_category?.map(function (_item) {
                  return _item['category_id'];
                })
              : [];

          const foundConflict = itemConflictCategoryIds?.some((_elem) =>
            conflictCategoriesRunning.includes(_elem)
          );
          if (foundConflict) {
            return false;
          } else {
            return (
              item.creative_dimension == currentSettings.dimention &&
              !previouslyPlayed.includes(item.creative_id) &&
              parseInt(campaign.max_view_per_session) >= 1 &&
              parseInt(campaign.bal_view_per_user_per_camp) >= 1
            );
          }
        });
    }
  }

  function _searchForCreative(campaign, index) {
    var currentlyPlaying = [];
    var previouslyPlayed = JSON.parse(getSessionStorage(`banner_playing`, '{}'))[position] || [];

    const creatives = getQualifiedCreatives(campaign);
    if (creatives && Array.isArray(creatives) && creatives.length > 0) {
      if (previouslyPlayed.length > 0) {
        currentlyPlaying = [...previouslyPlayed, creatives[0].creative_id];
      } else {
        currentlyPlaying.push(creatives[0].creative_id);
      }

      setSessionStorage(
        `banner_playing`,
        JSON.stringify({
          ...JSON.parse(getSessionStorage('banner_playing', '{}')),
          [position]: currentlyPlaying
        })
      );
      const itemConflictCategoryIds =
        'conflict_brand_category' in creatives[0]
          ? creatives[0]?.conflict_brand_category.map(function (_item) {
              return _item['category_id'];
            })
          : [];

      var conflictCategoriesRunning = [
        ...JSON.parse(getSessionStorage('banner_conflict_ids', '[]')),
        ...itemConflictCategoryIds
      ];

      setSessionStorage('banner_conflict_ids', JSON.stringify(conflictCategoriesRunning));

      return Promise.resolve(creatives[0]);
    } else return Promise.resolve({});
  }

  async function sendCampaign(index) {
    // if(playing){}
    var selectedItem;
    if (currentData.length > index) {
      for (var i = index; i < currentData.length; i++) {
        selectedItem = await _searchForCreative(currentData[i], i);
        if ('creative_id' in selectedItem) {
          if (setHasData) {
            setHasData(true);
          } else {
            if (setBannerData) {
              setBannerData({
                ...selectedItem,
                currentPlayingCampaignIndex: i,
                campaign: { ...currentData[i] }
              });
            }
            setPlayingItem({
              ...selectedItem,
              currentPlayingCampaignIndex: i,
              campaign: { ...currentData[i] }
            });

            trackActivityEvent(
              BANNER_CREATIVE_SELECTED,
              onBannerReport(BANNER_CREATIVE_SELECTED, position, {
                campaign_id: currentData[i].campaign_id
              })
              // true
            );

            // sendReport();
            recordBannerView({
              ...selectedItem,
              currentPlayingCampaignIndex: i,
              campaign: { ...currentData[i] }
            });
            setPlaying(true);
            setIsConsentModal(false);
            await changeViewFactors(currentData[i].campaign_id);
          }

          // handleBannerPlayEnd();
          break;
        } else if (currentData.length - 1 == i) {
          setPlayingItem({});
          // removeSessionStorage(`banner_playing_${position}`);
          const payload = {
            ...JSON.parse(getSessionStorage('banner_playing', '{}'))
          };

          delete payload[position];
          setSessionStorage('banner_playing', JSON.stringify(payload));

          const currentDataFromSessionStorage =
            JSON.parse(getSessionStorage(`banner_item`, '[]'))[position] || [];

          for (var i = 0; i < currentDataFromSessionStorage.length; i++) {
            const _creatives = getQualifiedCreatives(currentDataFromSessionStorage[i]);

            if (_creatives && Array.isArray(_creatives) && _creatives.length > 0) {
              setCurrentData(JSON.parse(getSessionStorage(`banner_item`, '[]'))[position] || []);
              break;
            }

            if (currentDataFromSessionStorage.length - 1 == i) {
              await syncWithSessionStorage();

              fetchOtherType('send campaign');
            }
          }

          // handleBannerPlayEnd();
          break;
        }
      }
    } else {
      setPlayingItem({});
      // removeSessionStorage(`banner_playing_${position}`);
      const playingItemPayload = {
        ...JSON.parse(getSessionStorage('banner_playing', '{}'))
      };
      delete playingItemPayload[position];
      setSessionStorage('banner_playing', JSON.stringify(playingItemPayload));
      const currentDataFromSessionStorage =
        JSON.parse(getSessionStorage(`banner_item`, '[]'))[position] || [];
      if (currentDataFromSessionStorage.length > 0) {
        for (var i = 0; i < currentDataFromSessionStorage.length; i++) {
          const _creatives = getQualifiedCreatives(currentDataFromSessionStorage[i]);
          if (_creatives && Array.isArray(_creatives) && _creatives.length <= 0) {
            changeViewFactors(currentDataFromSessionStorage[i].campaign_id);
          }
          if (_creatives && Array.isArray(_creatives) && _creatives.length > 0) {
            setCurrentData(JSON.parse(getSessionStorage(`banner_item`, '[]'))[position]);
            return;
          } else if (currentDataFromSessionStorage.length - 1 == i) {
            // const payload = {
            //   ...JSON.parse(getSessionStorage('banner_item', '{}'))
            // };

            // delete payload[position];
            setCurrentData([]);
            fetchOtherType('currentDataFromSessionStorage.length - 1 == i');
            await syncWithSessionStorage();
            // setSessionStorage('banner_item', JSON.stringify(payload));
          }
        }
      } else {
        fetchOtherType('!currentDataFromSessionStorage.length > 0');
      }
      // setPlayingItem({});
      // handleBannerPlayEnd();
      // removeSessionStorage(`banner_playing_${position}`);
      const payload = {
        ...JSON.parse(getSessionStorage('banner_playing', '{}'))
      };

      delete payload[position];
      await syncWithSessionStorage();
      setSessionStorage('banner_playing', JSON.stringify(payload));
    }
  }

  function changeViewFactors(id) {
    let currentDataFromSessionStorage = { ...JSON.parse(getSessionStorage(`banner_item`, '{}')) };

    let currentStaticDataFromSessionStorage = {
      ...JSON.parse(getSessionStorage(`banner_static_item`, '{}'))
    };

    const availablePositions = Object.keys(currentDataFromSessionStorage);
    const availablePositionsStatic = Object.keys(currentStaticDataFromSessionStorage);

    availablePositions.forEach((_position) => {
      var temp = currentDataFromSessionStorage[_position] || [];
      const itemIndex = temp.findIndex((item) => item.campaign_id == id);
      if (itemIndex > -1) {
        if (
          temp[itemIndex].target_type.toLowerCase() !== 'filler' &&
          (parseInt(temp[itemIndex].max_view_per_session) <= 0 ||
            parseInt(temp[itemIndex].bal_view_per_user_per_camp) <= 0)
        ) {
          currentDataFromSessionStorage[_position].splice(itemIndex, 1);
        } else {
          currentDataFromSessionStorage[_position][itemIndex] = {
            ...temp[itemIndex],
            max_view_per_session: parseInt(temp[itemIndex].max_view_per_session) - 1,
            bal_view_per_user_per_camp: parseInt(temp[itemIndex].bal_view_per_user_per_camp) - 1
          };
        }
      }
    });

    availablePositionsStatic.forEach((_position) => {
      var temp_static_item = currentStaticDataFromSessionStorage[_position] || [];
      const itemIndex_static = temp_static_item.findIndex((item) => item.campaign_id == id);
      if (itemIndex_static > -1) {
        currentStaticDataFromSessionStorage[_position][itemIndex_static] = {
          ...temp_static_item[itemIndex_static],
          max_view_per_session:
            parseInt(temp_static_item[itemIndex_static].max_view_per_session) - 1,
          bal_view_per_user_per_camp:
            parseInt(temp_static_item[itemIndex_static].bal_view_per_user_per_camp) - 1
        };
      }
    });

    // var temp = { ...JSON.parse(getSessionStorage(`banner_item`, '{}')) }[position] || [];
    // var temp_static_item =
    //   { ...JSON.parse(getSessionStorage(`banner_static_item`, '{}')) }[position] || [];

    // const itemIndex = temp.findIndex((item) => item.campaign_id == id);
    // if (itemIndex > -1) {
    //   if (
    //     temp[itemIndex].target_type.toLowerCase() !== 'filler' &&
    //     (parseInt(temp[itemIndex].max_view_per_session) <= 0 ||
    //       parseInt(temp[itemIndex].bal_view_per_user_per_camp) <= 0)
    //   ) {
    //     temp.splice(itemIndex, 1);
    //   } else {
    //     temp[itemIndex] = {
    //       ...temp[itemIndex],
    //       // view_per_day: parseInt(temp[itemIndex].view_per_day) - 1,
    //       max_view_per_session: parseInt(temp[itemIndex].max_view_per_session) - 1,
    //       bal_view_per_user_per_camp: parseInt(temp[itemIndex].bal_view_per_user_per_camp) - 1
    //       // total_view_done_per_day_campaign:
    //       //   parseInt(temp[itemIndex].total_view_done_per_day_campaign) + 1
    //     };
    //   }
    // }

    setSessionStorage(
      `banner_item`,
      // JSON.stringify({
      //   // ...JSON.parse(getSessionStorage('banner_item', '{}')),
      //   // // [position]: temp

      // })

      JSON.stringify(currentDataFromSessionStorage)
    );

    setSessionStorage(
      `banner_static_item`,
      // JSON.stringify({
      //   ...JSON.parse(getSessionStorage('banner_static_item', '{}')),
      //   [position]: temp_static_item
      // })
      JSON.stringify(currentStaticDataFromSessionStorage)
    );

    return Promise.resolve();
  }

  async function checkNextItem() {
    // setPlaying(false);
    await reportBannerView(playingItemRef.current);

    await removeConflictCategory();

    if (type === 'video') {
      const videoSettings = currentSettings.videoSettings;

      if (closedAdsCount <= parseInt(videoSettings.max_overlay_per_video)) {
        sendCampaign(playingItem.currentPlayingCampaignIndex + 1);
      } else {
        // setPlayingItem({});
      }
    } else {
      sendCampaign(playingItem.currentPlayingCampaignIndex + 1);
    }
  }

  useEffect(() => {
    playingItemRef.current = playingItem;
  }, [playingItem]);

  async function removeConflictCategory() {
    const palyingItemConflictIds = playingItemRef.current?.conflict_brand_category?.map(function (
      _item
    ) {
      return _item['category_id'];
    });

    const playedConflictIds = JSON.parse(getSessionStorage('banner_conflict_ids', '[]'));

    let newPlayingConflictIds = playedConflictIds.filter(
      (_elem) => !palyingItemConflictIds?.includes(_elem)
    );

    setSessionStorage('banner_conflict_ids', JSON.stringify(newPlayingConflictIds));
    playingItemRef.current = {};
    return Promise.resolve();
  }

  function recordBannerView(campaignOBJ) {
    const payload = [
      ...JSON.parse(getSessionStorage('banner_report_playing', '[]')),
      {
        banner_user: getLocalStorage('user', '{}')?.user_master_id,
        campaign_id: campaignOBJ?.campaign?.campaign_id,
        creative_id: campaignOBJ?.creative_id,
        duration: campaignOBJ?.campaign?.duration,
        position: position,
        os: getPlatForm(),
        start_time: getCurrentTime(),
        timestamp_get: timestamp_get.current,
        type: page_name,
        type_id: page_id
      }
    ];

    setSessionStorage('banner_report_playing', JSON.stringify(payload));
  }

  async function reportBannerView(item = null) {
    const playedItem = item ? item : playingItemRef.current;
    const playingBannerReports = JSON.parse(getSessionStorage('banner_report_playing', '[]'));
    const creativeReportIndex = playingBannerReports.findIndex(
      (_elem) => _elem.creative_id === playedItem.creative_id && _elem.position === position
    );
    if (creativeReportIndex > -1) {
      const payload = { ...playingBannerReports[creativeReportIndex], position };
      payload.end_time = getCurrentTime();
      await axiosInstance.post(ADD_BANNER_REPORT, [payload]);
      playingBannerReports.splice(creativeReportIndex, 1);
      setSessionStorage('banner_report_playing', JSON.stringify(playingBannerReports));
    }
  }

  function handleBannerPlayEnd() {
    var previouslyPlayed = JSON.parse(getSessionStorage(`banner_playing`, '{}'))[position];

    if (previouslyPlayed.includes(playingItem.creative_id)) {
      previouslyPlayed.splice(previouslyPlayed.indexOf(playingItem.creative_id), 1);
      setSessionStorage(
        `banner_playing`,
        JSON.stringify({
          ...JSON.parse(getSessionStorage('banner_playing')),
          [top]: previouslyPlayed
        })
      );
    }
  }

  async function syncWithSessionStorage() {
    const banner_items = JSON.parse(getSessionStorage('banner_item', '{}'));
    const sessionBannerCampaignData = banner_items[position] || [];
    //sync session storage with query

    queryClient.setQueryData([GET_GLOBAL_DATA], (oldQueryData) => {
      var positionalData = [];
      if (oldQueryData && 'data' in oldQueryData && position in oldQueryData?.data) {
        positionalData = oldQueryData?.data[position];
      }
      if (Array.isArray(positionalData) && positionalData.length > 0) {
        const newGlobalCampaignData = positionalData?.map((_item) => {
          const index = sessionBannerCampaignData?.findIndex((_elem) => {
            return _elem.campaign_id == _item.campaign_id;
          });
          if (index > -1) {
            _item = {
              ...sessionBannerCampaignData[index]
              // max_view_per_session: sessionBannerCampaignData[index].max_view_per_session - 1,
              // bal_view_per_user_per_camp:
              //   sessionBannerCampaignData[index].bal_view_per_user_per_camp - 1
            };
          }

          return _item;
        });
        return {
          ...oldQueryData,
          data: {
            ...oldQueryData?.data,
            [position]: newGlobalCampaignData
          }
        };
      } else {
        return { ...oldQueryData };
      }
    });

    queryClient.setQueryData([GET_CONTENT_CAMPAIGN_DATA, page_id, page_name], (oldQueryData) => {
      var positionalData = [];
      if (oldQueryData && 'data' in oldQueryData && position in oldQueryData?.data) {
        positionalData = oldQueryData?.data[position];
      }

      if (Array.isArray(positionalData) && positionalData.length > 0) {
        const newContentCampaignData = positionalData?.map((_item) => {
          const index = sessionBannerCampaignData.findIndex(
            (_elem) => _elem.campaign_id == _item.campaign_id
          );
          if (index > -1) {
            _item = sessionBannerCampaignData[index];
          }

          return _item;
        });

        return {
          ...oldQueryData,
          data: {
            ...oldQueryData?.data,
            [position]: newContentCampaignData
          }
        };
      } else {
        return { ...oldQueryData };
      }
    });

    // });

    //clean session storage
    removeSessionStorage('banner_playing');
    // const payload = {
    //   ...JSON.parse(getSessionStorage('banner_item', '{}'))
    // };

    // delete payload[position];
    // setSessionStorage('banner_item', JSON.stringify(payload));

    return Promise.resolve();
  }

  useEffect(() => {
    return async () => {
      reportBannerView();
      await removeConflictCategory();
      await syncWithSessionStorage();
    };
  }, []);

  const onBannerClick = () => {
    let temp_click_report = {
      campaign_id: playingItem?.campaign.campaign_id ? playingItem?.campaign.campaign_id : 0,
      content_type: page_name ? page_name : 'not found',
      content_type_id: page_id,
      banner_position: position,
      creative_id: playingItem?.creative_id ?? 0
    };
    dispatch(
      addBannerClickReport(temp_click_report, (response) => {
        //console.log("click report added")
      })
    );
    if (parseInt(playingItem?.campaign?.is_consent)) {
      //Open consent Modal
      toggleConsentModal(true);
      return false;
    }
    if (playingItem?.CTA) {
      handleCTAAction();
    } else {
      return false;
    }
  };

  const onAgreeConsent = () => {
    let mobile_primary = userDetails()?.mobile_primary ? userDetails()?.mobile_primary : '';
    let device_details = getClientDeviceDetails(mobile_primary);

    dispatch(
      addBannerConsentClickReport(
        parseInt(playingItem?.campaign?.campaign_id),
        JSON.stringify(device_details),
        (response) => {
          toggleConsentModal(false);
        }
      )
    );
    if (playingItem?.CTA) {
      handleCTAAction();
    } else {
      return false;
    }
  };

  function handleCTAAction() {
    if (playingItem?.CTA) {
      switch (playingItem?.CTA) {
        case 'external_url':
          //console.log("this is external")
          if (playingItem?.external_URL) {
            openInNewTab(playingItem?.external_URL);
          }
          break;
        case 'internal_url':
          if (playingItem?.internal_type) {
            if (playingItem?.internal_type_id && playingItem?.internal_type_id != 0) {
              redirectTo(playingItem?.internal_type, playingItem?.internal_type_id);
            } else {
              redirectTo(playingItem?.internal_type);
            }
          }
          break;
        case 'file_download':
          if (playingItem?.CTAupload_file) {
            downloadFromUrl(playingItem?.CTAupload_file);
          }
          break;
        case 'pdf_open':
          if (playingItem?.CTAupload_file) {
            openInNewTab(playingItem.CTAupload_file);
          }
          break;
        default:
          console.log('no cta found-----☹☹');
          break;
      }
    } else {
      return false;
    }
  }

  function onPageHide() {
    let temp_banner_report = JSON.parse(getSessionStorage('banner_report_playing', '[]'));

    temp_banner_report.forEach((_item, i) => {
      temp_banner_report[i] = { ...temp_banner_report[i], end_time: getCurrentTime() };
    });

    if (temp_banner_report && temp_banner_report?.length > 0) {
      let reporting_array_temp = {};
      reporting_array_temp.data = temp_banner_report;
      reporting_array_temp.version = `rjsw ${appVersion}`;
      reporting_array_temp.os = getPlatForm();
      reporting_array_temp.token = getLocalStorage('accessToken', '');

      let _data = JSON.stringify(reporting_array_temp);
      navigator.sendBeacon(`${api_url}/openapi/clirbannerBeacone`, _data);
    }
  }

  const sendReportData = () => {
    if (document.visibilityState == 'hidden') {
      // let temp_banner_report = JSON.parse(getSessionStorage('banner_report_playing', '[]'));

      // const updated_banner_report = temp_banner_report.map((_object) => {
      //   return { ..._object, end_time: getCurrentTime() };
      // });

      // if (temp_banner_report?.length > 0) {
      //   axiosInstance.post(ADD_BANNER_REPORT, [updated_banner_report]);
      // }
      reportBannerView(playingItemRef.current);
    }
  };

  useEffect(() => {
    if (page_id) {
      if (Array.isArray(excludedCampigns?.ids) && excludedCampigns.ids.includes(page_id)) {
        setRemoveDiv(true);
      }
      if (hasContentCampaignData) {
        if (
          contentCampignData &&
          Array.isArray(contentCampignData) &&
          contentCampignData.length <= 0
        ) {
          setRemoveDiv(true);
        } else {
          setRemoveDiv(false);
        }
      } else if (
        contentCampignData &&
        Array.isArray(contentCampignData) &&
        contentCampignData.length <= 0 &&
        bannerData &&
        Array.isArray(bannerData) &&
        bannerData.length <= 0
      ) {
        setRemoveDiv(true);
      } else {
        setRemoveDiv(false);
      }
    } else {
      if (bannerData) {
        if (Array.isArray(bannerData) && bannerData.length <= 0) {
          setRemoveDiv(true);
        } else {
          setRemoveDiv(false);
        }
      }
    }
  }, [bannerData, contentCampignData, page_id, hasContentCampaignData]);

  useEffect(() => {
    document.addEventListener(visibilityChange, sendReportData);
    document.addEventListener('pagehide', onPageHide);

    return () => {
      document.addEventListener(visibilityChange, sendReportData);
      document.addEventListener('pagehide', onPageHide);
    };
  }, []);

  const setBannerHeight = (increase) => {
    if (currentSettings != null) {
      const parent_width = cardRefNew?.current?.clientWidth;
      const height =
        (parent_width * parseInt(currentSettings.height)) / parseInt(currentSettings.width);
      const payload = {
        height: increase ? `calc(${height}px + 1.5rem)` : height,
        width: '100%'
      };
      return payload;
    } else {
      return {
        height: '0px',
        width: '100%'
      };
    }
  };

  const setBannerPosition = (increase) => {
    const payload = {};
    if (currentSettings != null) {
      if (!isMobile && (position == 'top' || position == 'bottom')) {
        payload.maxWidth = currentSettings.width + 'px';
        payload.marginLeft = 'auto';
        payload.marginRight = 'auto';
        payload.display = 'flex';
      }

      const parent_width = cardRefNew?.current?.clientWidth;
      const height =
        (parent_width * parseInt(currentSettings.height)) / parseInt(currentSettings.width);

      (payload.height = increase ? `calc(${height}px + 1.5rem)` : height), (payload.width = '100%');
    } else {
      payload.height = '0px';
      payload.width = '100%';
    }

    return payload;
  };

  const getBannerClass = useMemo(() => {
    let tempClass = '';

    if (layout != 'speciality') {
      if (position == 'top') {
        if (isMobile && page_id && page_id != 0 && layout == 'speciality') {
          tempClass = 'mb-sm-4 mb-3 mt-20 px-3';
        } else {
          tempClass = 'mb-sm-4 mb-3 mt-3';
        }
      } else if (position == 'bottom') {
        tempClass = 'mb-4 mt-sm-4 mt-3 pt-3';
      } else if (position == 'side-top') {
        if (layout != 'speciality') {
          tempClass = 'mb-4';
        } else {
          tempClass = 'mt-3';
        }
      } else if (position == 'side-bottom') {
        if (layout != 'speciality') {
          tempClass = 'mt-4 mb-4';
        } else {
          tempClass = 'mt-3';
        }
      } else if (position == 'section-header') {
        tempClass = 'mt-4 mb-4';
      }
    }

    return tempClass;
  }, [position]);

  function getModifiedURL() {
    const url = playingItem.file;
    let transformation = ''; //`?tr=pr=true,c-at_max`;
    // console.log('inside video banner player', transformation, url);
    if (url && typeof url == 'string' && !url.includes('https')) {
      let splittedImg = url?.split('/');
      let path_extracted_image = splittedImg[splittedImg?.length - 1];
      const cfImageUrl = `https://bnr-cdn.clirnet.com/${path_extracted_image}${transformation}&cid=${
        playingItem.campaign.campaign_id
      }&umid=${userDetails().user_master_id}&crid=${playingItem?.creative_id}`;
      return cfImageUrl;
    } else if (
      url &&
      typeof url == 'string' &&
      url.includes('https://storage.googleapis.com/medwiki')
    ) {
      const temp_image_name = url.replace('https://storage.googleapis.com/medwiki/', '');
      const cfImageUrl = `https://bnr-cdn.clirnet.com/${temp_image_name}${transformation}&cid=${
        playingItem.campaign.campaign_id
      }&umid=${userDetails().user_master_id}`;
      // console.log('inside video banner player in else if', temp_image_name, cfImageUrl);
      return cfImageUrl;
    } else {
      // console.log('inside video banner player in else ', url);
      return url;
    }
  }

  function handleLoad() {
    trackActivityEvent(
      BANNER_LOAD,
      onBannerLoad(playingItem.campaign.campaign_id)
      // true
    );
  }

  const playerRef = useRef();
  function handleSuspend() {
    if (isMobile && playing && playingItem && 'creative_id' in playingItem) {
      document.body.addEventListener('touchstart', handleMobileLowPowerMode, true);
    }
  }

  function handleMobileLowPowerMode() {
    const player = playerRef.current;
    if (player) {
      const videoElement = player.getInternalPlayer();
      videoElement.play();
    }
  }

  //sendReport
  useEffect(() => {
    trackActivityEvent(
      BANNER_COMPONENT_LOAD,
      onBannerReport(BANNER_COMPONENT_LOAD, position)
      // true
    );
    // sendReport();
    return () => {
      reportBannerView();
      trackActivityEvent(
        BANNER_COMPONENT_UNLOAD,
        onBannerReport(BANNER_COMPONENT_UNLOAD, position)
        // true
      );
      // sendReport(
      //   generatePayload(networkState, page_name, page_id, position, 'BANNER_COMPONENT_UNLOAD')
      // );
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(bannerData)) {
      const payload = bannerData.map((obj) => ({ campaign_id: obj.campaign_id }));
      trackActivityEvent(
        BANNER_GLOBAL_CAMPAIGN_RESPONSE,
        onBannerReport(BANNER_GLOBAL_CAMPAIGN_RESPONSE, position, payload)
        // true
      );

      // sendReport(
      //   generatePayload(
      //     networkState,
      //     page_name,
      //     page_id,
      //     position,
      //     'BANNER_GLOBAL_CAMPAIGN_RESPONSE',
      //     payload
      //   )
      // );
    }
  }, [globalBannerData]);

  useEffect(() => {
    if (Array.isArray(contentCampignData)) {
      const payload = contentCampignData.map((obj) => ({ campaign_id: obj.campaign_id }));
      trackActivityEvent(
        BANNER_CONTENT_CAMPAIGN_RESPONSE,
        onBannerReport(BANNER_CONTENT_CAMPAIGN_RESPONSE, position, payload)
        // true
      );
      // sendReport(
      //   generatePayload(
      //     networkState,
      //     page_name,
      //     page_id,
      //     position,
      //     'BANNER_CONTENT_CAMPAIGN_RESPONSE',
      //     payload
      //   )
      // );
    }
  }, [contentBannerData]);

  if (removeDiv) return <></>;
  else if (playingItem && 'creative_id' in playingItem && currentSettings != null) {
    if (playingItem.type === 'video')
      return (
        <>
          {wrapper !== null ? (
            wrapper({
              bannerDiv: (
                <div
                  ref={cardRef}
                  // style={layout === 'speciality' ? setBannerHeight() : setBannerPosition()}
                  style={layout != 'speciality' ? setBannerPosition() : setBannerHeight()}
                  onClick={onBannerClick}
                >
                  <div
                    ref={cardRefNew}
                    style={setBannerHeight()}
                    // className={
                    //   position == 'top' || position == 'bottom' ? 'globalBanner_style' : ''
                    // }
                  >
                    <ReactPlayer
                      className="d-flex rounded-3 overflow-hidden cursorPointer"
                      key={playingItem.creative_id}
                      width={'100%'}
                      height={'100%'}
                      muted={true}
                      playsinline={true}
                      controls={false}
                      playing={playing}
                      url={getModifiedURL()}
                      onProgress={(e) => {
                        setPlayedDuration && setPlayedDuration(e.playedSeconds);
                      }}
                      onEnded={checkNextItem}
                      ref={playerRef}
                      // onReady={checkForError}
                      onSuspend={handleSuspend}
                      onError={(e) => {
                        trackActivityEvent(
                          BANNER_COMPONENT_VIDEO_ERROR,
                          onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)
                          // true
                        );

                        // sendReport(
                        //   generatePayload(
                        //     networkState,
                        //     page_name,
                        //     page_id,
                        //     position,
                        //     'BANNER_COMPONENT_VIDEO_ERROR',
                        //     e
                        //   )
                        // );
                      }}
                    />
                  </div>
                </div>
              ),
              title: playingItem?.CTA_text || '',
              buttonTxt: playingItem?.CTA_button || '',
              btnClick: onBannerClick,
              removeDiv: removeDiv
            })
          ) : (
            <div
              className={getBannerClass}
              ref={cardRefNew}
              // style={layout != 'speciality' ? setBannerPosition() : {}}
              style={layout != 'speciality' ? setBannerPosition(true) : setBannerHeight(true)}
              // className={`globalBanner_style ${getBannerClass}`}
            >
              <div
                ref={cardRef}
                style={setBannerHeight()}
                onClick={onBannerClick}
                // className={`mt-4 position-relative ${
                //   position == 'top' || position == 'bottom' ? 'globalBanner_style' : ''
                // }`}

                className={`mt-4 position-relative`}
              >
                <h4
                  className="fs-6 fw-medium text-black position-absolute text-right"
                  style={{ right: '0', bottom: '100%' }}
                  title={playingItem.CTA_text ? playingItem.CTA_text : 'Sponsored Ad'}
                >
                  Ad
                </h4>
                <ReactPlayer
                  onReady={handleLoad}
                  className="d-flex rounded-3 overflow-hidden cursorPointer"
                  key={playingItem.creative_id}
                  width={'100%'}
                  height={'100%'}
                  muted={true}
                  playsinline={true}
                  controls={false}
                  playing={playing}
                  url={getModifiedURL()}
                  onProgress={(e) => {
                    setPlayedDuration && setPlayedDuration(e.playedSeconds);
                  }}
                  onEnded={checkNextItem}
                  ref={playerRef}
                  onSuspend={handleSuspend}
                  onError={(e) => {
                    trackActivityEvent(
                      BANNER_COMPONENT_VIDEO_ERROR,
                      onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)
                      // true
                    );

                    // sendReport(
                    //   generatePayload(
                    //     networkState,
                    //     page_name,
                    //     page_id,
                    //     position,
                    //     'BANNER_COMPONENT_VIDEO_ERROR',
                    //     e
                    //   )
                    // );
                  }}
                />
              </div>
            </div>
          )}

          <ConsentModal
            title="Terms & Conditions"
            is_consent_modal={is_consent_modal}
            consent_text={playingItem?.campaign?.consent_value}
            onHide={() => toggleConsentModal(false)}
            onAgreeConsent={onAgreeConsent}
          />
        </>
      );
    else if (playingItem.type === 'image') {
      return (
        <>
          {wrapper !== null ? (
            wrapper({
              bannerDiv: (
                <div
                  ref={cardRef}
                  // style={layout === 'speciality' ? setBannerHeight() : setBannerPosition()}
                  style={layout != 'speciality' ? setBannerPosition() : setBannerHeight()}
                  onClick={onBannerClick}
                >
                  <div
                    ref={cardRefNew}
                    style={setBannerHeight()}
                    // className={
                    //   position == 'top' || position == 'bottom' ? 'globalBanner_style' : ''
                    // }
                  >
                    <ImageBannerPlayer
                      className="d-flex rounded-3 overflow-hidden cursorPointer"
                      url={playingItem.file}
                      seconds={parseInt(playingItem.video_duration)}
                      playing={!playing}
                      onEnded={checkNextItem}
                      campaign_id={playingItem?.campaign.campaign_id}
                      creative_id={playingItem.creative_id}
                      // onDuration={(e) => checkNextItem(parseInt(e), playingItem.video_duration)}
                      onDuration={(e) => setPlayedDuration && setPlayedDuration(e)}
                      onError={(e) => {
                        trackActivityEvent(
                          BANNER_COMPONENT_VIDEO_ERROR,
                          onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)
                          // true
                        );
                        // sendReport(
                        //   generatePayload(
                        //     networkState,
                        //     page_name,
                        //     page_id,
                        //     position,
                        //     'BANNER_COMPONENT_IMAGE_ERROR',
                        //     e
                        //   )
                        // );
                      }}
                    />
                  </div>
                </div>
              ),
              title: playingItem?.CTA_text || '',
              buttonTxt: playingItem?.CTA_button || '',
              btnClick: onBannerClick,
              removeDiv: removeDiv
            })
          ) : (
            <div
              className={getBannerClass}
              ref={cardRefNew}
              style={layout != 'speciality' ? setBannerPosition(true) : setBannerHeight(true)}
              // className={`globalBanner_style ${getBannerClass}`}
            >
              <div
                ref={cardRef}
                style={setBannerHeight()}
                onClick={onBannerClick}
                className={`mt-4 position-relative`}
              >
                <h4
                  className="fs-6 fw-medium text-black position-absolute text-right"
                  style={{ right: '0', bottom: '100%' }}
                  title={playingItem.CTA_text ? playingItem.CTA_text : 'Sponsored Ad'}
                >
                  Ad
                </h4>
                <ImageBannerPlayer
                  className="d-flex rounded-3 overflow-hidden cursorPointer"
                  url={playingItem.file}
                  seconds={parseInt(playingItem.video_duration)}
                  playing={!playing}
                  onEnded={checkNextItem}
                  campaign_id={playingItem?.campaign.campaign_id}
                  creative_id={playingItem.creative_id}
                  // onDuration={(e) => checkNextItem(parseInt(e), playingItem.video_duration)}
                  onDuration={(e) => setPlayedDuration && setPlayedDuration(e)}
                  onError={(e) => {
                    trackActivityEvent(
                      BANNER_COMPONENT_VIDEO_ERROR,
                      onBannerReport(BANNER_COMPONENT_VIDEO_ERROR, position, e)

                      // true
                    );
                    // sendReport(
                    //   generatePayload(
                    //     networkState,
                    //     page_name,
                    //     page_id,
                    //     position,
                    //     'BANNER_COMPONENT_IMAGE_ERROR',
                    //     e
                    //   )
                    // );
                  }}
                />
              </div>
            </div>
          )}

          <ConsentModal
            title="Terms & Conditions"
            is_consent_modal={is_consent_modal}
            consent_text={playingItem?.campaign?.consent_value}
            onHide={() => toggleConsentModal(false)}
            onAgreeConsent={onAgreeConsent}
          />
        </>
      );
    } else return <div ref={cardRef}></div>;
  } else {
    return (
      <>
        {wrapper !== null ? (
          wrapper({
            bannerDiv: (
              <div
                ref={cardRef}
                // style={layout === 'speciality' ? setBannerHeight() : setBannerPosition()}
                style={layout != 'speciality' ? setBannerPosition() : setBannerHeight()}
                onClick={onBannerClick}
              >
                <div ref={cardRefNew} style={setBannerHeight()}></div>
              </div>
            ),
            title: playingItem?.CTA_text || '',
            buttonTxt: playingItem?.CTA_button || '',
            btnClick: onBannerClick,
            removeDiv: removeDiv
          })
        ) : (
          <div
            ref={cardRef}
            className={getBannerClass}
            style={layout != 'speciality' ? setBannerPosition(true) : setBannerHeight(true)}
            // className={` position-relative ${
            //   position == 'top' || position == 'bottom' ? 'globalBanner_style' : ''
            // }`}
          ></div>
        )}
      </>
    );
  }
  // } else <></>;
}

export default memo(BannerItem);
