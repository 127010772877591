import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createHashRouter,
  createRoutesFromElements
} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRouter';
import { useSelector } from 'react-redux';
import PublicRoute from '../components/PublicRouter';
import ShareRoute from '../components/ShareRouter';
import RootLayout from '../layout/RootLayout';
import { layoutedRoutes } from './routes/layoutedRoutes';
import { newPrivateRoute } from './routes/newPrivateRoute';
import { newRoutes } from './routes/newRoutes';
import { PrivateRoutes } from './routes/privateRoutes';
import { publicRoutes } from './routes/publicRoutes';
import { shareRoutes } from './routes/shareRoutes';
import { ErrorFallback } from '../pages/error';
import { neutralRoutes } from './routes/neutralRoutes';
import OpixWrapper from './OpixWrapper';
import LoaderLine from '../components/LoaderLine';
import AuthRouter from './AuthRouter';
import { autoAuthRoutes } from './routes/autoAuthRoutes';
import DefaultLayout from '../layout/DefaultLayout';
import UnavailableV2 from '../pages/unavailable/UnavailableV2';

export function useDefaultRouter() {
  const userDetails = useSelector((state) => state.common.user_details);

  const activeModules = {
    medwiki: true,
    spq: true,
    generic: true,
    clinicalVideo: true,
    session: true,
    election: true,
    epub: true,
    gr: true,
    dnr: true,
    courses: true,
    speciality: true,
    channel: true,
    telemed: true
  };

  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RootLayout />}>
        {publicRoutes.map((elem, index) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route
                  key={`public-${index}`}
                  path={elem.path}
                  element={
                    <>
                      <LoaderLine />
                      <Outlet />
                    </>
                  }
                >
                  {elem.subPath &&
                    elem.subPath.map((key) => (
                      <Route
                        key={key}
                        path={key}
                        element={
                          <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                            {elem.component}
                          </ShareRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  key={`public-${index}`}
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      <LoaderLine />
                      {elem.component}
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route
                  key={`public-${index}`}
                  exact
                  path={elem.path}
                  element={
                    <>
                      <LoaderLine />
                      <Outlet />
                    </>
                  }
                >
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route exact path={subPath} key={subPath} element={<DefaultLayout />}>
                        <Route exact index element={elem.component} />
                      </Route>
                    ))}
                </Route>
              );
            } else {
              return (
                <Route key={`public-${index}`} path={elem.path} element={<DefaultLayout />}>
                  <Route index element={<PublicRoute>{elem.component}</PublicRoute>} />
                </Route>
              );
            }
          }
        })}
        {neutralRoutes.map((elem, index) => {
          if ('subPath' in elem) {
            return (
              <Route key={`neutral-${index}`} exact path={elem.path} element={<DefaultLayout />}>
                {elem.subPath &&
                  elem.subPath.map((subPath) => (
                    <Route exact path={subPath} key={subPath} element={elem.component} />
                  ))}
              </Route>
            );
          } else {
            return (
              <Route key={`neutral-${index}`} path={elem.path} element={<DefaultLayout />}>
                <Route index element={elem.component} />
              </Route>
            );
          }
        })}
        {shareRoutes.map((elem, index) => {
          if ('subPath' in elem) {
            return (
              <Route
                key={`share-${index}`}
                path={elem.path}
                element={
                  <>
                    <LoaderLine />
                    <Outlet />
                  </>
                }
              >
                {elem.subPath &&
                  elem.subPath.map((subPath) => (
                    <Route
                      key={subPath}
                      path={subPath}
                      element={
                        <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                          {elem.component}
                        </ShareRoute>
                      }
                    />
                  ))}
              </Route>
            );
          } else {
            return (
              <Route
                key={`share-${index}`}
                path={elem.path}
                element={
                  <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                    <LoaderLine />
                    {elem.component}
                  </ShareRoute>
                }
              />
            );
          }
        })}
        {newRoutes.map((elem, routeIndex) => {
          if ('subpath' in elem) {
            return (
              <Route key={`new-${routeIndex}`} element={elem.layout}>
                {elem.subpath &&
                  elem.subpath.map((item, index) => {
                    if (item.module in activeModules && !activeModules[item.module]) {
                      return <Navigate key={`new-subpath-${index}`} to="/unavailable" />;
                    } else {
                      if ('paramPath' in item) {
                        return (
                          <Route key={`new-subpath-${index}`} path={item.path} element={<Outlet />}>
                            {item.paramPath &&
                              item.paramPath.map((_elem, idx) => {
                                return (
                                  <Route
                                    path={_elem}
                                    key={_elem}
                                    element={
                                      <AuthRouter>
                                        {userDetails?.spec_dashboard_eligibility_status &&
                                        userDetails.specility_update_status !== '0' &&
                                        'specialityComponent' in item
                                          ? item.specialityComponent
                                          : item.component}
                                      </AuthRouter>
                                    }
                                  />
                                );
                              })}
                          </Route>
                        );
                      } else
                        return (
                          <Route
                            key={`new-subpath-${index}`}
                            path={item.path}
                            element={<AuthRouter>{item.component}</AuthRouter>}
                          />
                        );
                    }
                  })}
              </Route>
            );
          }
        })}
        {newPrivateRoute.map((elem, index) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route key={`new-private-${index}`} path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={
                          <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                            {elem.component}
                          </ShareRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  key={`new-private-${index}`}
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      {elem.component}
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route key={`new-private-${index}`} path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={<AuthRouter>{elem.component}</AuthRouter>}
                      />
                    ))}
                </Route>
              );
            } else {
              if ('paramPath' in elem) {
                return (
                  <Route key={`new-private-${index}`} path={elem.path} element={<Outlet />}>
                    {elem.paramPath &&
                      elem.paramPath.map((_elem, idx) => {
                        return (
                          <Route
                            path={_elem}
                            key={_elem}
                            element={
                              <AuthRouter>
                                {userDetails?.spec_dashboard_eligibility_status &&
                                userDetails.specility_update_status !== '0' &&
                                'specialityComponent' in elem
                                  ? elem.specialityComponent
                                  : elem.component}
                              </AuthRouter>
                            }
                          />
                        );
                      })}
                  </Route>
                );
              }

              return (
                <Route
                  key={`new-private-${index}`}
                  path={elem.path}
                  element={<AuthRouter>{elem.component}</AuthRouter>}
                />
              );
            }
          }
        })}
        {PrivateRoutes.map((elem, index) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route key={`private-${index}`} path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={
                          <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                            {elem.component}
                          </ShareRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  key={`private-${index}`}
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      {elem.component}
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route key={`private-${index}`} path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={<AuthRouter>{elem.component}</AuthRouter>}
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  key={`private-${index}`}
                  path={elem.path}
                  element={<AuthRouter>{elem.component}</AuthRouter>}
                />
              );
            }
          }
        })}
        {autoAuthRoutes.map((elem, index) => {
          if ('subPath' in elem) {
            return (
              <Route key={`auto-auth-${index}`} exact path={elem.path} element={<DefaultLayout />}>
                {elem.subPath &&
                  elem.subPath.map((subPath) => (
                    <Route
                      exact
                      path={subPath}
                      key={subPath}
                      element={<AuthRouter autoAuth={true}>{elem.component} </AuthRouter>}
                    />
                  ))}
              </Route>
            );
          } else {
            return (
              <Route key={`auto-auth-${index}`} path={elem.path} element={<DefaultLayout />}>
                <Route index element={<AuthRouter autoAuth={true}>{elem.component}</AuthRouter>} />
              </Route>
            );
          }
        })}
      </Route>
    ),
    {
      basename: '/'
    }
  );
}
