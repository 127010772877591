import React, { memo } from 'react';
import LazyImage from '../CustomLibraries/LazyImage';
import './css/noResult.scss';
import noResultImg from './images/noResult.png';

const NoResult = ({
  mainText = '',
  subText = '',
  children,
  mainTextClass = '',
  subTextClass = ''
}) => {
  return (
    <div className="w-100 noResult text-center">
      <img src={noResultImg} alt="No Result Found" className="noResultPic mb-3" />
      <h4 className={mainTextClass ? mainTextClass : 'fs-2 fw-bold text-black mb-4'}>{mainText}</h4>
      {subText && (
        <h6 className={subTextClass ? subTextClass : 'fs-4 fw-normal text-black'}>{subText}</h6>
      )}
      {children}
    </div>
  );
};

export default memo(NoResult);
